import React, { Suspense } from 'react';
import {
    BrowserRouter,
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
    RouteObject,
    RouterProvider,
    Routes,
} from 'react-router-dom';
import {
    ActivateEmail,
    CreateUser,
    DataProtection,
    DeleteUser,
    ForogtPassword,
    LoginPage,
    ResetPassword,
    TermsOfUse,
} from './pages';
import { route } from './constants';
import { DeleteUserConfirm } from './pages/DeleteUserConfirm';
import { ChangePassword } from './pages/ChangePassword';
import { Shop } from './shop/Page/Shop';
import { Market, MarketAdd, MarketMy } from './market/Page';
import { UserData } from './userData/Page';
import { getUserFromToken } from './api/getUserFromToken';
import { Dashboard } from './pages/Dashboard';
import DeckBuilder from './deckBuilder/Page/DeckBuilder';
import { loader } from './pages/Dashboard/Dashboard.loader';
import { CardCollection } from './cardCollection/Page';
import { ThemeProvider } from 'styled-components';
import { light } from './themes';
import { AdminProvider } from './AdminProvider';

const router = createBrowserRouter([
    {
        errorElement: <LoginPage />,
        children: [
            {
                path: route.REGISTER,
                element: <CreateUser />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.DASHBOARD,
                element: <Dashboard />,
                //lazy: () => import( /* webpackChunkName: "Dashboard" */ './pages/Dashboard' )
            },
            {
                path: route.FORGOT_PASSWORD,
                element: <ForogtPassword />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.DELETE_ACCOUNT,
                element: <DeleteUser />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.DELETE_ACCOUNT_CONFIRM,
                element: <DeleteUserConfirm />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.CHANGE_PASSWORD,
                element: <ChangePassword />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.LOGIN,
                element: <LoginPage />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.DECK_BUILDER,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.SHOP,
                element: <Shop />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.MARKET,
                element: <Market />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.MARKET_ADD,
                element: <MarketAdd />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.MARKET_MY,
                element: <MarketMy />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
            {
                path: route.USER,
                element: <UserData />,
                //lazy: () => import( /* webpackChunkName: "DeckBuilder" */ './deckBuilder/Page' )
            },
        ] satisfies RouteObject[],
    },
]);

function App() {
    const protectedRoutes = [...Object.values(route), '/arena'];

    const shouldRedirectToDashboard = !protectedRoutes.includes(
        window.location.pathname,
    );

    /*
  const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={route.LOGIN} element={<LoginPage />} />
      <Route path={route.DASHBOARD} element={<Dashboard />} />
    </Route>
  )
  )
  */

    //return <RouterProvider router={router} />

    return (
        <ThemeProvider theme={light}>
            <AdminProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path={route.ROOT} element={<CreateUser />} />
                        <Route path={route.REGISTER} element={<CreateUser />} />
                        <Route path={route.LOGIN} element={<LoginPage />} />
                        <Route
                            path={route.FORGOT_PASSWORD}
                            element={<ForogtPassword />}
                        />
                        <Route
                            path={route.DELETE_ACCOUNT}
                            element={<DeleteUser />}
                        />
                        <Route
                            path={route.DELETE_ACCOUNT_CONFIRM}
                            element={<DeleteUserConfirm />}
                        />
                        <Route
                            path={route.CHANGE_PASSWORD}
                            element={<ChangePassword />}
                        />
                        <Route path={route.DASHBOARD} element={<Dashboard />} />
                        <Route
                            path={route.DECK_BUILDER}
                            element={<DeckBuilder />}
                        />
                        <Route
                            path={route.CARD_COLLECTION}
                            element={<CardCollection />}
                        />
                        <Route path={route.SHOP} element={<Shop />} />
                        <Route
                            path={route.RESET_PASSWORD}
                            element={<ResetPassword />}
                        />
                        <Route
                            path={route.ACTIVATE}
                            element={<ActivateEmail />}
                        />
                        <Route path={route.MARKET} element={<Market />} />
                        <Route
                            path={route.MARKET_ADD}
                            element={<MarketAdd />}
                        />
                        <Route path={route.MARKET_MY} element={<MarketMy />} />
                        <Route path={route.USER} element={<UserData />} />
                        <Route
                            path={route.DATA_PROTECTION}
                            element={<DataProtection />}
                        />
                        <Route
                            path={route.TERMS_OF_USE}
                            element={<TermsOfUse />}
                        />
                        {shouldRedirectToDashboard && (
                            <Route
                                path="*"
                                element={<Navigate to={route.DASHBOARD} />}
                            />
                        )}
                    </Routes>
                </BrowserRouter>
            </AdminProvider>
        </ThemeProvider>
    );
}

export default App;
//        <Route path={route.DECK_BUILDER} element={<DeckBuilder />} />
