import { generateRandomString, getRandomInt } from "../factoryHelpers";
import { MatchesType } from "../userData/Types";

  export function createMatch(match: Partial<MatchesType>): MatchesType {
  const defaultObject: MatchesType = {
    ID: getRandomInt(),
    Winner: generateRandomString(10),
    WinnerElo: getRandomInt(),
    Loser: generateRandomString(10),
    LoserElo: getRandomInt(),
    Type: generateRandomString(10),
    CreatedAt: generateRandomString(10),
  };

  return { ...defaultObject, ...match};
}

export function createMatchHistory(): MatchesType[] {
  const defaultObject = []
  for (let index = 0; index < 10; index++) {
    defaultObject.push(createMatch({}))
  }
  return [ ...defaultObject];
}