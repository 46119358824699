import React, { useCallback } from 'react';
import DashboardBackground from '../../assets/dashboard.jpg';
import { BackgroundImage } from '../../BackgroundImage';
import {
    ButtonContainer,
    Center,
    Container,
    Text,
} from './ActivateEmail.styles';
import { ButtonAccept } from '../../components/Buttons';
import { ReactComponent as Home } from '../../assets/icons/house.svg';

type Props = {
    username: string;
    setShowMessage: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ActivateEmailReminder = ({ username, setShowMessage }: Props) => {
    const goToDashboard = useCallback(() => {
        setShowMessage(true);
    }, []);
    return (
        <Container>
            <Center>
                <Text>
                    Don&apos;t forget to activate your email, {username}
                </Text>
                <ButtonContainer>
                    <ButtonAccept onclick={goToDashboard}>
                        <Home
                            viewBox="20 20 150 150"
                            width="100%"
                            height="100%"
                        />
                    </ButtonAccept>
                </ButtonContainer>
            </Center>
        </Container>
    );
};
