import styled from 'styled-components';

export const AddCardContainer = styled.div`
    left: 0px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background-color: black;
    border: 1px solid black;
    box-shadow: inset 0 0 15px white;
`;

export const IconContainer = styled.div`
    width: 1.5rem;
    height: 100%;
    margin: auto;
    svg {
        width: inherit;
        height: inherit;
    }
`;

export const SellInputResponsive = styled.input`
    margin: 20px auto;
`;

export const CenterOnScreen = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const BottomOfScreen = styled.div`
    position: fixed;
    bottom: 20px;
    width: 100%;
    margin: auto;
`;

export const AddCardButtonContainer = styled.div`
    margin: 20px auto;
    height: 50px;
`;
