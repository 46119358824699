import styled from 'styled-components';

export const Button = styled.button`
    background-color: rgba(9, 45, 5, 0.7);
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-size: 14px;
    display: block;
    height: inherit;
    width: inherit;
    white-space-collapse: initial;
    text-wrap: wrap;
    text-overflow: ellipsis;
    padding: 0.5rem 1.8rem;
    margin: 0;
    border-radius: 10px;
    border-style: solid;
    border-image: initial;
    border-color: rgb(210, 210, 210);
    border-width: 0.12rem;
    text-decoration: none;
    transition: all 0.25s linear 0s;
    overflow: hidden;

    @media (hover: hover) {
        &:hover {
            border-color: rgb(255, 255, 255);
            background-color: rgba(9, 45, 5, 0.8);
            cursor: pointer;
        }
    }
    @media (hover: none) {
        &:hover {
            border-color: rgb(255, 255, 255);
        }
    }
`;
