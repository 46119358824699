import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import { Navbar } from '../../components';
import { BackgroundImage } from '../../BackgroundImage';
import DashboardBackground from './../../assets/dashboard.jpg';
import { DashboardUserInfo } from './DashboardUserInfo';
import { Feedback } from '../../components/Feedback';
import { ParticleCanvas } from '../../components/ParticleCanvas';
import { EloRank } from './EloRank';
import { Points } from './Points';
import { LoaderData, loader } from './Dashboard.loader';
import { Navigate } from 'react-router-dom';
import { route } from '../../constants';
import { BannedUser } from '../BannedUser';
import { getCookie } from '../../getCookie';
import { isLocalhost } from '../../factoryHelpers';
import { ActivateEmailReminder } from '../ActivateEmailReminder';
import { useAdmin } from '../../AdminProvider';
import { FidgetSpinnerLoader } from '../../fidgetSpinner';

export const Dashboard = () => {
    const [loaderData, setLoaderData] = useState<LoaderData>();
    const [showMessage, setShowMessage] = useState(false);
    const { userUsername, setAdminUsername } = useAdmin();

    useEffect(() => {
        loader().then((data) => {
            setLoaderData(data as LoaderData | undefined);
        });
    }, []);
    if (
        getCookie('bloodstor_user') === undefined ||
        getCookie('bloodstor_user') === ''
    ) {
        if (!isLocalhost()) {
            return <Navigate to={route.LOGIN} />;
        }
    }
    if (loaderData === undefined)
        return (
            <div className="dashboard">
                <BackgroundImage src={DashboardBackground} />
                <Navbar Username={userUsername} />
                <DashboardUserInfo>
                    <EloRank
                        elo={<FidgetSpinnerLoader size={20} />}
                        rank={<FidgetSpinnerLoader size={20} />}
                    />
                    <Points
                        gamePoints={<FidgetSpinnerLoader size={20} />}
                        prestigePoints={<FidgetSpinnerLoader size={20} />}
                    />
                </DashboardUserInfo>
            </div>
        );

    const {
        elo,
        rank,
        gamePoints,
        prestigePoints,
        username,
        isBanned,
        isActive,
    } = loaderData;
    if (username === '') {
        if (!isLocalhost()) {
            return <Navigate to={route.LOGIN} />;
        }
    }
    if (userUsername === '') {
        setAdminUsername(username);
    }

    if (!isActive && !showMessage) {
        return (
            <div className="dashboard">
                <BackgroundImage src={DashboardBackground} />
                <ActivateEmailReminder
                    username={username}
                    setShowMessage={setShowMessage}
                />
            </div>
        );
    }

    if (isBanned) {
        return <BannedUser />;
    }

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <Navbar Username={userUsername} />
            <DashboardUserInfo>
                <EloRank elo={elo} rank={rank} />
                <Points
                    gamePoints={gamePoints}
                    prestigePoints={prestigePoints}
                />
            </DashboardUserInfo>
        </div>
    );
};

//<Feedback />
//<ParticleCanvas />
