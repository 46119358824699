import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { UserCardCatalogMy, MarketSideBarMy } from '../Modules';
import { MarketCardInterface } from '../Types/CardInterface';
import { DeckBackend } from '../Api/AdminAPI';
import { getCookie } from '../../getCookie';
import { MarketFormType } from '../Types/MarketFormInterface';
import { bringMarketCardBackToUser, filterMarketCards } from '../Api';
import BackgroundImage from './../assets/market.jpg';
import { BackgroundImageSideBar } from '../BackgroundImage';
import { MarketSideBarResponsiveMy } from '../Modules/SideBar/MarketSideBarResponsiveMy';
import { isLocalhost } from '../factoryHelpers';
import { myMarketCard } from '../Types/CardInterface.factory';
import { sortByCardName } from '../sortByCardName';
import { filterCards } from '../filterCards';
import { useFilteredAndSortedCards } from '../shared/useFilteredAndSortedCards';

export const MarketMy = () => {
    const [allCards, setAllCards] = useState<Array<MarketCardInterface>>([]);
    const [sortedCardsMemoized, setSortedCardsMemoized] = useState<
        MarketCardInterface[]
    >([]);
    const [detailCardId, setDetailCardId] = useState('');
    const cookieValue = getCookie('bloodstor_user');
    const api = new DeckBackend();
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const [form, SetForm] = useState<MarketFormType>({
        Search: '',
    });

    useEffect(() => {
        if (cookieValue === undefined) {
            return;
        }

        api.getUserMarketCards(cookieValue).then(
            (response_json: Array<MarketCardInterface>) => {
                const sortedCards = sortByCardName(response_json);
                setAllCards(sortedCards);
                setSortedCardsMemoized(sortedCards);
            },
        );
    }, []);

    useFilteredAndSortedCards(allCards, sortedCardsMemoized, form, setAllCards);

    const handlePageClick = (event: MouseEvent) => {
        if (detailCardId !== '' && (event.target as Element).id !== 'card') {
            setDetailCardId('');
        }
    };

    if (isLocalhost() && allCards.length === 0) {
        const arr: MarketCardInterface[] = [];
        for (let index = 0; index < 100; index++) {
            arr.push(myMarketCard({}));
        }
        const sortedCards = sortByCardName(arr);
        setAllCards(sortedCards);
        setSortedCardsMemoized(sortedCards);
    }

    const bringMarketCardBackToUserInit = async (card_id: string) => {
        bringMarketCardBackToUser(card_id, cookieValue, api, setAllCards);
    };

    //const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const handleKeyDown = (event: any) => {
        if (event.key !== 'Escape') {
            return;
        }
        setDetailCardId('');
    };

    document.addEventListener('mousedown', handlePageClick);
    document.addEventListener('keyup', handleKeyDown);
    const SideBar =
        screenWidth > 675 ? MarketSideBarMy : MarketSideBarResponsiveMy;

    return (
        <div className="body">
            <BackgroundImageSideBar
                src={BackgroundImage}
                width={1655}
                height={900}
            />
            <SideBar form={form} setForm={SetForm} />
            <UserCardCatalogMy
                detailCardId={detailCardId}
                setDetailCardId={setDetailCardId}
                allCards={allCards}
                bringMarketCardBackToUser={bringMarketCardBackToUserInit}
            />
        </div>
    );
};
