import * as React from 'react';
import { useEffect } from 'react';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { PayPalButtons } from '@paypal/react-paypal-js';
import {
    OnApproveData,
    OnApproveActions,
} from '@paypal/paypal-js/types/components/buttons';
import { getCookie } from '../getCookie';
import { SERVER_URL } from '../../constants';
import { RemoveItemButton } from 'react-admin';
import { FidgetSpinnerLoader } from '../../fidgetSpinner';

interface ButtonWrapperProps {
    title: string;
    currency: string;
    showSpinner: boolean;
    price: string;
    getUserFromToken: () => Promise<void>;
}

const sendOrderToBackend = async (
    orderType: string,
    price: string,
    id: string,
    payeer_id: string,
    createTimePaypal: string,
    firstName: string,
    lastName: string,
    getUserFromToken: () => Promise<void>,
) => {
    const res = await fetch(`https://${SERVER_URL}/transaction`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            Token:
                getCookie('bloodstor_user') === undefined
                    ? ''
                    : getCookie('bloodstor_user'),
            Website: window.location.href,
            Price: price,
            Product: orderType,
            OrderId: id,
            FirstName: firstName,
            LastName: lastName,
            CreatedAtPaypal: createTimePaypal,
        }),
    });
    if (res.ok) {
        getUserFromToken();
    } else {
        alert('Something went wrong. We will take care of that.');
    }
};

export const ButtonWrapper = (props: ButtonWrapperProps) => {
    const style = {
        color: undefined,
        height: undefined,
        label: undefined,
        layout: 'vertical',
        shape: undefined,
        tagline: undefined,
    };
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const { title, currency, showSpinner, price, getUserFromToken } = props;
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: 'resetOptions',
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);

    const handleApprove = async (
        data: OnApproveData,
        actions: OnApproveActions,
    ) => {
        // Capture the transaction when the buyer approves the payment
        const captureResult = await actions.order?.capture();
        const createTimePaypal = captureResult?.create_time || '';
        const firstName = captureResult?.payer.name?.given_name || '';
        const lastName = captureResult?.payer.name?.surname || '';
        // Get the transaction ID from the capture result
        const id =
            captureResult?.purchase_units?.[0]?.payments?.captures?.[0]?.id ??
            '';

        // Check if the payment method used was PayPal or credit card
        const paymentMethod =
            captureResult?.purchase_units?.[0]?.payments?.captures?.[0]
                ?.payment_method_type;

        sendOrderToBackend(
            'PrestigePoints',
            price,
            data.orderID,
            data.payerID ?? '',
            createTimePaypal,
            firstName,
            lastName,
            getUserFromToken,
        );
    };
    if (isPending) {
        return <FidgetSpinnerLoader size={25} />;
    }

    return (
        <PayPalButtons
            // style={style}
            disabled={false}
            forceReRender={[price, currency, style]}
            fundingSource={undefined}
            createOrder={(data, actions) => {
                return actions.order
                    .create({
                        application_context: {
                            brand_name: title,
                        },
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: currency,
                                    value: price,
                                },
                            },
                        ],
                    })
                    .then((orderId) => {
                        // Your code here after create the order
                        return orderId;
                    });
            }}
            onApprove={function (data, actions) {
                return actions.order!.capture().then(function () {
                    handleApprove(data, actions);
                });
            }}
        />
    );
};
