import { useEffect } from "react";

export function useClickOutside<T extends Element>(
  ref: React.RefObject<T> | React.MutableRefObject<T>,
  handler: (e: MouseEvent | TouchEvent) => void,
  options: AddEventListenerOptions | boolean = true
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref?.current || ref?.current?.contains(event.target as Element)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("click", listener, options);

    return () => {
      document.removeEventListener("click", listener, options);
    };
  }, [ref.current, handler, options]);
}
