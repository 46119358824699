import { AccountSlotsType } from '../../Types/AccountSlotsType';
import { SERVER_URL } from '../../constants';

export const getAccountSlots = async () => {
    const res = await fetch(`https://${SERVER_URL}/get_accounts_number`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (res.ok) {
        const data: AccountSlotsType = await res.json();
        return data;
        // set user state (should be in admin context so it can be loaded from anywhere)
    }
    return;
};
