export const getColor = (rareLvl: number) => {
  switch (rareLvl) {
    case 0:
      return "silver" 
    case 1:
      return "blue" 
    case 2:
      return "brown" 
    case 3:
      return "green" 
    case 4:
      return "violet" 
    case 5:
      return "orange" 
  
    default:
      return "silver" 
    return "silver" 
  }
}