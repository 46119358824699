import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie, deleteCookie } from '../getCookie';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import './Shop.scss';
import 'reactjs-popup/dist/index.css';
import treasue from './../assets/treasure.jpg';
import coins from './../assets/coins.jpg';
import { SERVER_CARDS_URL, SERVER_URL, route } from '../../constants';

import { CardInterface } from '../Types/CardInterface';
import { NewCards } from './NewCards';
import { ShopButton } from './ShopButton';
import {
    CARD_PACK_PRIZE,
    PAYPAL_LIVE_ID,
    PAYPAL_SANDBOX_ID,
} from '../constants';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { ReactComponent as Controller } from '../../assets/icons/controller.svg';
import {
    IconSvgConatiner,
    IconSvgConatinerController,
    IconSvgConatinerShop,
    IconTitle,
    PointsNavbar,
} from '../components/styled/IconHeaderStyle';
import { ReactComponent as Home } from '../../assets/icons/house.svg';
import {
    CardInfo,
    ClassicButton,
    Footer,
    ShopItemContainer,
    SvgContianer,
} from './Shop.styles';
import Popup from 'reactjs-popup';
import { BuyPopup } from '../../components';
import { NewCardPack } from '../../Types/NewCardPack';
import { getNewCardPackData } from '../api/getNewCardPackData';
import { isLocalhost } from '../../factoryHelpers';

interface UserDashboardInterface {
    Username: string;
    Email: string;
    //Decks []  Deck
    IsBanned: boolean;
    PrestigePoints: number;
    GamePoints: number;
}

const pp_2 = { price: '2', title: '13' };
const pp_5 = { price: '5', title: '42' };
const pp_18 = { price: '18', title: '165' };

export const Shop = () => {
    const [user, setUser] = useState<UserDashboardInterface | undefined>();
    const [cards, setCards] = useState<Array<CardInterface>>([]);
    const [cardsPrice, setCardPrice] = useState<NewCardPack>();
    const [isBuyCardsPopupOpen, setIsBuyCardsPopupOpen] = useState(false);
    const navigate = useNavigate();

    const isLiveWebsite = useCallback(() => {
        return window.location.href === 'https://asthell.com/user/shop';
    }, []);

    useEffect(() => {
        if (isLocalhost()) {
            setCardPrice({ Price: -1, Amount: -1 });
            return;
        }
        getUserFromToken();
        getNewCardPackData().then((data) => setCardPrice(data));
    }, []);

    const paypalId = isLiveWebsite() ? PAYPAL_LIVE_ID : PAYPAL_SANDBOX_ID;
    console.log(isLiveWebsite(), 'test');
    const getUserFromToken = async () => {
        const cookieValue = getCookie('bloodstor_user');
        const res = await fetch(`https://${SERVER_URL}/user`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${cookieValue}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.ok) {
            const data: UserDashboardInterface = await res.json();
            setUser(data);
        } else {
            deleteCookie('bloodstor_user');
            navigate(route.LOGIN);
        }
    };
    const purchaseCards = async () => {
        const cookieValue = getCookie('bloodstor_user');
        const res = await fetch(
            `https://${SERVER_CARDS_URL}/purchase_new_cards`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${cookieValue}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        if (res.ok) {
            const cards: CardInterface[] = await res.json();
            setCards(cards);
        }
    };

    if (cards.length !== 0) {
        return (
            <div className="shop__navbar">
                <div className="shop">
                    <NewCards cards={cards} setCards={setCards} />
                </div>
            </div>
        );
    }

    const icon = <Star viewBox="-5 -2 25 25" width="100%" height="100%"></Star>;
    const icon_controller = (
        <Controller viewBox="0 0 30 30" width="100%" height="100%"></Controller>
    );

    //enable credit card
    //               "enable-funding": "paypal,card"

    //              'enable-funding': 'paypal',
    //              'disable-funding': 'card',

    return (
        <>
            <div className="shop__navbar">
                <PointsNavbar>
                    <IconTitle className="number_font">
                        {user ? user.PrestigePoints : ''}
                    </IconTitle>
                    <IconSvgConatinerShop>{icon}</IconSvgConatinerShop>
                </PointsNavbar>
                <PointsNavbar>
                    <IconTitle className="number_font">
                        {user ? user.GamePoints : ''}
                    </IconTitle>
                    <IconSvgConatinerController>
                        {icon_controller}
                    </IconSvgConatinerController>
                </PointsNavbar>
            </div>
            <div className="shop">
                <ShopItemContainer>
                    <PayPalScriptProvider
                        options={{
                            'client-id': paypalId,
                            currency: 'USD',
                            components: 'buttons,funding-eligibility',
                            'enable-funding': 'paypal,card',
                        }}
                    >
                        <ShopButton
                            title={pp_2.title}
                            price={pp_2.price}
                            image={coins}
                            getUserFromToken={getUserFromToken}
                            icon={icon}
                        ></ShopButton>
                        <ShopButton
                            title={pp_5.title}
                            price={pp_5.price}
                            image={treasue}
                            icon={icon}
                            getUserFromToken={getUserFromToken}
                        ></ShopButton>
                        <ShopButton
                            title={pp_18.title}
                            price={pp_18.price}
                            image={treasue}
                            icon={icon}
                            getUserFromToken={getUserFromToken}
                        ></ShopButton>
                    </PayPalScriptProvider>
                    <div
                        className="shop_button"
                        onClick={() => {
                            setIsBuyCardsPopupOpen(true);
                        }}
                    >
                        <div className="shop_title number_font">
                            {cardsPrice?.Amount || ''} Cards
                        </div>
                        <img src={coins} alt="yolo" />
                        <div className="shop_price">
                            <CardInfo className="number_font">
                                {cardsPrice?.Price}
                                <SvgContianer>
                                    <Controller
                                        viewBox="0 0 30 30"
                                        width="100%"
                                        height="100%"
                                    ></Controller>
                                </SvgContianer>
                            </CardInfo>
                        </div>
                    </div>
                    <Popup
                        open={isBuyCardsPopupOpen}
                        closeOnDocumentClick={false}
                        modal
                    >
                        <BuyPopup
                            setIsOpen={setIsBuyCardsPopupOpen}
                            onClick={purchaseCards}
                        />
                    </Popup>
                </ShopItemContainer>
                <Footer>
                    <ClassicButton onClick={() => navigate(route.DASHBOARD)}>
                        <Home
                            viewBox="0 10 165 165"
                            width="100%"
                            height="100%"
                        ></Home>
                    </ClassicButton>
                </Footer>
            </div>
        </>
    );
};
