import React, { useEffect, useState } from 'react';
import DashboardBackground from '../../assets/dashboard.jpg';
import { BackgroundImage } from '../../BackgroundImage';
import { Center, Container, Text } from './ActivateEmail.styles';
import { verifyEmail } from './sendVerification';
import { ActivateEmailSuccess } from './ActivateEmailSuccess';

export const ActivateEmail = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const username = urlParams.get('username');
    console.log(token, username);

    useEffect(() => {
        if (username === null || token === null) {
            setErrorMsg('Token not found');
            return;
        }

        verifyEmail(username, token).then((message) => {
            if (message !== '') {
                setErrorMsg('Token not found.');
                return;
            }
            setSuccessMsg('Congrats! You activated your account.');
        });
    }, []);

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <Container>
                <Center>
                    <Text>{errorMsg}</Text>
                    <ActivateEmailSuccess message={successMsg} />
                </Center>
            </Container>
        </div>
    );
};
