import React, { ReactNode } from 'react';
import { Button } from './ButtonAccept.styles';

interface Props {
    onclick: () => void;
    children: ReactNode;
}

export const ButtonAccept = ({ children, onclick }: Props) => {
    return <Button onClick={onclick}>{children}</Button>;
};
