import React from "react";
import { GemContainer } from "./Gem.style";
import { getColor } from "./getColor";

type CardGemType = {
    rareLvl: number;
    isDetail?: boolean; 
};

export const Gem = ({rareLvl, isDetail = false}: CardGemType) => {
  return <GemContainer rareLvl={getColor(rareLvl)} isDetail={isDetail}>
  </GemContainer>;
};