import React, { useState } from "react";
import "./UserCardCatalog.css";
import { CardInterface } from "../../Types/CardInterface";
import {
  CardCatalogContainer,
  CatalogSubContainer,
  CollectionContainer,
} from "../../Components/styled/Catalog";
import { Card } from "../../Components/Card/Card";

interface Props {
  allCards: CardInterface[];
  userCards: string[]
}

export const CardCatalog = ({allCards, userCards}: Props) => {
  const [openCard, setOpenCard] = useState<boolean[]>([]);
  const [block, setBlock] = useState(false);
  if (allCards.length === 0) return <></>;

  if (openCard.length === 0){
    setOpenCard(Array(allCards.length).fill(false))
  }

  return (
    <CollectionContainer >
      <CatalogSubContainer>
        {allCards.map((card: CardInterface, index: number) => {
          const isOwed = userCards.includes(card.ID.toString())
          return (
            <CardCatalogContainer key={card.ID} isOwed={isOwed}>
              <Card
                key={card.ID}
                card={card}
                block={block}
                setBlock={setBlock}
                openCard={openCard}
                setOpenCard={setOpenCard}
                index={index}
              />
            </CardCatalogContainer>
          );
        })}
      </CatalogSubContainer>
    </CollectionContainer>
  );
};
