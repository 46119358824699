import * as React from 'react';
import './MarketSideBar.scss';
import { route } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { MarketFormType } from '../../Types/MarketFormInterface';
import { SideBarContainer } from './SideBarStyle';

import { FidgetSpinnerLoader } from '../../../fidgetSpinner';

import { ReactComponent as Home } from '../../assets/icons/house.svg';
import { ReactComponent as Add } from '../../assets/icons/plus.svg';
import { ReactComponent as Profile } from '../../assets/icons/person.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import {
    IconContainer,
    PrestigePointsContainer,
    PrestigePointsNumber,
} from '../../Components/styled/PrestigePointsContainer';
import { IconButtonGreen } from '../../Components/styled/IconButton';
import { IconButtonContainer } from '../../Components/styled/IconButtonContainer';
interface SideBarProps {
    form: MarketFormType;
    setForm: React.Dispatch<React.SetStateAction<MarketFormType>>;
    prestigePoints: number;
}

export const MarketSideBar = (props: SideBarProps) => {
    const { form, setForm, prestigePoints } = props;

    const navigate = useNavigate();

    const handleDone = () => {
        navigate(route.DASHBOARD);
    };

    return (
        <SideBarContainer>
            <div className="title center_text height_block_top white_text">
                Market
            </div>
            <div className="h-20"></div>

            <div className="h-20"></div>
            <div className="title center_text white_text">Search</div>
            <div className="h-20"></div>
            <input
                className="current_deck center_input_text"
                type="text"
                id="new_deck"
                value={form.Search}
                onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
                    setForm({ ...form, Search: element.target.value });
                }}
            />
            <div className="h-20"></div>
            <PrestigePointsContainer>
                <PrestigePointsNumber className="number_font">
                    {prestigePoints !== -1 ? (
                        prestigePoints
                    ) : (
                        <FidgetSpinnerLoader size={25} />
                    )}
                </PrestigePointsNumber>
                <IconContainer>
                    <Star
                        viewBox="-5 -1 25 25"
                        width="100%"
                        height="100%"
                    ></Star>
                </IconContainer>
            </PrestigePointsContainer>

            <div className="h-20"></div>

            <div className="h-20"></div>
            <IconButtonContainer onClick={() => navigate(route.MARKET_ADD)}>
                <IconButtonGreen>
                    <Add
                        viewBox="-210 5 465 465"
                        width="100%"
                        height="100%"
                    ></Add>
                </IconButtonGreen>
            </IconButtonContainer>
            <div className="h-20"></div>
            <div className="h-20"></div>
            <div className="h-20"></div>
            <IconButtonContainer onClick={() => navigate(route.MARKET_MY)}>
                <IconButtonGreen>
                    <Profile
                        viewBox="-2600 -860 5960 5960"
                        width="100%"
                        height="100%"
                    ></Profile>
                </IconButtonGreen>
            </IconButtonContainer>
            <div className="h-20"></div>

            <div
                className="done_deck_container pointer"
                onClick={() => {
                    handleDone();
                }}
            >
                <div className="done_deck white_text">
                    <Home
                        viewBox="-450 28 1085 1085"
                        width="100%"
                        height="100%"
                    ></Home>
                </div>
            </div>
        </SideBarContainer>
    );
};
