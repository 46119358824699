import React, { useRef } from 'react';
import './SideBar.scss';
import { useState, useEffect } from 'react';
import { MainDeckInterface } from '../../Types/CardInterface';
import { route } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { CardsInDeck } from '../../Components/CardsInDeck';
import { ReactComponent as Trashcan } from '../../../assets/icons/trashcan.svg';

import {
    IconButtonGreen,
    IconButtonRed,
} from '../../Components/styled/IconButton';
import { NewDeckForm } from './NewDeckForm';
import {
    CenterFlexContainer,
    SearchInput,
} from '../../Components/styled/SideBarStyle';
import { SearchCardForm } from '../../Types/SearchCardForm';
import { DeckSettings } from './DeckSettings';

interface SideBarProps {
    validDeck: string;
    deckNames: Array<string>;
    setDeckNames: React.Dispatch<React.SetStateAction<string[]>>;
    deckCards: MainDeckInterface;
    setDeckCards: React.Dispatch<React.SetStateAction<MainDeckInterface>>;
    updateDeck: (changeDeck: string) => void;
    deleteDeck: () => void;
    form: SearchCardForm;
    setForm: React.Dispatch<React.SetStateAction<SearchCardForm>>;
    hasNotValidCard: boolean;
}

export const SideBar = (props: SideBarProps) => {
    const {
        validDeck,
        deckNames,
        setDeckNames,
        deckCards,
        setDeckCards,
        updateDeck,
        deleteDeck,
        form,
        setForm,
        hasNotValidCard,
    } = props;

    const navigate = useNavigate();

    const handleDone = () => {
        navigate(route.DASHBOARD);
    };

    return (
        <div className="side-bar">
            <div className="title center_text height_block_top white_text">
                Current Deck
            </div>
            <div className="h-20"></div>
            <div className="current_deck">{deckCards.DeckName}</div>
            <div className="h-20"></div>
            <CenterFlexContainer>
                <SearchInput
                    placeholder="Search"
                    onChange={(
                        element: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                        setForm({ ...form, Search: element.target.value });
                    }}
                />
            </CenterFlexContainer>
            <div className="h-20"></div>
            <CardsInDeck hasMargin={true} deckCards={deckCards} />

            {deckNames.length >= 2 && (
                <>
                    <div className="h-20"></div>
                    <CenterFlexContainer onClick={deleteDeck}>
                        <IconButtonRed>
                            <Trashcan
                                viewBox="-175 5 420 420"
                                width="100%"
                                height="100%"
                            ></Trashcan>
                        </IconButtonRed>
                    </CenterFlexContainer>
                </>
            )}

            {hasNotValidCard ? (
                <div className="valid_deck center_text white_text">
                    Delete deck or remove red marked cards from your deck.
                </div>
            ) : (
                <DeckSettings
                    updateDeck={updateDeck}
                    setDeckCards={setDeckCards}
                    setDeckNames={setDeckNames}
                    validDeck={validDeck}
                    deckNames={deckNames}
                    deckCards={deckCards}
                    handleDone={handleDone}
                />
            )}
        </div>
    );
};
