import React, { useCallback } from 'react';
import { ButtonContainer, Text } from './ActivateEmail.styles';
import { ButtonAccept } from '../../components/Buttons';
import { route } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Home } from '../../assets/icons/house.svg';

type Props = {
    message: string;
};

export const ActivateEmailSuccess = ({ message }: Props) => {
    const navigate = useNavigate();

    const goToDashboard = useCallback(() => {
        navigate(route.DASHBOARD);
    }, []);

    if (message === '') {
        return <></>;
    }

    return (
        <>
            <Text>{message}</Text>
            <ButtonContainer>
                <ButtonAccept onclick={goToDashboard}>
                    <Home viewBox="20 20 150 150" width="100%" height="100%" />
                </ButtonAccept>
            </ButtonContainer>
        </>
    );
};
