import styled from "styled-components";

export const DropdownContainer = styled.div`
  min-height: 20px;
  text-align: center;
  block-size: fit-content;
  border: solid 2px darkgray;
  padding: 5px;
  border-radius: 5px;
  background-color: lightgray;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectContainer = styled.select`
  background-color: lightgray;
  border: none;
  display: inline-block;

  &:focus {
    outline: none;
    box-shadow: 0 0 0px rgba(0, 0, 255, 0.5);
  }
`;
