import { UserDashboardType } from "../userData/Types";
import { getCookie, deleteCookie } from "../getCookie";
import { SERVER_URL } from "../constants";

export const getUserFromToken = async () => {
  const cookieValue = getCookie("bloodstor_user");
  const res = await fetch(`https://${SERVER_URL}/user`, {
    method: "GET",
  headers: {
    Authorization: `Bearer ${cookieValue}`,
    "Content-Type": "application/json",
  },
  });
  if (res.ok) {
    const data: UserDashboardType = await res.json();
    return data;
    // set user state (should be in admin context so it can be loaded from anywhere)
  } else {
    //delte cookie
    deleteCookie("bloodstor_user");
  }
  return undefined;
};
