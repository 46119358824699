export function generateRandomString(n: number): string {
    const length: number = Math.floor(Math.random() * n) + 1;
    const characters: string =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomString: string = '';

    for (let i = 0; i < length; i++) {
        const randomIndex: number = Math.floor(
            Math.random() * characters.length,
        );
        randomString += characters[randomIndex];
    }

    return randomString;
}

export function getRandomInt(): number {
    return Math.floor(Math.random() * 101);
}

export function getRandomIntBetween(n: number): number {
    return Math.floor(Math.random() * n + 1);
}

export function getRandomCardType(): string {
    if (Math.random() < 0.5) {
        return 'Monster';
    }
    return 'Spell';
}

export function getCoinFlipBoolean(): boolean {
    return Math.random() < 0.5;
}

export function isLocalhost(): boolean {
    return window.location.href.includes('localhost');
}
