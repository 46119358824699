import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { route } from '../../constants';
import { navigateTo } from '../../navigateTo';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Navbar.scss';
import { FidgetSpinnerLoader } from '../../fidgetSpinner';
import { ClassicButton } from '../../market/Components/styled';
import { MatchPopupRow, MatchWindow, NavbarContainer } from './NavbarContainer';
import { logout } from '../../Logout';
import { Button } from '../Buttons';
import { FitContent } from './Navbar.styles';
import { ModalPopup, ModalPopupCenter } from '../styled/ModalPopup';
import { Center } from '../../pages/Login/Dashboard.styles';

interface NavbarInterface {
    Username: undefined | string;
}

export const Navbar = (props: NavbarInterface) => {
    const { Username } = props;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate();

    const handleFindMatch = (type: string) => {
        navigateTo(`/arena?type=${type}`);
    };

    const closePopup = useCallback(() => {
        setIsPopupOpen(false);
    }, []);

    const openPopup = useCallback(() => {
        setIsPopupOpen(true);
    }, []);

    const ChooseGameType = () => {
        return (
            <ModalPopup onClick={closePopup}>
                <ModalPopupCenter>
                    <MatchWindow>
                        <MatchPopupRow
                            onClick={() => handleFindMatch('normal')}
                        >
                            Normal
                        </MatchPopupRow>
                        <MatchPopupRow
                            onClick={() => handleFindMatch('ranked')}
                        >
                            Ranked
                        </MatchPopupRow>
                        <MatchPopupRow className="game_type">
                            Coming soon
                        </MatchPopupRow>
                    </MatchWindow>
                </ModalPopupCenter>
            </ModalPopup>
        );
    };

    return (
        <>
            <FitContent>
                <Button onclick={() => navigate(route.USER)}>
                    {Username === undefined ? (
                        <FidgetSpinnerLoader size={25} />
                    ) : (
                        Username
                    )}
                </Button>
            </FitContent>
            <NavbarContainer>
                <ClassicButton onClick={openPopup}>Find Match</ClassicButton>
                <Popup open={isPopupOpen} closeOnDocumentClick={false} modal>
                    <ChooseGameType />
                </Popup>
                <ClassicButton onClick={() => navigate(route.DECK_BUILDER)}>
                    Builder
                </ClassicButton>
                <ClassicButton onClick={() => navigate(route.MARKET)}>
                    Market
                </ClassicButton>
                <ClassicButton onClick={() => navigate(route.SHOP)}>
                    Shop
                </ClassicButton>
                <ClassicButton onClick={() => navigate(route.CARD_COLLECTION)}>
                    Gallery
                </ClassicButton>
            </NavbarContainer>
        </>
    );
};
