import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BackgroundMarket, Container } from './Components/BackgroundMarketDiv';

type BackgroundImageType = {
    src: string;
    width?: number;
    height?: number;
};

export const BackgroundImageSideBar = (props: BackgroundImageType) => {
    const { src, width, height } = props;
    const browserWidth = window.innerWidth;
    const browserHeight = window.innerHeight;

    if (browserWidth < 1200) {
        return (
            <Container>
                <BackgroundMarket>
                    <LazyLoadImage
                        effect="blur"
                        src={src}
                        style={{
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            width: '100vw',
                            height: '100vh',
                            backgroundPosition: 'center',
                        }}
                    />
                </BackgroundMarket>
            </Container>
        );
    }
    return (
        <BackgroundMarket>
            <LazyLoadImage
                effect="blur"
                src={src}
                width={browserWidth}
                height={browserHeight}
            />
        </BackgroundMarket>
    );
};
