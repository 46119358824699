import { type DefaultTheme } from 'styled-components';

import { Color } from './units';

export const light: DefaultTheme = {
    button: {
        backgroundColor: 'black',
    },
    text: {
        primary: Color.white[100],
        secondary: Color.white[80],
        tertiary: Color.white[70],
        accent: Color.cta.main,
    },
};
