import React from 'react';
import { CardInterface } from '../../Types/CardInterface';
import { CardStats, HandCardStyle, CrackingMask } from './CardStyle';
import cracksImage from '../../../assets/card/cracks.png';
import { useState } from 'react';
import { CardCost } from './Cost';
import { CardImage } from './Image';
import { Name } from './Name';
import { Plus } from './Plus';
import { Attack } from './Attack';
import { Life } from './Life';
import './CardPopup.css';
import { CardBack } from './CardBack';

type MarketCardType = {
    card: CardInterface;
    index: number;
};

type CardType = {
    card: CardInterface;
};
const MonsterHand = (props: CardType) => {
    const { card } = props;
    const size = 30;
    return (
        <>
            <CardCost size={size} cost={card.Cost} />
            <CardImage image={card.CardImage} />
            <Name name={card.CardName} isSpell={false} />
            <Plus size={size} plus={card.RegeneratePoints} />
            <Attack size={size} attack={card.Attack} />
            <Life size={size} life={card.Life} />
        </>
    );
};

const SpellCard = (props: CardType) => {
    const { card } = props;
    const size = 25;
    return (
        <>
            <CardCost size={size} cost={card.Cost} />
            <CardImage image={card.CardImage} />
            <Name name={card.CardName} isSpell={true} />
        </>
    );
};

export const Card = (props: MarketCardType) => {
    const { card, index } = props;
    const CardData = card.CardType === 'Spell' ? SpellCard : MonsterHand;
    const [rotation, setRotation] = useState('rotateX(0deg) rotateY(0deg)');
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        const card = event.currentTarget;
        const cardRect = card.getBoundingClientRect();
        const cardCenterX = cardRect.left + cardRect.width / 2;
        const cardCenterY = cardRect.top + cardRect.height / 2;
        const mouseX = event.clientX - cardCenterX;
        const mouseY = event.clientY - cardCenterY;
        const rotateX = -mouseY / 5;
        const rotateY = mouseX / 5;
        const transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        setRotation(transform);
    };

    const handleMouseLeave = () => {
        setRotation('');
        setIsHovered(false);
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    if (!isClicked) {
        return <CardBack index={index} setIsClicked={setIsClicked} />;
    }

    return (
        <>
            <HandCardStyle
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                className="card-content"
                isSpellCard={card.CardType === 'Spell'}
                isHovered={isHovered}
                rotation={rotation}
            >
                <CrackingMask
                    className="cracks-layer"
                    style={{
                        backgroundImage: `url(${cracksImage})`,
                    }}
                />
                <CardStats>
                    <CardData card={card} />
                </CardStats>
            </HandCardStyle>
        </>
    );
};
