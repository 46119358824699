import React, { useEffect, useState } from 'react';
import { getAccountSlots } from './getAccountSlots';
import { AccountSlotsType } from '../../Types/AccountSlotsType';
import { Text } from './AccountSlots.styles';
import { isLocalhost } from '../../factoryHelpers';

export const AccountSlots = () => {
    const [accountSlots, setAccountSlots] = useState<AccountSlotsType>();
    useEffect(() => {
        if (!isLocalhost()) {
            getAccountSlots().then((data) => {
                setAccountSlots(data);
            });
        }
    }, []);

    if (!isLocalhost() && accountSlots === undefined) {
        return <></>;
    }
    return (
        <Text>
            {accountSlots?.users || 2} / {accountSlots?.maxUsers || 3}
            <br />
            Accounts available
        </Text>
    );
};
