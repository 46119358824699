import React from 'react';
import DashboardBackground from '../../../assets/dashboard.jpg';
import { BackgroundImage } from '../../../BackgroundImage';
import { Center, Container, Text } from './InvalidToken.styles';

export const InvalidToken = () => {
    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <Container>
                <Center>
                    <Text>Invalid Token</Text>
                </Center>
            </Container>
        </div>
    );
};
