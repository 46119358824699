import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const InfoBox = styled.div`
    color: white;
    text-shadow: 2px 2px rgb(20, 20, 20);
    position: fixed;
    top: 5px;
    left: 50%;
    padding: 10px;
    transform: translate(-50%, 0%);
    border: solid 2px rgb(0, 0, 0);
    border-radius: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    animation: ${fadeOut} 0.5s forwards;
    animation-delay: 2s;
`;
