import styled from "styled-components";

export const CatalogContainer = styled.div`
  left: 260px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px 0px;
  padding-left: 35px;
  padding-right: 20px;

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide the scrollbar for other browsers */
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media (max-width: 675px) {
    position: unset;
    max-height: 100vh;
    max-height: 100svh;
  }
`;

export const CatalogSubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  justify-content: space-evenly;
  @media (max-width: 675px) {
    position: unset;
    gap: 45px;
  }
`;

export const MarketCard = styled.div`
  position: relative;
`;

export const CardCatalogContainer = styled.div`
  position: relative;
`;
