import * as React from 'react';
import { useState, useRef, useCallback } from 'react';
import './UserCardCatalog.css';
import { CardInterfaceAmount } from '../../Types/CardInterface';
//import { CardMyMarket } from "../../Components/Card";
import { Card } from '../../Components/Card/Card';
import { CardInterface } from '../../Types/CardInterface';
import {
    AmountContainer,
    Center,
} from '../../Components/styled/MarketPriceStyle';
import {
    CardCatalogContainer,
    CatalogContainer,
    CatalogSubContainer,
} from '../../Components/styled/Catalog';
import { useClickOutside } from '../../useClickOutside';

interface UserCardCatalogProps {
    detailCardId: string;
    setDetailCardId: React.Dispatch<React.SetStateAction<string>>;
    allCards: CardInterfaceAmount[];
    setChoosenCard: React.Dispatch<
        React.SetStateAction<CardInterface | undefined>
    >;
}

export const UserCardCatalogAdd = (props: UserCardCatalogProps) => {
    const { allCards, setChoosenCard } = props;
    const [selectedCard, setSelectedCard] = useState<number>();

    const ref = useRef(null);

    const onReset = useCallback(() => setSelectedCard(undefined), []);
    useClickOutside(ref, onReset);

    const selectCard = (card_index: number) => {
        setSelectedCard(card_index);
    };

    return (
        <CatalogContainer onClick={onReset}>
            <CatalogSubContainer>
                {allCards !== null &&
                    allCards.map((data: CardInterfaceAmount, index: number) => {
                        return (
                            <CardCatalogContainer key={index}>
                                <Center>
                                    <AmountContainer className="number_font">
                                        {data.Amount}
                                    </AmountContainer>
                                </Center>
                                <Card
                                    card={data.Card}
                                    index={index}
                                    marketOption="create"
                                    selectCard={selectCard}
                                    addToMarket={setChoosenCard}
                                    selectedCard={selectedCard}
                                />
                            </CardCatalogContainer>
                        );
                    })}
            </CatalogSubContainer>
        </CatalogContainer>
    );
};
