import {
    generateRandomString,
    getRandomCardType,
    getRandomInt,
    getRandomIntBetween,
} from '../factoryHelpers';
import {
    CardInterface,
    CardInterfaceAmount,
    MarketCardInterface,
} from './CardInterface';

export function createCard(cardData: Partial<CardInterface>): CardInterface {
    const defaultCard: CardInterface = {
        Attack: getRandomInt(),
        AttacksCounter: 0,
        Cost: getRandomInt(),
        Effect: '',
        Element: '',
        Description: '',
        ID: '',
        IsDead: false,
        IsShiny: '',
        IsVisible: false,
        IsWall: false,
        Life: getRandomInt(),
        CardName: generateRandomString(20),
        Price: getRandomInt(),
        Rank: getRandomIntBetween(5),
        RegeneratePoints: getRandomInt(),
        SellValue: 0,
        SkinType: '',
        CardTribe: '',
        CardType: getRandomCardType(),
        CardImage:
            'https://images.unsplash.com/photo-1618614944895-fc409a83ad80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80',
    };

    return { ...defaultCard, ...cardData };
}

export function createMarketCard(
    cardAmountData: Partial<MarketCardInterface>,
): MarketCardInterface {
    const defaultMarketCard: MarketCardInterface = {
        Card: createCard({}),
        Price: getRandomIntBetween(10001).toString(),
        Amount: getRandomIntBetween(10001).toString(),
    };

    return { ...defaultMarketCard, ...cardAmountData };
}

export function addMarketCard(
    cardAmountData: Partial<CardInterfaceAmount>,
): CardInterfaceAmount {
    const defaultMarketCard: CardInterfaceAmount = {
        Card: createCard({}),
        Amount: getRandomIntBetween(10001).toString(),
    };

    return { ...defaultMarketCard, ...cardAmountData };
}

export function myMarketCard(
    cardAmountData: Partial<MarketCardInterface>,
): MarketCardInterface {
    const defaultMarketCard: MarketCardInterface = {
        Card: createCard({}),
        Amount: getRandomIntBetween(10001).toString(),
        Price: getRandomIntBetween(10001).toString(),
    };

    return { ...defaultMarketCard, ...cardAmountData };
}
