import { getCoinFlipBoolean, getRandomInt } from '../factoryHelpers';
import { DashboardType } from './DashboardLoader';

export function createDashboardUser(
    user: Partial<DashboardType>,
): DashboardType {
    const defaultUser: DashboardType = {
        GamePoints: getRandomInt(),
        IsBanned: false,
        IsActive: getCoinFlipBoolean(),
        PrestigePoints: getRandomInt(),
        Username: 'Swagnator',
    };

    return { ...defaultUser, ...user };
}
