import React, { useEffect, useState } from 'react';
import './Shop.scss';
import 'reactjs-popup/dist/index.css';

import { CardInterface } from '../Types/CardInterface';
import { FooterButton } from './FooterButton';
import { Card } from '../components/Card/Card';
import { NewCardsContainer } from '../components/styled/IconHeaderStyle';

type NewCardsType = {
    cards: CardInterface[];
    setCards: React.Dispatch<React.SetStateAction<CardInterface[]>>;
};

export const NewCards = (props: NewCardsType) => {
    const { cards, setCards } = props;
    const [renderedCards, setRenderedCards] = useState<CardInterface[]>([]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (cards.length > renderedCards.length) {
                setRenderedCards([
                    ...renderedCards,
                    cards[renderedCards.length],
                ]);
            }
        }, 800);

        return () => clearTimeout(timerId);
    }, [renderedCards, cards]);

    return (
        <>
            <NewCardsContainer />
            <div className="shop__new_card_container">
                {renderedCards.map((card: CardInterface, index: number) => (
                    <Card key={index} index={index} card={card} />
                ))}
            </div>
            <FooterButton onClick={() => setCards([])} text={'Back'} />
        </>
    );
};

// <Circle />
