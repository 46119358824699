import { SERVER_CARDS_URL, SERVER_URL } from "../../constants";
import { MainDeckInterface } from "../Types/CardInterface";

enum methods {
  DELETE = "DELETE",
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

const adminRequest = async (url: string, token: string, method = "GET", Data = {}) => {
  let response;

  if (method === "PUT" || method === "POST") {
    response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Data),
    });
  } else {
    response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }

  if (response.status === 401) {
    throw new Error("unauthorized");
  }

  try {
    return await response.json();
  } catch (err) {
    return err;
  }
};

export class DeckBackend {
  url: string;
  //token: string;
  constructor() {
    this.url = `https://${SERVER_CARDS_URL}`;
    //this.token = token;
  }

  getMarketCards(token: string) {
    return adminRequest(`${this.url}/market_cards`, token);
  }
  getUserPrestigePoints(token: string) {
    return adminRequest(`${this.url}/user_prestige_points`, token);
  }
  getUserMarketCards(token: string) {
    return adminRequest(`${this.url}/market_card`, token);
  }

  getDeckMainCards(token: string) {
    return adminRequest(`${this.url}/user_deck_main`, token);
  }
  getUserCards(token: string) {
    console.log(token, "token")
    return adminRequest(`${this.url}/user_cards`, token, "GET");
  }
  changeDeckMain(token: string, deck_id: number) {
    return adminRequest(
      `${this.url}/user_deck_main`, token,
      "PUT",
      deck_id
    );
  }
  getDeckNames(token: string) {
    return adminRequest(`${this.url}/user_deck_names`, token);
  }

  updateDeck(token: string, deck: MainDeckInterface) {
    return adminRequest(
      `${this.url}/user_deck_main`, token,
      "POST",
      deck
    );
  }
}
