import styled from 'styled-components';

export const ConfirmationPopupContainer = styled.div`
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    padding: 10px;
    text-align: center;
`;
