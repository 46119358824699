import { CardInterfaceAmount } from "./Types/CardInterface";

export const filterAndSortByCardName = (
  cardArray: CardInterfaceAmount[],
  searchString: string
) => {
  const searchLowerCase = searchString.toLowerCase();
  return cardArray
    .sort((cardA, cardB) => {
      const cardAName = cardA.Card.CardName.toLowerCase();
      const cardBName = cardB.Card.CardName.toLowerCase();
      return cardAName.localeCompare(cardBName);
    })
    .filter((card) =>
      card.Card.CardName.toLowerCase().includes(searchLowerCase)
    );
};
