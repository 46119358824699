import styled from 'styled-components';

export const Center = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const Container = styled.div`
    margin: 0;
    height: 100vh;
    position: relative;
    height: 100svh;
`;

export const Text = styled.p`
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    text-shadow: 2px 2px rgb(20, 20, 20);
`;
