import * as React from 'react';
import './MarketSideBar.scss';
import { route } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { MarketFormType } from '../../Types/MarketFormInterface';
import { SearchInput, SideBarResponsiveContainer } from './SideBarStyle';

import { Dropdown } from '../../Components/Dropdown';
import { NavigationType } from '../../Types/DropdownNavigation';
import { CardInterface } from '../../Types/CardInterface';

interface SideBarProps {
    form: MarketFormType;
    setForm: React.Dispatch<React.SetStateAction<MarketFormType>>;
    addToMarket: () => Promise<void>;
    choosenCard: CardInterface | undefined;
    addMarketForm: {
        Price: string;
        CardId: string;
    };
    setAddMarketForm: React.Dispatch<
        React.SetStateAction<{
            Price: string;
            CardId: string;
        }>
    >;
}

export const MarketSideBarResponsiveAdd = (props: SideBarProps) => {
    const { form, setForm } = props;

    const navigate = useNavigate();

    const navigationDict: NavigationType[] = [
        {
            label: 'Add',
            onClick: () => navigate(route.MARKET_ADD),
        },
        {
            label: 'Market',
            onClick: () => navigate(route.MARKET),
        },
        {
            label: 'My Market',
            onClick: () => navigate(route.MARKET_MY),
        },
    ];

    return (
        <SideBarResponsiveContainer>
            <SearchInput
                type="text"
                id="new_deck"
                placeholder="Search"
                value={form.Search}
                onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
                    setForm({ ...form, Search: element.target.value });
                }}
            />

            <Dropdown options={navigationDict} />
        </SideBarResponsiveContainer>
    );
};
