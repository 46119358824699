import React, { useState, useRef, useCallback } from 'react';
import './UserCardCatalog.css';
import { MarketCardInterface } from '../../Types/CardInterface';
import { Card } from '../../Components/Card/Card';
import {
    CardCatalogContainer,
    CatalogContainer,
    CatalogSubContainer,
} from '../../Components/styled/Catalog';
import { useClickOutside } from '../../useClickOutside';
import {
    AmountContainer,
    Center,
    PriceContainer,
} from '../../Components/styled/MarketPriceStyle';
import {
    MyMArketStarIcon,
    MyMarketPriceConatiner,
    PrestigePointsContainerCatalog,
} from '../../Components/styled/PrestigePointsContainer';
import { ReactComponent as Star } from '../../../assets/icons/star.svg';

interface UserCardCatalogProps {
    detailCardId: string;
    setDetailCardId: React.Dispatch<React.SetStateAction<string>>;
    allCards: MarketCardInterface[];
    bringMarketCardBackToUser: (card_id: string) => Promise<void>;
}

export const UserCardCatalogMy = (props: UserCardCatalogProps) => {
    const { allCards, bringMarketCardBackToUser } = props;
    const [selectedCard, setSelectedCard] = useState<number>();

    const ref = useRef(null);

    const onReset = useCallback(() => setSelectedCard(undefined), []);
    useClickOutside(ref, onReset);

    const selectCard = (card_index: number) => {
        setSelectedCard(card_index);
    };

    return (
        <CatalogContainer onClick={onReset}>
            <CatalogSubContainer>
                {allCards !== null &&
                    allCards.map((data: MarketCardInterface, index: number) => {
                        return (
                            <CardCatalogContainer key={index}>
                                <Center>
                                    <AmountContainer className="number_font">
                                        {data.Amount}
                                    </AmountContainer>
                                </Center>
                                <Card
                                    key={index}
                                    card={data.Card}
                                    index={index}
                                    marketOption="my_market"
                                    selectCard={selectCard}
                                    backToUser={bringMarketCardBackToUser}
                                    selectedCard={selectedCard}
                                />
                                <MyMarketPriceConatiner>
                                    <PriceContainer className="number_font">
                                        {data.Price}
                                    </PriceContainer>
                                    <MyMArketStarIcon>
                                        <Star width="100%" height="100%"></Star>
                                    </MyMArketStarIcon>
                                </MyMarketPriceConatiner>
                            </CardCatalogContainer>
                        );
                    })}
            </CatalogSubContainer>
        </CatalogContainer>
    );
};
