import styled from 'styled-components';
import { SMALLER_CARD } from '../../constants';

const GemSize = 10;

export const GemContainer = styled.div<{ rareLvl: string; isDetail?: boolean }>`
    position: absolute;
    width: ${GemSize}px;
    height: ${GemSize}px;
    right: calc(50% - ${GemSize}px);
    top: ${({ isDetail }) => (isDetail ? 'unset' : 'calc(71%)')};
    background-color: ${(props) => props.rareLvl};
    transform: ${({ isDetail }) =>
        isDetail ? 'rotate(45deg) translate(-5px, -5px)' : 'rotate(45deg)'};
    border-radius: 20%;
    border-bottom: 3px solid rgba(255, 255, 255, 0.5);
    border-right: 3px solid rgba(0, 0, 0, 0.5);
    border-top: 3px solid rgba(255, 255, 255, 0.5);
    border-left: 3px solid rgba(0, 0, 0, 0.5);
    @media (max-width: ${SMALLER_CARD}) {
        top: ${({ isDetail }) => (isDetail ? 'unset' : 'calc(62%)')};
    }
`;

GemContainer.defaultProps = {
    isDetail: false,
};

export const Shine = styled.div`
    position: absolute;
    top: 1px;
    left: 1px;
    width: 70%;
    height: 50%;
    background: rgba(255, 255, 255, 1);
    border-radius: 20%;
    transform: rotate(0deg);
    filter: blur(1px);
`;

export const Shadow = styled.div`
    position: absolute;
    bottom: -20%;
    left: -20%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20%;
    /*
  background: linear-gradient(to bottom right, orange, #000000);
  box-shadow: 0 0 10px rgba(255, 161, 61, 0.5), 0 0 20px rgba(255, 161, 61, 0.3), 0 0 30px rgba(255, 161, 61, 0.2);
    filter: blur(1px);
    */
`;
