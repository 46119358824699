import styled, { keyframes } from 'styled-components';

export const FormInput = styled.input`
    display: block;
    margin: 0 auto;
    padding: 0.7rem;
    border: none;
    border-radius: 0.2rem;
    margin-bottom: 1.2rem;
    font-size: 1rem;
    flex-basis: 70%;
`;

export const EmailInfoText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 1.2rem;
    text-shadow: 2px 2px rgb(20, 20, 20);
`;

export const FormTitle = styled.div`
    display: block;
    margin: 0 auto;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 1.2rem;
    text-shadow: 2px 2px rgb(20, 20, 20);
`;

export const BannedText = styled.div`
    position: absolute;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 1.2rem;
    text-shadow: 2px 2px rgb(20, 20, 20);
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    transform: translateY(50%);
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const FormFooter = styled.div`
    width: 100%;
`;

export const FormContainerCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

export const FormErrorMessage = styled.div`
    color: white;
    text-shadow: 2px 2px rgb(20, 20, 20);
    position: fixed;
    top: 5px;
    left: 50%;
    padding: 10px;
    transform: translate(-50%, 0%);
    background-color: rgba(112, 15, 15, 0.6);
    border: solid 2px rgb(0, 0, 0);
    border-radius: 10px;
    animation: ${fadeOut} 0.5s forwards;
    animation-delay: 2s;
`;

export const FormSuccessMessage = styled.div`
    color: white;
    text-shadow: 2px 2px rgb(20, 20, 20);
    position: fixed;
    top: 5px;
    left: 50%;
    padding: 10px;
    transform: translate(-50%, 0%);
    background-color: rgba(15, 112, 26, 0.6);
    border: solid 2px rgb(0, 0, 0);
    border-radius: 10px;
    animation: ${fadeOut} 0.5s forwards;
    animation-delay: 2s;
`;

export const ForgotPasswordContainer = styled.div`
    width: 100%;
`;

export const ForgotPasswordText = styled.div`
    width: fit-content;
    color: lightgray;
    text-shadow: 2px 2px rgb(20, 20, 20);
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.5);
    transition: color ease-in 0.1s;

    &:hover {
        color: var(--text-color);
    }
`;

export const DeleteText = styled.div`
    margin: auto;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px rgb(20, 20, 20);
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.3);
    transition: color ease-in 0.1s;
`;
