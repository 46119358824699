import { defer, LoaderFunction } from 'react-router-dom';

import { CardInterfaceAmount, MainDeckInterface } from "../Types/CardInterface";
import { DeckBackend } from '../Api/AdminAPI';
import { getCookie } from '../../getCookie';
import { isLocalhost } from '../factoryHelpers';
import { createMainDeck } from '../Types/CardInterface.factory';

export type LoaderData = {
    mainDeck: MainDeckInterface;
    allCards: CardInterfaceAmount[];
    deckNames: string[];
};

export const loader_2 = (async () => {
    //const domains = await AntcoreClient.getDomains();
    const api = new DeckBackend();
    const cookieValue = getCookie("bloodstor_user") || "";
    const mainDeckData = createMainDeck({ DeckName: 'My Deck' });

    const mainDeck = isLocalhost() ? mainDeckData : await api.getDeckMainCards(cookieValue) 
    const allCards = isLocalhost() ? mainDeckData.Cards : await api.getUserCards(cookieValue)
    const deckNames = isLocalhost() ? [mainDeckData.DeckName] : await api.getDeckNames(cookieValue)

    return defer({ mainDeck, allCards, deckNames });
}) satisfies LoaderFunction;

export const loader = async () => {
    const api = new DeckBackend();
    const cookieValue = getCookie("bloodstor_user") || "";
    const mainDeckData = createMainDeck({ DeckName: 'My Deck' });

    const mainDeck = isLocalhost() ? mainDeckData : await api.getDeckMainCards(cookieValue) 
    const allCards = isLocalhost() ? mainDeckData.Cards : await api.getUserCards(cookieValue)
    const deckNames = isLocalhost() ? [mainDeckData.DeckName] : await api.getDeckNames(cookieValue)

    return { mainDeck, allCards, deckNames };
}