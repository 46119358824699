import React, { useEffect, useState } from 'react';
import { BackgroundImage } from '../../BackgroundImage';
import DashboardBackground from './../../assets/dashboard.jpg';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';

import { FidgetSpinnerLoader } from '../../fidgetSpinner';
import { getCookie } from '../Api';
import type { EloRaningType, UserDashboardType } from '../Types';
import { deleteCookie } from '../../getCookie';
import { SERVER_URL, route } from '../../constants';
import { UsernameStyle } from '../components/styled/UsernameStyle';
import { StatRow } from '../components/styled/StatContainer';
import { CenterBox } from '../components/styled/CenterBox';
import { MatchHistory } from '../components/MatchHistory/MatchHistory';
import {
    SettingPopup,
    SettingPopupRow,
    Settings,
} from '../components/styled/SettingsContainer';
import { ReactComponent as Home } from '../../assets/icons/house.svg';

import { ReactComponent as SettingIcon } from '../Assets/icons/settings.svg';
import { ClassicButton, Footer } from './UserData.styles';
import { logout } from '../../Logout';
import { isLocalhost } from '../../factoryHelpers';

export const UserData = () => {
    const navigate = useNavigate();
    const cookie = getCookie('bloodstor_user');
    const [user, setUser] = useState<UserDashboardType | undefined>();
    const [eloRanking, setEloRanking] = useState<EloRaningType>();
    const [winRate, setWinRate] = useState('');

    useEffect(() => {
        if (user !== undefined) {
            getRank();
            getWinRate();
            return;
        }
        if (!isLocalhost()) {
            getUserFromToken();
        }
    }, [user]);
    // if user does not exist in admin context get the data
    const getUserFromToken = async () => {
        const res = await fetch(`https://${SERVER_URL}/user`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${cookie}`,
                'Content-Type': 'application/json',
            },
        });
        if (res.ok) {
            const data: UserDashboardType = await res.json();
            setUser(data);
            // set user state (should be in admin context so it can be loaded from anywhere)
        } else {
            //delte cookie
            deleteCookie('bloodstor_user');
            navigate(route.LOGIN);
        }
    };

    const getWinRate = async () => {
        if (user === undefined) {
            return;
        }
        const res = await fetch(
            `https://${SERVER_URL}/win_rate?username=${user?.Username}`,
            {
                method: 'GET',
            },
        );
        if (res.ok) {
            const res_data = await res.json();
            setWinRate(res_data);
        }
    };

    const getRank = async () => {
        if (user === undefined) {
            return;
        }
        const res = await fetch(
            `https://${SERVER_URL}/elo?username=${user?.Username}`,
            {
                method: 'GET',
            },
        );
        if (res.ok) {
            const res_data = await res.json();
            setEloRanking(res_data);
        }
    };

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />;
            <Popup
                trigger={
                    <Settings>
                        <SettingIcon
                            viewBox="-4 -4 40 40"
                            width="100%"
                            height="100%"
                        ></SettingIcon>
                    </Settings>
                }
                modal
            >
                <SettingPopup>
                    <SettingPopupRow onClick={logout}>Logout</SettingPopupRow>
                    <SettingPopupRow
                        onClick={() => navigate(route.CHANGE_PASSWORD)}
                    >
                        Change password
                    </SettingPopupRow>
                    <SettingPopupRow
                        onClick={() => navigate(route.DELETE_ACCOUNT)}
                    >
                        Delete account
                    </SettingPopupRow>
                </SettingPopup>
            </Popup>
            <CenterBox>
                <UsernameStyle>
                    {user?.Username ?? <FidgetSpinnerLoader size={20} />}
                </UsernameStyle>
                <StatRow>
                    <div>Elo</div>
                    <div className="number_font">
                        {eloRanking?.Elo ?? <FidgetSpinnerLoader size={20} />}
                    </div>
                </StatRow>
                <StatRow>
                    <div>Rank</div>
                    <div className="number_font">
                        {eloRanking?.Rank ?? <FidgetSpinnerLoader size={20} />}
                    </div>
                </StatRow>
                <StatRow>
                    <div>Win rate</div>
                    <div className="number_font">
                        {winRate === '' ? (
                            <FidgetSpinnerLoader size={20} />
                        ) : (
                            winRate + '%'
                        )}
                    </div>
                </StatRow>
                <MatchHistory username={user?.Username || ''} />
            </CenterBox>
            <Footer>
                <ClassicButton onClick={() => navigate(route.DASHBOARD)}>
                    <Home
                        viewBox="0 10 165 165"
                        width="100%"
                        height="100%"
                    ></Home>
                </ClassicButton>
            </Footer>
        </div>
    );
};
