import * as React from "react";
import "./SideBar.scss";
import { route } from "../../../constants";
import { useNavigate } from "react-router-dom";
import {
  FooterButton,
  FooterContainer,
  SearchInputResponsive,
  SideBarResponsiveContainer,
} from "../../Components/styled/SideBarStyle";
import { SearchCardForm } from "../../Types/SearchCardForm";
import { ReactComponent as Home } from "../../../assets/icons/house.svg";
import { Dropdown } from "../../Components/Dropdown";

interface Props {
  form: SearchCardForm;
  setForm: React.Dispatch<React.SetStateAction<SearchCardForm>>;
}

export const SideBarResponsive = ({form, setForm}: Props) => {
  const navigate = useNavigate();
  const rarityLevel = [
    "No filter",
    "Normal",
    "Ordinary",
    "Rare",
    "Heroic",
    "Epic",
    "Legendary",
  ]
  
  return (
    <>
      <SideBarResponsiveContainer>
        <SearchInputResponsive
          placeholder="Search"
          onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
            setForm({ ...form, Search: element.target.value });
          }}
        />
        <Dropdown
          defaultSelectedValue={'No filter'}
          options={rarityLevel}
          form={form}
          setForm={setForm}
        />
      </SideBarResponsiveContainer>

      
      <FooterContainer>
        <FooterButton onClick={() => navigate(route.DASHBOARD)}>
          <Home viewBox="0 0 185 185" width="100%" height="100%"></Home>
        </FooterButton>
      </FooterContainer>
    </>
  );
};