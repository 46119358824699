import styled from 'styled-components';
import { CARD_SYMBOLS_OFFSET } from '../../constants';

export const LifeContainer = styled.div`
    position: absolute;
    bottom: ${CARD_SYMBOLS_OFFSET};
    right: ${CARD_SYMBOLS_OFFSET};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;
