import styled from 'styled-components';
import { rgba } from 'polished';

type Component = number;
type Color = `rgb(${Component},${Component},${Component})`;

export const SideBarContainer = styled.div`
    background-color: black;
    width: 260px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    box-shadow: 5px 0 20px 5px rgb(229, 229, 229);
    z-index: 5;
`;

export const SideBarResponsiveContainer = styled.div`
    position: sticky;
    display: flex;
    padding: 10px 0px;
    justify-content: space-evenly;
    background-color: rgba(0, 0, 0, 1);
`;

export const SearchInput = styled.input`
    min-height: 20px;
    width: 50px;
    text-align: center;
    block-size: fit-content;
    border: solid 2px darkgray;
    padding: 5px;
    border-radius: 5px;
    background-color: lightgray;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const SideBarButton = styled.button`
    background-color: rgba(23, 207, 23, 0.7);
    block-size: fit-content;
    border: 2px solid #d3d3d3;
    border-radius: 5px;
    min-height: 20px;
    overflow: hidden;
    padding: 5px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #d3d3d3;
    text-shadow: 1px 1px #141414;
    cursor: pointer;
    font-size: 16px;
    width: 190px;
    width: -webkit-fill-available;
    width: -moz-available;
    margin: 0px 35px;
    &:hover {
        background-color: rgba(23, 207, 23, 0.9);
        color: #ffffff;
        transition: all 0.1s ease;
    }
`;

export const FooterButtonIconContainer = styled.div`
    max-height: 1.5rem;
    max-width: 1.5rem;
    padding: 0.5rem 1rem;
    border: 0.1rem white solid;
    border-radius: 0.5rem;
    margin: auto;
    cursor: pointer;
`;

export const IconContainer = styled.div`
    max-height: 15px;
    max-width: 15px;
    margin: auto;
`;

export const PrestigePointsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px white solid;
    border-radius: 5px;
    padding: 5px;
`;

export const PrestigePointsText = styled.div`
    color: var(--text-color);
    font-size: 1rem;
    font-weight: bold;
    max-width: 45vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
