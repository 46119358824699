import React, { useState, useEffect } from 'react';
import { SERVER_CARDS_URL } from '../constants';
import { BackgroundImage } from '../BackgroundImage';
import DashboardBackground from '../assets/dashboard.jpg';
import { BoxCenterRegisterLogin } from '../market/Components/styled';
import { EmailInfoText } from '../market/Components/styled/FormInput';

export const DeleteUserConfirm = () => {
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [tokenState, setToken] = useState<string | null>();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        setToken(token);
    }, []);

    useEffect(() => {
        if (tokenState) {
            deleteUserConfirm();
        }
    }, [tokenState]);

    const deleteUserConfirm = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const res = await fetch(
            `https://${SERVER_CARDS_URL}/update_user_password`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        if (res.ok) {
            setSuccessMessage('Account deleted');
        } else {
            setSuccessMessage('Not valid link');
        }
    };

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <BoxCenterRegisterLogin>
                <EmailInfoText>{successMessage}</EmailInfoText>
            </BoxCenterRegisterLogin>
        </div>
    );
};
