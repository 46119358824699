import { NewCardPack } from '../../Types/NewCardPack';
import { SERVER_CARDS_URL } from '../../constants';
import { getCookie } from '../getCookie';

export const getNewCardPackData = async () => {
    const cookieValue = getCookie('bloodstor_user');
    const res = await fetch(`https://${SERVER_CARDS_URL}/get_new_cards_price`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${cookieValue}`,
            'Content-Type': 'application/json',
        },
    });
    if (res.ok) {
        const data: NewCardPack = await res.json();
        return data;
    }
};
