import { defer, LoaderFunction } from 'react-router-dom';

import { getUserFromToken } from '../../api/getUserFromToken';
import { getRank } from './getRank';
import { isLocalhost } from '../../factoryHelpers';
import { createDashboardUser } from '../../Types/DashboardLoader.factory';
import { createElo } from '../../Types/EloRanking.factory';

export type LoaderData = {
    elo: number;
    rank: number;
    isBanned: boolean;
    isActive: boolean;
    prestigePoints: number;
    username: string;
    gamePoints: number;
};

export const loader_w = (async () => {
    const user = isLocalhost()
        ? createDashboardUser({})
        : await getUserFromToken();
    if (user === undefined) return;

    const username = user.Username;
    const prestigePoints = user.PrestigePoints;
    const isBanned = user.IsBanned;

    const eloRank = isLocalhost() ? createElo({}) : await getRank(username);
    if (eloRank === undefined) return;
    const elo = eloRank.Elo;
    const rank = eloRank.Rank;
    const gamePoints = user.GamePoints;

    return defer({ elo, rank, isBanned, prestigePoints, gamePoints, username });
}) satisfies LoaderFunction;

export const loader = async () => {
    const user = isLocalhost()
        ? createDashboardUser({})
        : await getUserFromToken();
    if (user === undefined) return;

    const username = user.Username;
    const prestigePoints = user.PrestigePoints;
    const isBanned = user.IsBanned;
    const isActive = user.IsActive;

    const eloRank = isLocalhost() ? createElo({}) : await getRank(username);
    if (eloRank === undefined) return;
    const elo = eloRank.Elo;
    const rank = eloRank.Rank;
    const gamePoints = user.GamePoints;

    return {
        elo,
        rank,
        isBanned,
        isActive,
        prestigePoints,
        gamePoints,
        username,
    };
};
