import React from 'react';
import { BackgroundImage } from '../BackgroundImage';
import DashboardBackground from '../assets/dashboard.jpg';
import { BannedText } from '../market/Components/styled/FormInput';

export const BannedUser = () => {
    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <BannedText>This account is banned.</BannedText>
        </div>
    );
};
