import { CardInterfaceAmount } from "../../Types/CardInterface";

export const isValidCard = (allCards: CardInterfaceAmount[], deckCard: CardInterfaceAmount) => {
    /*
        If the amount of a card in the deck is higher then the amount that the user has the card amount. It should return false, because a user cant have more cards in the deck than he owens.
    */
    for (let i = 0; i < allCards.length; i++) {
        const userCard = allCards[i];
        if (userCard.Card.ID === deckCard.Card.ID) {
            if (userCard.Amount < deckCard.Amount) {
                return false;
            }else{
                return true
            }
        }
    }
    return false;
};