import { SERVER_CARDS_URL, SERVER_URL } from "../../constants";
import { CardInterfaceAmount, MainDeckInterface } from "../Types/CardInterface";

enum methods {
  DELETE = "DELETE",
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

const adminRequest = async (url: string, method = "GET", token="", Data = {}) => {
  let response;

  if (method === "PUT" || method === "POST") {
    Data = {...Data, Token: token}
    response = await fetch(url, {
      method: method,
      credentials: "include",
      body: JSON.stringify(Data),
    });
  } else {
    response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }

  if (response.status === 401) {
    throw new Error("unauthorized");
  }

  try {
    return await response.json();
  } catch (err) {
    return err;
  }
};

export class DeckBackend {
  url: string;
  constructor() {
    this.url = `https://${SERVER_CARDS_URL}`;
  }

  getUserCards(token: string): Promise<CardInterfaceAmount[]>  {
    return adminRequest(`${this.url}/user_cards`, "GET" ,token);
  }
  getDeckMainCards(token: string): Promise<MainDeckInterface> {
    return adminRequest(`${this.url}/user_deck_main`, "GET", token);
  }
  changeDeckMain(token: string, deck_id: number) {
    return adminRequest(
      `${this.url}/user_deck_main`,
      "PUT",
      token,
      deck_id
    );
  }
  getDeckNames(token: string): Promise<string[]> {
    return adminRequest(`${this.url}/user_deck_names`, "GET", token);
  }

  updateDeck(token: string, deck: MainDeckInterface, changeToDeck: string) {
    return adminRequest(
      `${this.url}/user_deck_main`,
      "POST",
      token,
      {DeckCards: deck, DeckName: changeToDeck},
    );
  }
}
