import { SERVER_URL } from "../../constants";
import { MatchesType } from "../Types";

export const getMatches = async (username: string) => {
  if(username === ""){
    return undefined
  }
    const res = await fetch(
      `https://${SERVER_URL}/match_history?username=${username}`,
      {
        method: "GET",
      }
    );
    if (res.ok) {
      const res_data: MatchesType[] = await res.json();
      return res_data
    }
    return undefined
  };