import * as React from 'react';
import './SideBar.scss';
import { MainDeckInterface } from '../../Types/CardInterface';
import { route } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import {
    CardsAmountResponsive,
    FooterButton,
    FooterContainer,
    SearchInput,
    SearchInputResponsive,
    SideBarResponsiveContainer,
} from '../../Components/styled/SideBarStyle';
import { Dropdown } from '../../Components/Dropdown';
import { NavigationType } from '../../Types/DropdownNavigation';
import { SearchCardForm } from '../../Types/SearchCardForm';
import { cardsInDeck } from '../../cardsInDeck';
import { CardsInDeck } from '../../Components/CardsInDeck';
import { ReactComponent as Save } from '../../../assets/icons/save.svg';
import { ReactComponent as Home } from '../../../assets/icons/house.svg';

interface SideBarProps {
    validDeck: string;
    deckNames: Array<string>;
    setDeckNames: React.Dispatch<React.SetStateAction<string[]>>;
    deckCards: MainDeckInterface;
    isResponsiveDeck: boolean;
    setDeckCards: React.Dispatch<React.SetStateAction<MainDeckInterface>>;
    setIsResponsiveDeck: React.Dispatch<React.SetStateAction<boolean>>;
    form: SearchCardForm;
    setForm: React.Dispatch<React.SetStateAction<SearchCardForm>>;
    updateDeck: (changeDeck: string) => void;
    deleteDeck: () => void;
    setIsNewDeckScreen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteDeckScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideBarResponsive = (props: SideBarProps) => {
    const {
        deckCards,
        deckNames,
        form,
        isResponsiveDeck,
        setForm,
        setIsDeleteDeckScreen,
        setIsNewDeckScreen,
        setIsResponsiveDeck,
        updateDeck,
    } = props;

    const navigate = useNavigate();

    const navigationDict: NavigationType[] = [];

    for (const name of deckNames) {
        navigationDict.push({
            label: name,
            onClick: () => {
                updateDeck(name);
            },
        });
    }

    navigationDict.push({
        label: 'New Deck',
        onClick: () => setIsNewDeckScreen(true),
    });

    const cardSetting: NavigationType[] = [
        {
            label: 'All Cards',
            onClick: () => setIsResponsiveDeck(true),
        },
        {
            label: 'Deck',
            onClick: () => setIsResponsiveDeck(false),
        },
    ];

    if (deckNames.length > 1) {
        cardSetting.push({
            label: 'Delete deck',
            onClick: () => setIsDeleteDeckScreen(true),
        });
    }

    return (
        <>
            <SideBarResponsiveContainer>
                <SearchInputResponsive
                    placeholder="Search"
                    onChange={(
                        element: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                        setForm({ ...form, Search: element.target.value });
                    }}
                />
                <Dropdown
                    defaultSelectedValue={isResponsiveDeck ? 'Cards' : 'Deck'}
                    options={cardSetting}
                />
                <Dropdown
                    defaultSelectedValue={deckCards.DeckName}
                    options={navigationDict}
                />
            </SideBarResponsiveContainer>
            <FooterContainer>
                <CardsInDeck hasMargin={false} deckCards={deckCards} />
                <FooterButton onClick={() => navigate(route.DASHBOARD)}>
                    <Home
                        viewBox="0 0 185 185"
                        width="100%"
                        height="100%"
                    ></Home>
                </FooterButton>
                <FooterButton onClick={() => updateDeck(deckCards.DeckName)}>
                    <Save viewBox="0 0 25 25" width="100%" height="100%"></Save>
                </FooterButton>
            </FooterContainer>
        </>
    );
};
//          <Home viewBox="-80 0 185 185" width="100%" height="100%"></Home>
//
