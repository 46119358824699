import styled from 'styled-components';
import { rgba } from 'polished';

export const StyleButton = styled.button<{ color: string }>`
    background-color: ${({ color }) => rgba(color, 0.8)};
    color: white;
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: ${({ color }) => rgba(color, 1)};
    }
`;
