import React from "react";
import { NameContainer } from "./NameStyle";

type CardNameType = {
  name: string;
  isSpell: boolean;
};

export const Name = (props: CardNameType) => {
  const { name, isSpell } = props;
  return <NameContainer isSpellCard={isSpell}>{name}</NameContainer>;
};
