import { useEffect } from 'react';
import { MarketCardInterface } from '../Types/CardInterface';
import { filterCards } from '../filterCards';
import { MarketFormType } from '../Types/MarketFormInterface';

export function useFilteredAndSortedCards(
    cards: MarketCardInterface[],
    sortedCards: MarketCardInterface[],
    form: MarketFormType,
    setAllCards: React.Dispatch<React.SetStateAction<MarketCardInterface[]>>,
) {
    useEffect(() => {
        if (form.Search === '') {
            setAllCards(sortedCards);
        } else {
            const filteredCards = filterCards(sortedCards, form.Search); // Implement filterCards function
            setAllCards(filteredCards);
        }
    }, [form]);
}
