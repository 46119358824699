import { getRandomInt } from "../factoryHelpers";
import { EloRankngType } from "./EloRanking";

  export function createElo(eloRanking: Partial<EloRankngType>): EloRankngType {
  const defaultObject: EloRankngType = {
    Elo: getRandomInt(),
    Rank: getRandomInt() 
  };

  return { ...defaultObject, ...eloRanking };
}