import { SERVER_URL } from '../../constants';

export const sendForgotEmail = async (usernameOrEmail: string) => {
    const res = await fetch(`https://${SERVER_URL}/forgot`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            Username_Or_Email: usernameOrEmail,
        }),
    });
    if (res.ok) {
        return 'Please check your email';
    }
    const data: { message: string } = await res.json();
    return data.message;
};
