import React, { useCallback } from 'react';
import './MarketSideBar.scss';
import { useEffect } from 'react';
import { MAX_MARKET_PRICE, route } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { MarketFormType } from '../../Types/MarketFormInterface';
import { CardInterface } from '../../Types/CardInterface';
import { CardPure } from '../../Components/Card/CardPure';
import { CardContainer } from '../../Components/Card/CardStyle';

import { ReactComponent as Back } from '../../assets/icons/left_arrow.svg';
import { IconContainer } from '../../Components/styled/AddCardResponsiveStyle';
import { ReactComponent as Plus } from './../../assets/icons/plus.svg';

interface SideBarProps {
    form: MarketFormType;
    setForm: React.Dispatch<React.SetStateAction<MarketFormType>>;
    addToMarket: () => Promise<void>;
    choosenCard: CardInterface | undefined;
    addMarketForm: {
        Price: string;
        CardId: string;
    };
    setAddMarketForm: React.Dispatch<
        React.SetStateAction<{
            Price: string;
            CardId: string;
        }>
    >;
}

export const MarketAddSideBar = (props: SideBarProps) => {
    const {
        form,
        setForm,
        choosenCard,
        addMarketForm,
        setAddMarketForm,
        addToMarket,
    } = props;

    const navigate = useNavigate();

    useEffect(() => {}, [form]);

    const handleDone = () => {
        navigate(route.MARKET);
    };

    const handleValue = useCallback(
        (element: React.ChangeEvent<HTMLInputElement>) => {
            const priceFloat = parseFloat(element.target.value);
            const price = isNaN(priceFloat) ? 1 : priceFloat;
            const minPrice = Math.min(price, MAX_MARKET_PRICE);
            console.log(minPrice);
            setAddMarketForm({
                ...addMarketForm,
                Price: minPrice.toString(),
            });
        },
        [addMarketForm],
    );

    const MonsterCard = () => {
        if (choosenCard === undefined) {
            return <></>;
        }
        return (
            <div id="card" className="card default_cursor">
                <div id="card" className="card_image">
                    <img src={choosenCard.CardImage} alt="" />
                </div>
                <div id="card" className="card_attack">
                    {choosenCard.Attack}
                </div>
                <div id="card" className="card_life">
                    {choosenCard.Life}
                </div>
            </div>
        );
    };

    const SpellCard = () => {
        if (choosenCard === undefined) {
            return <></>;
        }
        return (
            <div id="card" className="card_spell default_cursor">
                <div id="card" className="card_image">
                    <img src={choosenCard.CardImage} alt="" />
                </div>
            </div>
        );
    };

    const Card = () => {
        if (choosenCard === undefined) {
            return <></>;
        }
        return (
            <div
                id="card"
                className="card_container card_container_market_sidebar"
            >
                {choosenCard.CardType === 'Spell' ? (
                    <SpellCard />
                ) : (
                    <MonsterCard />
                )}
            </div>
        );
    };

    return (
        <div className="side-bar">
            <div className="title center_text height_block_top white_text">
                Market
            </div>
            <div className="h-20"></div>

            <div className="h-20"></div>
            <div className="title center_text white_text">Search</div>
            <div className="h-20"></div>
            <input
                className="current_deck center_input_text"
                type="text"
                id="new_deck"
                value={form.Search}
                onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
                    setForm({ ...form, Search: element.target.value });
                }}
            />

            <div className="h-20"></div>
            <div className="outline_container">
                <div className="title center_text white_text">Sell price</div>
                <div className="h-20"></div>
                <input
                    className="current_deck center_input_text"
                    type="text"
                    id="new_deck"
                    value={addMarketForm.Price}
                    onChange={handleValue}
                />
                <div className="h-20"></div>
                <div className="title center_text white_text">Card</div>
                {choosenCard === undefined ? (
                    <></>
                ) : (
                    <>
                        <div className="h-20"></div>
                        <div className="h-20"></div>
                        <CardContainer>
                            <CardPure card={choosenCard} />
                        </CardContainer>
                        <div className="h-20"></div>
                        <div className="h-20"></div>
                    </>
                )}
                <div className="h-20"></div>
            </div>

            <div className="h-20"></div>
            <div className="h-20"></div>
            {choosenCard === undefined ||
            addMarketForm.Price === '' ||
            isNaN(Number(addMarketForm.Price)) ||
            Number(addMarketForm.Price) < 0 ? (
                <div className="white_text center_text">
                    Choose a card and set a price
                </div>
            ) : (
                <div
                    className="done_deck white_text pointer"
                    onClick={() => {
                        addToMarket();
                    }}
                >
                    <IconContainer>
                        <Plus width="100%" height="100%" />
                    </IconContainer>
                </div>
            )}

            <div
                className="done_deck_container pointer"
                onClick={() => {
                    handleDone();
                }}
            >
                <div className="done_deck white_text">
                    <Back
                        viewBox="-130 0 285 285"
                        width="100%"
                        height="100%"
                    ></Back>
                </div>
            </div>
        </div>
    );
};
