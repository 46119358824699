import styled from 'styled-components';

export const NameContainer = styled.div<{ isSpellCard: boolean }>`
    color: rgb(0, 0, 0);
    font-size: 10px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    background-color: ${({ isSpellCard }) =>
        isSpellCard ? 'rgb(140, 235, 149)' : 'rgb(244, 235, 138)'};

    box-shadow: ${({ isSpellCard }) =>
        isSpellCard ? '#8d6c1a 1px 1px 5px' : 'darkgoldenrod 1px 1px 5px'};

    background-color: ${({ isSpellCard }) =>
        isSpellCard ? '1px solid #8d6c1a' : '1px solid darkgoldenrod'};
    border-top: 2px solid rgba(255, 255, 255, 0.35);
    border-left: 2px solid rgba(255, 255, 255, 0.35);
    border-right: 2px solid rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
    margin: 5px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
