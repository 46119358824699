import React, { ReactNode } from 'react';
import { Button as ButtonStyle } from './Button.styles';

interface Props {
    onclick: () => void;
    children: ReactNode;
}

export const Button = ({ children, onclick }: Props) => {
    return <ButtonStyle onClick={onclick}>{children}</ButtonStyle>;
};
