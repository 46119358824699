import React, { useEffect, useState } from "react";
import { MainDeckInterface } from "../../Types/CardInterface";
import { NewDeckForm } from "./NewDeckForm";
import { IconButtonGreen } from "../../Components/styled/IconButton";

import { ReactComponent as Save } from "../../../assets/icons/save.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Home } from "../../../assets/icons/house.svg";
import { InfoBox } from "../../../cardCollection/Components/styled";

type Props = {
    updateDeck: (changeDeck: string) => void;
    handleDone: () => void;
    setDeckCards: React.Dispatch<React.SetStateAction<MainDeckInterface>>
    setDeckNames: React.Dispatch<React.SetStateAction<string[]>>
    validDeck: string
    deckNames: string[]
    deckCards: MainDeckInterface
}

export const DeckSettings = ({updateDeck, setDeckCards, setDeckNames, validDeck, deckNames, deckCards, handleDone} : Props) => {
    const [showInfoBox, setShowInfoBox] = useState(false)

    useEffect(() => {
      let timeoutId: NodeJS.Timeout;
  
      if (showInfoBox) {
        timeoutId = setTimeout(() => {
          setShowInfoBox(false);
        }, 2500);
      }
  
      return () => {
        clearTimeout(timeoutId);
      };
    }, [showInfoBox]);

    return (
      <>
      <div className="h-20"></div>
      <div className="title center_text white_text">All Decks</div>
      <div className="h-20"></div>
      <div className="all_decks_list">
        {deckNames.sort().map((data: string, index: number) => {
          return (
            <div
              key={index}
              className="all_decks_list_item pointer"
              onClick={() => {
                updateDeck(data);
              }}
            >
              {data}
            </div>
          );
        })}
      </div>
      <div className="h-20"></div>
      <div className="title center_text white_text">Create New Deck</div>
      <div className="h-20"></div>
      <NewDeckForm deckNames={deckNames} setDeckCards={setDeckCards} setDeckNames={setDeckNames} />
      {validDeck === "" && (
        <>
          <div className="h-20"></div>
          <div
            className="title center_text white_text pointer"
            onClick={() => {
              updateDeck(deckCards.DeckName)
              //get some response from update deck to see if save was successful
              setShowInfoBox(true)
            }}
          >
            <IconButtonGreen>
              <Save viewBox="-80 0 185 185" width="100%" height="100%"></Save>
            </IconButtonGreen>
          </div>
        </>
      )}
        {validDeck !== "" && (
        <div className="valid_deck center_text white_text">
          Deck must have at least 40 cards!
        </div>
      )}
    {validDeck === "" && (
        <div
          className="done_deck_container pointer"
          onClick={() => {
            updateDeck(deckCards.DeckName);
            handleDone();
          }}
        >
          <div className="done_deck white_text">
                <Home viewBox="-450 28 1085 1085" width="100%" height="100%"></Home>
          </div>
    </div>
      )}
      {showInfoBox && <InfoBox color="green">Saved</InfoBox>}
      </>
    )
  }