import styled from "styled-components";

export const DashboardUserInfo = styled.nav`
  display: flex;
  align-items: center;
  height: 60px;
  color: #fff;
  padding: 20px 0px;
  justify-content: space-between;
  flex-wrap: wrap;
  height: fit-content;
  margin: 0px 145px;
  @media only screen and (max-width: 768px) {
    margin: 0px;
    justify-content: space-around;
  }
`;
