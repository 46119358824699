import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const BoxCenterRegisterLoginDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 420px;
    height: 420px;
    width: 80%;
    position: absolute;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.4);
    border: solid 2px rgb(0, 0, 0);
    border-radius: 10px;
    opacity: 0;
    animation: ${fadeIn} ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
`;

export const BoxCenterRegisterLogin = styled.form`
    max-width: 420px;
    width: 80%;
    position: absolute;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.4);
    border: solid 2px rgb(0, 0, 0);
    border-radius: 10px;
    opacity: 0;
    animation: ${fadeIn} ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
`;

export const BoxCenterRegister = styled.form`
    max-width: 420px;
    width: 80%;
    position: absolute;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.4);
    border: solid 2px rgb(0, 0, 0);
    border-radius: 10px;
    opacity: 0;
    animation: ${fadeIn} ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
`;

export const BackArrowContainer = styled.div`
    cursor: pointer;
    position: relative;
    top: -10px;
    left: -5px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
