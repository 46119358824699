import React, { useMemo, useState } from 'react';
import type { MatchesType } from '../../Types';
import {
    CardContainer,
    CardPlayer,
    CardPlayers,
    PlayerElo,
    Player,
    Date,
    GameType,
    Versus,
    GameResult,
    MatchHistoryContainer,
} from '../styled/Card';
import { loader } from './MatchHistory.loader';

type MatchHistoryType = {
    username: string;
};

export const MatchHistory = (props: MatchHistoryType) => {
    const { username } = props;

    const [matches, setMatches] = useState<MatchesType[]>();

    useMemo(() => {
        if (matches === undefined) {
            loader(username).then((data) => {
                setMatches(data.matchHistory);
            });
        }
    }, [matches, username]);

    if (matches === undefined) {
        return <>Error</>;
    }
    if (matches.length === 0) {
        return <></>;
    }

    return (
        <MatchHistoryContainer>
            {matches.map((match: MatchesType) => {
                return (
                    <CardContainer
                        key={match.ID}
                        isWinner={match.Winner === username}
                    >
                        <GameResult>
                            {match.Winner === username ? 'Victory' : 'Defeat'}
                        </GameResult>
                        <CardPlayers>
                            <CardPlayer>
                                <Player>{match.Winner}</Player>
                                <PlayerElo className="number_font">
                                    {match.WinnerElo}
                                </PlayerElo>
                            </CardPlayer>
                            <Versus>vs.</Versus>
                            <CardPlayer>
                                <Player>{match.Loser}</Player>
                                <PlayerElo className="number_font">
                                    {match.LoserElo}
                                </PlayerElo>
                            </CardPlayer>
                        </CardPlayers>
                        <GameType>
                            {match.Type.charAt(0).toUpperCase() +
                                match.Type.slice(1)}
                        </GameType>
                        <Date className="number_font">
                            {match.CreatedAt.split('T')[0].replaceAll('-', '.')}
                        </Date>
                    </CardContainer>
                );
            })}
        </MatchHistoryContainer>
    );
};
