import { SERVER_CARDS_URL, SERVER_URL } from "../../constants";
import { AddMarketFormType } from "../Types/AddMarketForm";
import { CardInterface, CardInterfaceAmount } from "../Types/CardInterface";
import { DeckBackend } from "./AdminAPI";

export const addToMarket = async (
  choosenCard: CardInterface | undefined,
  addMarketForm: AddMarketFormType,
  cookieValue: string | undefined,
  api: DeckBackend,
  setAllCards: (value: React.SetStateAction<CardInterfaceAmount[]>) => void,
  setAddMarketForm: (value: React.SetStateAction<AddMarketFormType>) => void,
  setChoosenCard: (
    value: React.SetStateAction<CardInterface | undefined>
  ) => void
) => {
  if (choosenCard === undefined || addMarketForm.Price === "") {
    return;
  }
  const formDict = {
    PrestigePointsPrice: addMarketForm.Price,
    CardId: choosenCard.ID.toString(),
  };
  if (cookieValue !== undefined) {
    const res = await fetch(
      `https://${SERVER_CARDS_URL}/market_card`,
      {
        method: "POST",
      credentials: "include",
        body: JSON.stringify({
          ...formDict,
        }),
        headers: {
          Authorization: `Bearer ${cookieValue}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.ok) {
      api
        .getUserCards(cookieValue)
        .then((response_json: Array<CardInterfaceAmount>) =>
          setAllCards(response_json)
        );
      setAddMarketForm({ Price: "", CardId: "" });
      setChoosenCard(undefined);
    }
  }
};
