import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HeartIcon from "../../../assets/card/heart.png";
import { LifeContainer } from "./LifeStyle";
import { TextContainer } from "./TextStyle";

type CardCostType = {
  life: number;
  size: number;
};

export const Life = (props: CardCostType) => {
  const { life, size } = props;
  return (
    <LifeContainer>
      <LazyLoadImage
        src={HeartIcon}
        alt={"life"}
        height={size}
        width={size}
        effect="blur"
      />
      <TextContainer>{life}</TextContainer>
    </LifeContainer>
  );
};
