import * as React from 'react';
import './MarketSideBar.scss';
import { route } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { MarketFormType } from '../../Types/MarketFormInterface';

import { ReactComponent as Back } from '../../assets/icons/left_arrow.svg';

interface SideBarProps {
    form: MarketFormType;
    setForm: React.Dispatch<React.SetStateAction<MarketFormType>>;
}

export const MarketSideBarMy = (props: SideBarProps) => {
    const { form, setForm } = props;

    const navigate = useNavigate();

    const handleDone = () => {
        navigate(route.MARKET);
    };

    return (
        <div className="side-bar">
            <div className="title center_text height_block_top white_text">
                Market
            </div>
            <div className="h-20"></div>

            <div className="h-20"></div>
            <div className="title center_text white_text">Search</div>
            <div className="h-20"></div>
            <input
                className="current_deck center_input_text"
                type="text"
                id="new_deck"
                value={form.Search}
                onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
                    setForm({ ...form, Search: element.target.value });
                }}
            />
            <div
                className="done_deck_container pointer"
                onClick={() => {
                    handleDone();
                }}
            >
                <div className="done_deck white_text">
                    <Back
                        viewBox="-130 0 285 285"
                        width="100%"
                        height="100%"
                    ></Back>
                </div>
            </div>
        </div>
    );
};
