import styled from 'styled-components';

export const TextContainer = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    display: grid;
    place-items: center;
    margin: 0px;
    padding: 0px;
    font-size: 1.1rem;
    text-shadow: black 0px 0px 2px;
    -webkit-text-stroke: 1px black;
    font-weight: 800;
    text-shadow: 1px 1px darkgoldenrod;
    @media (max-width: 800px) {
        font-size: 1.3rem;
    }
`;
