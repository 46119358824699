import { MainDeckInterface } from "../Types/CardInterface";
import { DeckBackend } from "./AdminAPI";

export const updateDeck = async (
  changeDeck: string,
  cookieValue: string | undefined,
  deckCards: MainDeckInterface,
  setDeckCards: (value: React.SetStateAction<MainDeckInterface>) => void
) => {
  if (cookieValue === undefined) return
  if (cookieValue === deckCards.DeckName) return
    const api = new DeckBackend();
    const DeckCards = deckCards;
   api.updateDeck(cookieValue, DeckCards, changeDeck).then((response_json: string) => {
    if(response_json !== "success") return
    api
      .getDeckMainCards(cookieValue)
      .then((response_json: MainDeckInterface) => setDeckCards(response_json));
   })
};
