import React from 'react';
import './SideBar.scss';
import { route } from '../../../constants';
import { useNavigate } from 'react-router-dom';

import {
    CenterFlexContainer,
    Checkbox,
    CheckboxContainer,
    CheckboxLabel,
    FilterContainer,
    Header,
    SearchInput,
} from '../../Components/styled/SideBarStyle';
import { SearchCardForm } from '../../Types/SearchCardForm';
import { ReactComponent as Home } from '../../../assets/icons/house.svg';

interface Props {
    form: SearchCardForm;
    setForm: React.Dispatch<React.SetStateAction<SearchCardForm>>;
}

export const SideBar = ({ form, setForm }: Props) => {
    const navigate = useNavigate();

    const handleDone = () => {
        navigate(route.DASHBOARD);
    };

    return (
        <div className="side-bar">
            <Header>Gallery</Header>
            <CenterFlexContainer>
                <SearchInput
                    placeholder="Search"
                    onChange={(
                        element: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                        setForm({ ...form, Search: element.target.value });
                    }}
                />
            </CenterFlexContainer>
            <FilterContainer>
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        id="normal"
                        name="normal"
                        onChange={() =>
                            setForm({ ...form, Normal: !form.Normal })
                        }
                    />
                    <CheckboxLabel htmlFor="normal">Normal</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        id="ordinary"
                        name="normal"
                        onChange={() =>
                            setForm({ ...form, Ordinary: !form.Ordinary })
                        }
                    />
                    <CheckboxLabel htmlFor="ordinary">Ordinary</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        id="rare"
                        name="normal"
                        onChange={() => setForm({ ...form, Rare: !form.Rare })}
                    />
                    <CheckboxLabel htmlFor="rare">Rare</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        id="heroic"
                        name="normal"
                        onChange={() =>
                            setForm({ ...form, Heroic: !form.Heroic })
                        }
                    />
                    <CheckboxLabel htmlFor="heroic">Heroic</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        id="epic"
                        name="normal"
                        onChange={() => setForm({ ...form, Epic: !form.Epic })}
                    />
                    <CheckboxLabel htmlFor="epic">Epic</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        id="legendary"
                        name="normal"
                        onChange={() =>
                            setForm({ ...form, Legendary: !form.Legendary })
                        }
                    />
                    <CheckboxLabel htmlFor="legendary">Legendary</CheckboxLabel>
                </CheckboxContainer>
            </FilterContainer>
            <div className="h-20"></div>
            <div className="done_deck_container pointer" onClick={handleDone}>
                <div className="done_deck white_text">
                    <Home
                        viewBox="-450 28 1085 1085"
                        width="100%"
                        height="100%"
                    ></Home>
                </div>
            </div>
        </div>
    );
};
