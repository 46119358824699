import * as React from 'react';
import { useState, useEffect } from 'react';
import { UserCardCatalogAdd, MarketAddSideBar } from '../Modules';
import { CardInterfaceAmount, CardInterface } from '../Types/CardInterface';
import { DeckBackend } from '../Api/AdminAPI';
import { getCookie } from '../../getCookie';
import { MarketFormType } from '../Types/MarketFormInterface';
import { addToMarket, filterUserCards } from '../Api';
import { AddMarketFormType } from '../Types/AddMarketForm';
import BackgroundImage from './../assets/market.jpg';
import { BackgroundImageSideBar } from '../BackgroundImage';
import { MarketSideBarResponsiveAdd } from '../Modules/SideBar/MarketSideBarResponsiveAdd';
import { AddCardResponsive } from '../Components/AddCardResponsive';
import { isLocalhost } from '../factoryHelpers';
import { addMarketCard } from '../Types/CardInterface.factory';
import { useFilteredAndSortedCardsAdd } from '../shared/useFilteredAndSortedCardsAdd';
import { sortByCardName, sortByCardNameAdd } from '../sortByCardName';

export const MarketAdd = () => {
    const [allCards, setAllCards] = useState<Array<CardInterfaceAmount>>([]);
    const [sortedCardsMemoized, setSortedCardsMemoized] = useState<
        Array<CardInterfaceAmount>
    >([]);
    const [addMarketForm, setAddMarketForm] = useState<AddMarketFormType>({
        Price: '',
        CardId: '',
    });
    const [detailCardId, setDetailCardId] = useState('');
    const [choosenCard, setChoosenCard] = useState<CardInterface>();
    const cookieValue = getCookie('bloodstor_user');
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    const api = new DeckBackend();
    const responsiveWidth = 675;

    const [form, SetForm] = useState<MarketFormType>({
        Search: '',
    });

    useEffect(() => {
        if (cookieValue === undefined) {
            return;
        }

        api.getUserCards(cookieValue).then(
            (response_json: Array<CardInterfaceAmount>) => {
                const sortedCards = sortByCardNameAdd(response_json);
                setAllCards(sortedCards);
                setSortedCardsMemoized(sortedCards);
            },
        );
    }, []);

    if (isLocalhost() && allCards.length === 0) {
        setAllCards([addMarketCard({})]);
    }

    useFilteredAndSortedCardsAdd(
        allCards,
        sortedCardsMemoized,
        form,
        setAllCards,
    );

    const handlePageClick = (event: MouseEvent) => {
        if (detailCardId !== '' && (event.target as Element).id !== 'card') {
            setDetailCardId('');
        }
    };

    const addToMarketInit = async () => {
        addToMarket(
            choosenCard,
            addMarketForm,
            cookieValue,
            api,
            setAllCards,
            setAddMarketForm,
            setChoosenCard,
        );
    };

    //const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const handleKeyDown = (event: any) => {
        if (event.key !== 'Escape') {
            return;
        }
        setDetailCardId('');
    };

    document.addEventListener('mousedown', handlePageClick);
    document.addEventListener('keyup', handleKeyDown);

    const SideBar =
        screenWidth > responsiveWidth
            ? MarketAddSideBar
            : MarketSideBarResponsiveAdd;

    if (screenWidth < responsiveWidth && choosenCard !== undefined) {
        return (
            <AddCardResponsive
                card={choosenCard}
                setChoosenCard={setChoosenCard}
                setAddMarketForm={setAddMarketForm}
                addMarketForm={addMarketForm}
                addToMarket={addToMarketInit}
            />
        );
    }

    return (
        <div className="body">
            <BackgroundImageSideBar
                src={BackgroundImage}
                width={1655}
                height={900}
            />

            <SideBar
                form={form}
                setForm={SetForm}
                choosenCard={choosenCard}
                setAddMarketForm={setAddMarketForm}
                addMarketForm={addMarketForm}
                addToMarket={addToMarketInit}
            />

            <UserCardCatalogAdd
                detailCardId={detailCardId}
                setDetailCardId={setDetailCardId}
                allCards={allCards}
                setChoosenCard={setChoosenCard}
            />
        </div>
    );
};
