import styled from 'styled-components';

export const ClassicButton = styled.button`
    padding: 5px 25px;
    margin: 5px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border: solid;
    border-color: #a1a1a1;
    border-width: 2px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    display: block;
    /* margin: 0 auto; */
    transition: all 0.25s linear;
    height: 65px;
    min-width: 120px;

    &:hover {
        color: #ccc;
        border-color: rgb(57, 208, 184);
        background-color: rgba(0, 0, 0, 1);
        transition: all 0.15s linear;
    }
`;

export const ClassicButtonSmall = styled.button`
    width: 100px;
    padding: 5px 15px;
    border-radius: 10px;
    border: solid;
    background-color: black;
    border-color: #a1a1a1;
    border-width: 2px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    display: block;
    background-color: rgba(0, 0, 0, 0.7);

    &:hover {
        color: #ccc;
        border-color: rgb(57, 208, 184);
        background-color: rgba(0, 0, 0, 1);
        transition: all 0.15s linear;
    }
`;
