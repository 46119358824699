import styled from "styled-components";

export const Footer = styled.div`
  position: absolute;
  z-index: 5;
  background-color: rgb(0, 0, 0);
  display: flex;
  padding: 10px 0px;
  justify-content: space-around;
  bottom: 0px;
  width: 100%;
`;

export const ClassicButton = styled.button`
  padding: 5px 15px;
  margin: 5px auto;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: solid;
  border-color: #a1a1a1;
  border-width: 2px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  display: block;
  /* margin: 0 auto; */
  transition: all 0.25s linear;
  height: 30px;
  width: 60px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: #ccc;
    border-color: rgb(57, 208, 184);
    background-color: rgba(0, 0, 0, 1);
    transition: all 0.15s linear;
  }
`;