import styled, { keyframes } from "styled-components";

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const InfoBox = styled.div<{ color: string }>`
  color: white;
  text-shadow: 2px 2px rgb(20, 20, 20);
  position: fixed;
  top: 5px;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, 0%);
  background-color: ${(props) => props.color};
  border: solid 2px rgb(0, 0, 0);
  border-radius: 10px;
  opacity: 0; /* Initial opacity set to 0 */
  animation: ${fadeIn} 0.2s forwards, ${fadeOut} 0.5s forwards; /* Use both fadeIn and fadeOut animations */
  animation-delay: 0s, 2s; /* No delay for fadeIn, 2-second delay for fadeOut */
`;