import styled from 'styled-components';

export const NavbarContainer = styled.nav`
    display: flex;
    align-items: center;
    height: 60px;
    color: #fff;
    padding: 20px 0px;
    justify-content: space-between;
    flex-wrap: wrap;
    height: fit-content;
    margin: 0px 145px;
    /* styles for phones */
    @media only screen and (max-width: 768px) {
        margin: 0px;
        justify-content: space-around;
    }
`;

export const MatchWindow = styled.div`
    max-width: fit-content;
    background-color: black;
    border-radius: 5px;
    border: 1px solid white;
`;

export const MatchPopupRow = styled.div`
    cursor: pointer;
    border-bottom: 1px solid lightgray;
    background-color: unset;
    overflow: hidden;
    color: white;
    padding: 0.8rem 4rem;
    transition: background-color 0.1s ease-in;
    text-align: center;
    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;
