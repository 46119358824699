import * as React from 'react';
import { useState, useEffect } from 'react';
import './CurrentDeck.css';
import {
    MainDeckInterface,
    CardInterfaceAmount,
} from '../../Types/CardInterface';
import { Card } from '../../Components/Card/Card';
import {
    AmountContainer,
    CardCatalogContainer,
    CatalogSubContainer,
    DeckContainer,
} from '../../Components/styled/Catalog';
import { CardSelectedType } from '../../Types/CardIndex';
import { isValidCard } from './isValidCard';

interface CurrentDeckProps {
    detailCardId: string;
    setDetailCardId: React.Dispatch<React.SetStateAction<string>>;
    deckCards: CardInterfaceAmount[];
    setDeckCards: React.Dispatch<React.SetStateAction<MainDeckInterface>>;
    removeCardFromDeck: (card: CardInterfaceAmount) => void;
    setSelectedCard: React.Dispatch<React.SetStateAction<CardSelectedType>>;
    allCards: CardInterfaceAmount[];
    selectedCard: CardSelectedType;
    setHasNotValidCard: React.Dispatch<React.SetStateAction<boolean>>;
    hasNotValidCard: boolean;
}

export const CurrentDeck = (props: CurrentDeckProps) => {
    const {
        deckCards,
        setDeckCards,
        detailCardId,
        setDetailCardId,
        removeCardFromDeck,
        selectedCard,
        allCards,
        setSelectedCard,
        setHasNotValidCard,
        hasNotValidCard,
    } = props;
    const [all_decks_list, setAllDecksList] = useState<Array<number>>([]);
    if (deckCards.length === 0) return <DeckContainer />;
    let allDeckValid = true;

    return (
        <DeckContainer>
            <CatalogSubContainer>
                {deckCards.map((data: CardInterfaceAmount, index: number) => {
                    const isValid = isValidCard(allCards, data);
                    if (allDeckValid && !isValid) {
                        allDeckValid = false;
                    }
                    if (!isValid && !hasNotValidCard) {
                        setHasNotValidCard(true);
                    }

                    if (index === deckCards.length - 1) {
                        if (allDeckValid && hasNotValidCard) {
                            setHasNotValidCard(false);
                        }
                    }
                    return (
                        <CardCatalogContainer isValid={isValid} key={index}>
                            <AmountContainer
                                className="number_font"
                                isValid={isValid}
                            >
                                {data.Amount}
                            </AmountContainer>
                            <Card
                                key={index}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                                index={index}
                                cardOnClick={removeCardFromDeck}
                                card_amount={data}
                                cardInDeck={false}
                                option="deck"
                            />
                        </CardCatalogContainer>
                    );
                })}
            </CatalogSubContainer>
        </DeckContainer>
    );
};
