import styled from 'styled-components';
import { MEDIUM_CARD, SMALLER_CARD } from '../../constants';

export const CatalogContainer = styled.div`
    left: 260px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: 675px) {
        position: unset;
        max-height: 100vh;
    }
`;

export const CollectionContainer = styled.div`
    left: 260px;
    position: absolute;
    top: 0px;
    height: calc(57%);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 10px;

    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide the scrollbar for other browsers */
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (max-width: 675px) {
        height: unset;
        top: 50px;
        position: absolute;
        bottom: 50px;
        left: unset;
        border-bottom: unset;
    }
`;

export const DeckContainer = styled.div`
    left: 260px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(38.8%);
    background-color: rgba(0, 0, 0, 0.5);

    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide the scrollbar for other browsers */
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (max-width: 675px) {
        top: 54px;
        position: absolute;
        left: unset;
        height: auto;
        border-bottom: unset;
        overflow-y: scroll;
        padding-bottom: 50px;
    }
`;

export const CatalogSubContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 15px;
    @media (max-width: 675px) {
        padding: 0px 0px;
    }
`;

export const MarketCard = styled.div`
    position: relative;
`;

export const CardCatalogContainer = styled.div<{ isValid?: boolean }>`
    position: relative;
    margin: 2rem 4rem;
    right: auto;
    box-shadow: ${({ isValid }) =>
        !isValid ? '0px 0px 5px 5px red;' : 'unset'};
    @media (min-width: ${SMALLER_CARD}) and (max-width: ${MEDIUM_CARD}) {
        margin: 1.8rem 2.2rem;
    }
    @media (max-width: ${SMALLER_CARD}) {
        margin: 1rem 1.5rem;
    }
`;

CardCatalogContainer.defaultProps = {
    isValid: true,
};

export const AmountContainer = styled.div<{ isValid?: boolean }>`
    color: ${({ isValid }) => (!isValid ? 'red' : 'white')};
    text-shadow: rgb(90 90 90) 1px 1px;
    text-align: center;
    font-size: medium;
    border: 1px solid white;
    border-bottom: none;
    background-color: black;
`;

AmountContainer.defaultProps = {
    isValid: true,
};

export const DeckText = styled.div`
    color: white;
    left: 260px;
    position: absolute;
    padding-left: 15px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    width: 100%;
    height: calc(3%);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: start;
    top: calc(58%);
`;

export const Triangle = styled.div`
    content: '';
    border: 0.3em solid;
    border-color: white transparent transparent;
    height: 100%;
    position: relative;
    top: 60%;
    margin: 0.5rem;
`;
