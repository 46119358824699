import React, { useCallback } from 'react';
import 'reactjs-popup/dist/index.css';
import { ConfirmationPopupContainer } from './BuyPopup.styles';
import { StyleFlexSpaceAround } from './StyleFlexSpaceAround';
import { StyleButton } from './StyleButton';
import { ModalPopup, ModalPopupCenter } from '../styled/ModalPopup';

type TypeBuyPopup = {
    onClick: () => void;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BuyPopup = (props: TypeBuyPopup) => {
    const { onClick, setIsOpen } = props;

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <ModalPopup onClick={handleClose}>
            <ModalPopupCenter>
                <ConfirmationPopupContainer
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <h3>Are you sure?</h3>
                    <StyleFlexSpaceAround>
                        <StyleButton
                            id="card"
                            color={'rgb(110, 200, 105)'}
                            onClick={onClick}
                        >
                            Buy
                        </StyleButton>
                        <StyleButton
                            onClick={handleClose}
                            color={'rgb(205, 73, 73)'}
                        >
                            Close
                        </StyleButton>
                    </StyleFlexSpaceAround>
                </ConfirmationPopupContainer>
            </ModalPopupCenter>
        </ModalPopup>
    );
};
