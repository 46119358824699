import styled from "styled-components";
import { rgba } from "polished";

type Component = number;
type Color = `rgb(${Component},${Component},${Component})`;

export const StyleButton = styled.button<{ alpha?: number }>`
  background-color: ${({ theme, alpha }) =>
    rgba(theme.button.backgroundColor, alpha)};
  color: white;
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => rgba(theme.button.backgroundColor, 1)};
  }
`;

StyleButton.defaultProps = {
  color: "rgba(61, 151, 183, 0.9)",
  alpha: 0.9,
};
