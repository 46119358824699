import styled from "styled-components";
import image from "./../assets/market.jpg"

export const BackgroundMarket = styled.div`
  position: fixed;
  right: 0px;
  width: 100vw;
  height: 100vh;

  img {
    max-width: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 930px) {
    display: none;
  }
`;

export const Container = styled.div`
  @media only screen and (max-width: 930px) {
    position: fixed;
  right: 0px;
  width: 100vw;
  height: 100vh;
  width: 100svw;
  height: 100svh;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;