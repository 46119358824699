import React from 'react';
import {
    IconContainer,
    TableHeaderPoints,
    TableHeaderPointsRow,
} from '../../../components/styled/BoxCenterRegisterLogin';

import { ReactComponent as Controller } from '../../../assets/icons/controller.svg';
import { ReactComponent as Star } from '../../../assets/icons/star.svg';

type Props = {
    prestigePoints: number | JSX.Element;
    gamePoints: number | JSX.Element;
};

export const Points = ({ prestigePoints, gamePoints }: Props) => {
    return (
        <table className="elo_container">
            <TableHeaderPoints>
                <tr>
                    <TableHeaderPointsRow>
                        <IconContainer>
                            <Controller
                                viewBox="0 0 30 30"
                                width="100%"
                                height="100%"
                            ></Controller>
                        </IconContainer>
                    </TableHeaderPointsRow>
                    <TableHeaderPointsRow>
                        <IconContainer>
                            <Star
                                viewBox="0 0 20 20"
                                width="100%"
                                height="100%"
                            ></Star>
                        </IconContainer>
                    </TableHeaderPointsRow>
                </tr>
            </TableHeaderPoints>
            <tbody>
                <tr>
                    <td className="number_font">{gamePoints}</td>
                    <td className="number_font">{prestigePoints}</td>
                </tr>
            </tbody>
        </table>
    );
};
