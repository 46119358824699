import * as React from "react";
import { MainDeckInterface } from "../../Types/CardInterface";
import {
  CenterScreenMiddle,
  ErrorMessage,
  FooterButton,
  FooterButtonRed,
  FooterContainer,
  HeightComponent,
  Shade,
  SpaceBetween,
} from "../../Components/styled/SideBarStyle";

interface SideBarProps {
  deckCards: MainDeckInterface;
  deleteDeck: () => void;
  setIsDeleteScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteDeck = (props: SideBarProps) => {
  const { deckCards, deleteDeck, setIsDeleteScreen } = props;

  return (
    <div className="body background_library">
      <Shade>
        <CenterScreenMiddle>
          <ErrorMessage>
            Delete deck <b>{deckCards.DeckName}</b>?
          </ErrorMessage>
          <HeightComponent height="20px" />
          <SpaceBetween>
            <FooterButton
              onClick={() => {
                setIsDeleteScreen(false);
                deleteDeck();
              }}
            >
              Yes
            </FooterButton>
            <FooterButtonRed onClick={() => setIsDeleteScreen(false)}>
              No
            </FooterButtonRed>
          </SpaceBetween>
        </CenterScreenMiddle>
        <FooterContainer>
          <FooterButtonRed onClick={() => setIsDeleteScreen(false)}>
            Back
          </FooterButtonRed>
        </FooterContainer>
      </Shade>
    </div>
  );
};
