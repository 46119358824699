import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BloodDropIcon from "../../../assets/card/blood_drop.png";
import { CostContainer } from "./CostStyle";
import { TextContainer } from "./TextStyle";

type CardCostType = {
  cost: number;
  size: number;
};

//

export const CardCost = (props: CardCostType) => {
  const { cost, size } = props;
  return (
    <CostContainer>
      <LazyLoadImage
        src={BloodDropIcon}
        alt={"blood_icon"}
        height={size}
        width={size}
        effect="blur"
      />
      <TextContainer>{cost}</TextContainer>
    </CostContainer>
  );
};
