import { EloRankngType } from "../../Types/EloRanking";
import { SERVER_URL } from "../../constants";

export const getRank = async (username: string): Promise< EloRankngType | undefined> => {
    const res = await fetch(
      `https://${SERVER_URL}/elo?username=${username}`,
      {
        method: "GET",
      }
    );
    if (res.ok) {
      return await res.json();
    }
    return;
  };

