import React, { useState, useCallback, useRef } from 'react';
import './UserCardCatalog.css';
import { Card } from '../../Components/Card/Card';
import { MarketCardInterface } from '../../Types/CardInterface';
import { PriceContainer } from '../../Components/styled/MarketPriceStyle';
import {
    CatalogContainer,
    CatalogSubContainer,
    MarketCard,
} from '../../Components/styled/Catalog';
import { useClickOutside } from '../../useClickOutside';

import { ReactComponent as Star } from '../../../assets/icons/star.svg';
import {
    IconContainer,
    PrestigePointsContainerCatalog,
} from '../../Components/styled/PrestigePointsContainer';

interface UserCardCatalogProps {
    detailCardId: string;
    setDetailCardId: React.Dispatch<React.SetStateAction<string>>;
    allCards: MarketCardInterface[];
    buyCard: (card_id: string) => Promise<void>;
}

export const UserCardCatalog = (props: UserCardCatalogProps) => {
    const { allCards, buyCard } = props;
    const [selectedCard, setSelectedCard] = useState<number>();
    const ref = useRef(null);

    const selectCard = (card_index: number) => {
        setSelectedCard(card_index);
    };

    const onReset = useCallback(() => setSelectedCard(undefined), []);
    useClickOutside(ref, onReset);

    return (
        <CatalogContainer onClick={onReset}>
            <CatalogSubContainer>
                {allCards !== null &&
                    allCards.map((data: MarketCardInterface, index: number) => {
                        return (
                            <MarketCard key={index}>
                                <PrestigePointsContainerCatalog>
                                    <PriceContainer className="number_font">
                                        {data.Price}
                                    </PriceContainer>
                                    <IconContainer>
                                        <Star
                                            viewBox="-5 -6 30 30"
                                            width="100%"
                                            height="100%"
                                        ></Star>
                                    </IconContainer>
                                </PrestigePointsContainerCatalog>
                                <Card
                                    buyCard={buyCard}
                                    card={data.Card}
                                    index={index}
                                    marketOption="market"
                                    selectCard={selectCard}
                                    selectedCard={selectedCard}
                                />
                            </MarketCard>
                        );
                    })}
            </CatalogSubContainer>
        </CatalogContainer>
    );
};
