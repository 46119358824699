import React from "react";
import "./Lazy.css";
import { CardImageLoader } from "../ImageLoader";

type CardCostType = {
  image: string;
};

export const CardImage = (props: CardCostType) => {
  const { image } = props;
  return (
        <CardImageLoader imagePath={image} />
    
  );
};