import styled from 'styled-components';

export const InfoText = styled.div`
    margin-bottom: 1.2rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    border-style: solid;
    border-image: initial;
    border-color: rgb(210, 210, 210);
    border-width: 0.12rem;
    flex-basis: 70%;
    box-sizing: border-box;
    overflow: hidden;
`;

export const FlexContainer = styled.div<{ isTrue: boolean }>`
    display: flex;
    justify-content: start;
    padding: 0.5rem;
    background-color: ${({ isTrue }) => (isTrue ? '#10cf1042' : 'transparent')};
    & + & {
        border-top: 0.1rem solid rgba(255, 255, 255, 0.3);
    }
`;

export const Icon = styled.div`
    width: 1rem;
    margin: 0.5rem;
`;

export const Text = styled.span`
    padding-right: 0.2rem;
    text-align: start;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
`;
