import React, { useState } from "react";
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg";
import { MainDeckInterface } from "../../../Types/CardInterface";
import { IconButtonGreen } from "../../../Components/styled/IconButton";
import { Center } from "./NewDeckForm.styles";

type NewDeckFormProps = {
    deckNames: Array<string>;
    setDeckNames: React.Dispatch<React.SetStateAction<string[]>>;
    setDeckCards: React.Dispatch<React.SetStateAction<MainDeckInterface>>;
}

export const NewDeckForm = ({deckNames, setDeckNames, setDeckCards} : NewDeckFormProps) => {
    const [deckName, setDeckName] = useState("")

    const addEmptyDeck = () => {
        if (deckName === "") return;
        if (deckNames.includes(deckName)) return;
        setDeckNames([...deckNames, deckName]);
        setDeckCards({ Cards: [], DeckName: deckName });
      };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        addEmptyDeck()
        setDeckName("")
        form.reset();
      }

  return (
    <form onSubmit={handleSubmit}>
      <input
        className="current_deck center_input_text"
        type="text"
        name="new_deck"
        value={deckName}
        onChange={(element: React.ChangeEvent<HTMLInputElement>) => setDeckName(element.target.value)}
      />
      <div className="h-20"></div>
      {deckName !== "" &&
      <Center>
        <IconButtonGreen className="done_deck white_text pointer" type="submit">
            <Plus viewBox="-180 5 425 425" width="100%" height="100%" />
        </IconButtonGreen>
      </Center>
      }
    </form>

  );
};
