import React from 'react';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { ReactComponent as False } from '../../../assets/icons/x.svg';
import { FlexContainer, Icon, Text } from './PasswordInfoCheck.style';

type Props = {
    isTrue: boolean;
    text: string;
};

export const PasswordInfoCheck = ({ isTrue, text }: Props) => {
    const IconDisplay = isTrue ? (
        <Check viewBox="0 -5 20 20" width="100%" height="100%" />
    ) : (
        <False viewBox="0 0 30 30" width="100%" height="100%" />
    );
    return (
        <FlexContainer isTrue={isTrue}>
            <Icon>{IconDisplay}</Icon>
            <Text>{text}</Text>
        </FlexContainer>
    );
};
