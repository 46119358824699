import styled from 'styled-components';

export const Text = styled.p`
    text-align: center;
    color: var(--text-color);
    text-shadow: rgb(20, 20, 20) 2px 2px;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    margin-bottom: 1.8rem;
`;
