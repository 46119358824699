export const cta = {
    xx_light: 'hsl(227, 100%, 96%)',
    x_light: 'hsl(227, 100%, 85%)',
    light: 'hsl(227, 100%, 71%)',
    main: 'hsl(227, 100%, 64%)',
    dark: 'hsl(227, 55%, 39%)',
} as const;

export const primary = {
    light: 'hsl(227, 25%, 50%)',
    main: 'hsl(227, 25%, 29%)',
    dark: 'hsl(227, 25%, 17%)',
} as const;

export const secondary = {
    light: 'hsl(227, 45%, 98%)',
    main: 'hsl(227, 33%, 87%)',
    dark: 'hsl(227, 33%, 79%)',
} as const;

export const interaction = {
    100: 'hsl(227, 25%, 29%)',
    90: 'hsl(227, 25%, 40%)',
    80: 'hsl(227, 25%, 50%)',
    70: 'hsl(227, 33%, 58%)',
    60: 'hsl(227, 33%, 64%)',
    50: 'hsl(227, 33%, 70%)',
    40: 'hsl(227, 33%, 79%)',
    30: 'hsl(227, 33%, 87%)',
    25: 'hsl(227, 33%, 92%)',
    20: 'hsl(227, 45%, 96%)',
    10: 'hsl(227, 45%, 98%)',
} as const;

export const content = {
    100: 'hsl(227, 34%, 23%)',
    90: 'hsl(227, 23%, 30%)',
    80: 'hsl(227, 16%, 38%)',
    70: 'hsl(227, 12%, 46%)',
    60: 'hsl(227, 10%, 54%)',
    50: 'hsl(227, 10%, 61%)',
    40: 'hsl(227, 10%, 69%)',
    30: 'hsl(227, 10%, 77%)',
    20: 'hsl(227, 10%, 85%)',
    10: 'hsl(227, 10%, 92%)',
} as const;

export const black = {
    100: 'rgba(0, 0, 0, 1)',
    90: 'rgba(0, 0, 0, 0.9)',
    80: 'rgba(0, 0, 0, 0.8)',
    70: 'rgba(0, 0, 0, 0.7)',
    60: 'rgba(0, 0, 0, 0.6)',
    50: 'rgba(0, 0, 0, 0.5)',
    40: 'rgba(0, 0, 0, 0.4)',
    30: 'rgba(0, 0, 0, 0.3)',
    20: 'rgba(0, 0, 0, 0.2)',
    10: 'rgba(0, 0, 0, 0.1)',
    5: 'rgba(0, 0, 0, 0.05)',
    0: 'rgba(0, 0, 0, 0)',
} as const;

export const grey = {
    98: 'rgb(250, 250, 250)',
    95: 'rgb(234, 236, 243)',
    90: 'rgb(224, 224, 224)',
    60: 'rgb(139, 139, 139)',
    50: 'rgb(121, 121, 121)',
    30: 'rgb(73, 73, 73)',
    20: 'rgb(51, 51, 51)',
} as const;

export const white = {
    100: 'rgba(255, 255, 255, 1)',
    90: 'rgba(255, 255, 255, 0.9)',
    80: 'rgba(255, 255, 255, 0.8)',
    70: 'rgba(255, 255, 255, 0.7)',
    60: 'rgba(255, 255, 255, 0.6)',
    50: 'rgba(255, 255, 255, 0.5)',
    40: 'rgba(255, 255, 255, 0.4)',
    30: 'rgba(255, 255, 255, 0.3)',
    20: 'rgba(255, 255, 255, 0.2)',
    10: 'rgba(255, 255, 255, 0.1)',
    0: 'rgba(255, 255,255, 0)',
} as const;

export const utility = {
    success: {
        light: 'hsl(120, 60%, 68%)',
        main: 'hsl(121, 38%, 49%)',
        dark: 'hsl(127, 74%, 29%)',
    },
    attention: {
        light: 'hsl(4, 100%, 79%)',
        main: 'hsl(1, 85%, 62%)',
        dark: 'hsl(353, 85%, 40%)',
    },
    highlight: {
        light: 'hsl(33, 100%, 79%)',
        main: 'hsl(35, 84%, 60%)',
        dark: 'hsl(37, 78%, 45%)',
    },
    extra: {
        light: 'hsl(183, 90%, 96%)',
        main: 'hsl(183, 50%, 54%)',
        dark: 'hsl(183, 84%, 32%)',
    },
} as const;
