import * as React from 'react';
import './UserCardCatalog.css';
import { CardInterfaceAmount } from '../../Types/CardInterface';
import {
    AmountContainer,
    CardCatalogContainer,
    CatalogSubContainer,
    CollectionContainer,
} from '../../Components/styled/Catalog';
import { Card } from '../../Components/Card/Card';
import { CardSelectedType } from '../../Types/CardIndex';

interface UserCardCatalogProps {
    addCardToDeck: (card: CardInterfaceAmount) => void;
    allCards: CardInterfaceAmount[];
    setSelectedCard: React.Dispatch<React.SetStateAction<CardSelectedType>>;
    selectedCard: CardSelectedType;
}

export const UserCardCatalog = (props: UserCardCatalogProps) => {
    const { allCards, addCardToDeck, selectedCard, setSelectedCard } = props;
    if (allCards.length === 0) return <></>;

    return (
        <CollectionContainer>
            <CatalogSubContainer>
                {allCards.map((data: CardInterfaceAmount, index: number) => {
                    return (
                        <CardCatalogContainer key={index}>
                            <AmountContainer className="number_font">
                                {data.Amount}
                            </AmountContainer>
                            <Card
                                key={index}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                                index={index}
                                cardOnClick={addCardToDeck}
                                card_amount={data}
                                cardInDeck={false}
                                option="collection"
                            />
                        </CardCatalogContainer>
                    );
                })}
            </CatalogSubContainer>
        </CollectionContainer>
    );
};
