
import { generateRandomString, getRandomCardType, getRandomInt, getRandomIntBetween } from '../factoryHelpers';
import { CardInterface, CardInterfaceAmount, MainDeckInterface } from './CardInterface';

export function createCard(cardData: Partial<CardInterface>): CardInterface {
  const defaultCard: CardInterface = {
    Attack: getRandomInt(),
    AttacksCounter: 0,
    Cost: getRandomInt(),
    Effect: '',
    Element: '',
    Description: '',
    ID: getRandomIntBetween(5).toString(),
    IsDead: false,
    IsShiny: '',
    IsVisible: false,
    IsWall: false,
    Life: getRandomInt(),
    CardName: generateRandomString(20),
    Price: getRandomInt(),
    Rank: getRandomIntBetween(5),
    RegeneratePoints: getRandomInt(),
    SellValue: 0,
    SkinType: '',
    CardTribe: '',
    CardType: getRandomCardType(),
    CardImage: 'https://images.unsplash.com/photo-1618614944895-fc409a83ad80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80',
  };

  return { ...defaultCard, ...cardData };
}

export function createCardAmount(cardAmountData: Partial<CardInterfaceAmount>): CardInterfaceAmount {
  const defaultCardAmount: CardInterfaceAmount = {
    Card: createCard({}),
    Amount: "3",
  };

  return { ...defaultCardAmount, ...cardAmountData };
}

export function createMainDeck(deckData: Partial<MainDeckInterface>): MainDeckInterface {
  const defaultMainDeck: MainDeckInterface = {
    Cards: createDefaultCardsAmount(),
    DeckName: generateRandomString(10),
  };

  return { ...defaultMainDeck, ...deckData };
}

function createDefaultCardsAmount(): CardInterfaceAmount[] {
  const defaultCards: CardInterfaceAmount[] = [];

  for (let i = 0; i < 40; i++) {
    defaultCards.push(createCardAmount({}));
  }

  return defaultCards;
}

export function createDefaultCards(): CardInterface[] {
  const defaultCards: CardInterface[] = [];

  for (let i = 0; i < 40; i++) {
    defaultCards.push(createCard({}));
  }

  return defaultCards;
}