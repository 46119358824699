import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CardContainer = styled.div<{ isWinner: boolean }>`
    padding: 1rem;
    color: var(--text-color);
    background-color: rgb(114 0 0 / 40%);
    background-color: ${({ isWinner }) =>
        isWinner ? rgba(0, 70, 10, 0.4) : rgba(114, 0, 0, 0.4)};
    border: solid 2px rgb(0, 0, 0);
    box-shadow: 2px 2px 5px 1px rgb(57 57 57);
    border-radius: 0.8rem;
    opacity: 0;
    animation: ${fadeIn} ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;

    & + & {
        margin-top: 1.5em;
    }
`;

export const CardPlayers = styled.div`
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
`;

export const CardPlayer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PlayerElo = styled.div`
    font-size: 10px;
    color: lightgray;
    margin: auto;
    margin-left: 5px;
`;

export const Player = styled.div``;
export const GameType = styled.div`
    font-size: 12px;
    margin-bottom: 5px;
`;
export const Date = styled.div`
    font-size: 10px;
    color: lightgray;
`;

export const Versus = styled.div`
    font-size: 11px;
    color: lightgray;
`;

export const GameResult = styled.div`
    font-size: 14px;
    text-align: center;
    font-weight: bold;
`;

export const MatchHistoryContainer = styled.div`
    display: block;
    height: 340px;
    overflow-y: scroll;
    margin-top: 20px;

    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide the scrollbar for other browsers */
    scrollbar-width: none;
    -ms-overflow-style: none;
`;
