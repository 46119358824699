import React from 'react';

type Props = {
    elo: number | JSX.Element;
    rank: number | JSX.Element;
};

export const EloRank = ({ elo, rank }: Props) => {
    return (
        <table className="elo_container">
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Elo</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="number_font">{rank}</td>
                    <td className="number_font">{elo}</td>
                </tr>
            </tbody>
        </table>
    );
};
