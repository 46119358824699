import * as React from "react";
import { useState } from "react";
import { MainDeckInterface } from "../../Types/CardInterface";
import {
  CenterScreenMiddle,
  ErrorMessage,
  FooterButton,
  FooterButtonRed,
  FooterContainer,
  NewDeckInput,
  Shade,
} from "../../Components/styled/SideBarStyle";

interface SideBarProps {
  deckNames: Array<string>;
  setDeckCards: React.Dispatch<React.SetStateAction<MainDeckInterface>>;
  setDeckNames: React.Dispatch<React.SetStateAction<string[]>>;
  setIsNewDeckScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewDeckResponsive = (props: SideBarProps) => {
  const { deckNames, setDeckCards, setDeckNames, setIsNewDeckScreen } = props;

  const [newDeck, setNewDeck] = useState("");

  const addEmptyDeck = () => {
    if (newDeck === "") return;
    if (deckNames.includes(newDeck)) return;
    setDeckNames([...deckNames, newDeck]);
    setNewDeck("");
    setDeckCards({ Cards: [], DeckName: newDeck });
  };

  const errorMessage = deckNames.includes(newDeck)
    ? "Deck name already used"
    : "Write your deck name";

  return (
    <div className="body background_library">
      <Shade>
        <CenterScreenMiddle>
          <NewDeckInput
            placeholder="New deck name"
            onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
              setNewDeck(element.target.value);
            }}
          />
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </CenterScreenMiddle>
        <FooterContainer>
          {newDeck !== "" && !deckNames.includes(newDeck) ? (
            <FooterButton
              onClick={() => {
                addEmptyDeck();
                setIsNewDeckScreen(false);
              }}
            >
              Save
            </FooterButton>
          ) : (
            <FooterButtonRed onClick={() => setIsNewDeckScreen(false)}>
              Back
            </FooterButtonRed>
          )}
        </FooterContainer>
      </Shade>
    </div>
  );
};
