import React, { useState } from 'react';
import { getCookie } from '../../getCookie';
import { Navigate, useNavigate } from 'react-router-dom';
import { route } from '../../constants';
import { BackgroundImage } from '../../BackgroundImage';
import DashboardBackground from '../../assets/dashboard.jpg';
import {
    BoxCenterRegisterLogin,
    ClassicButton,
    FormContainerCenter,
    FormInput,
    FormTitle,
} from '../../market/Components/styled';
import { ReactComponent as BackArrow } from '../../assets/icons/left_arrow.svg';
import { BackArrowContainer } from '../../components/styled/BoxCenterRegisterLogin';
import { sendForgotEmail } from './sendForgotEmail';
import { ErrorInfoBox, SuccessInfoBox } from '../../components/InfoBox';

export const ForogtPassword = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState('');
    const cookieValue = getCookie('bloodstor_user');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setMessage('');
        if (username === '') {
            return;
        }
        const msg = await sendForgotEmail(username);
        setUsername('');
        setMessage(msg);
    };

    if (cookieValue !== undefined && cookieValue !== '') {
        return <Navigate to={route.DASHBOARD} replace />;
    }

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <BoxCenterRegisterLogin onSubmit={handleSubmit}>
                <BackArrowContainer onClick={() => navigate(route.LOGIN)}>
                    <BackArrow
                        viewBox="0 0 30 30"
                        width="100%"
                        height="100%"
                    ></BackArrow>
                </BackArrowContainer>

                <FormTitle>Reset password</FormTitle>
                <FormContainerCenter>
                    <FormInput
                        name="Username"
                        type="text"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="E-Mail or Username"
                    />

                    <ClassicButton
                        className="navbar__item"
                        type="submit"
                        value="Submit"
                    >
                        Submit
                    </ClassicButton>
                </FormContainerCenter>
            </BoxCenterRegisterLogin>
            {message !== '' && message !== 'Please check your email' && (
                <ErrorInfoBox>{message}</ErrorInfoBox>
            )}
            {message !== '' && message === 'Please check your email' && (
                <SuccessInfoBox>{message}</SuccessInfoBox>
            )}
        </div>
    );
};
