import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
//import { CardImageContainer } from "./CardStyle";
import "./Lazy.css";

type CardCostType = {
  image: string;
};

export const CardImage = (props: CardCostType) => {
  const { image } = props;
  return (
    <LazyLoadImage
      src={image}
      alt={"image"}
      className={"lazy-load-image lazy-load-image-wrapper"}
      effect="blur"
    />
  );
};
