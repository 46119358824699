import styled from 'styled-components';
import { CARD_SYMBOLS_OFFSET } from '../../constants';

export const PlusContainer = styled.div`
    position: absolute;
    top: ${CARD_SYMBOLS_OFFSET};
    right: ${CARD_SYMBOLS_OFFSET};
    display: flex;
    z-index: 2;
`;
