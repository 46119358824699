import React, { useState } from 'react';
import { CardsAmountResponsive } from './styled/SideBarStyle';
import { cardsInDeck } from '../cardsInDeck';
import { MainDeckInterface } from '../Types/CardInterface';

interface CardsInDeckProps {
    deckCards: MainDeckInterface;
    hasMargin: boolean;
}

export const CardsInDeck = (props: CardsInDeckProps) => {
    const { deckCards, hasMargin } = props;
    const [isAnimating, setIsAnimating] = useState<boolean>(false);
    const [cardsInDeckState, setCardsInDeck] = useState<number>(
        cardsInDeck(deckCards),
    );
    const id_string = 'card_amount';

    const handleCountChangeAdd = (newCount: number) => {
        if (isAnimating) {
            return;
        }

        setIsAnimating(true);

        const container = document.getElementById(id_string);
        if (container) {
            container.classList.add('fade-out-add');
            container.addEventListener(
                'animationend',
                () => {
                    container.classList.remove('fade-out-add');
                    setCardsInDeck(newCount);
                    container.classList.add('fade-in-add');
                    container.addEventListener(
                        'animationend',
                        () => {
                            container.classList.remove('fade-in-add');
                            setIsAnimating(false);
                        },
                        { once: true },
                    );
                },
                { once: true },
            );
        }
    };

    const handleCountChangeSubtract = (newCount: number) => {
        if (isAnimating) {
            return;
        }

        setIsAnimating(true);

        const container = document.getElementById(id_string);
        if (container) {
            container.classList.add('fade-out-subtract');
            container.addEventListener(
                'animationend',
                () => {
                    container.classList.remove('fade-out-subtract');
                    setCardsInDeck(newCount);
                    container.classList.add('fade-in-subtract');
                    container.addEventListener(
                        'animationend',
                        () => {
                            container.classList.remove('fade-in-subtract');
                            setIsAnimating(false);
                        },
                        { once: true },
                    );
                },
                { once: true },
            );
        }
    };

    if (cardsInDeckState < cardsInDeck(deckCards)) {
        handleCountChangeAdd(cardsInDeck(deckCards));
    }
    if (cardsInDeckState > cardsInDeck(deckCards)) {
        handleCountChangeSubtract(cardsInDeck(deckCards));
    }

    return (
        <CardsAmountResponsive
            className="number_font"
            optionalMargin={hasMargin}
            id={id_string}
        >
            {cardsInDeckState}
        </CardsAmountResponsive>
    );
};
