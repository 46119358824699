import React, { useState } from "react";
import { DropdownContainer, SelectContainer } from "./styled/DropdownStyle";
import { SearchCardForm } from "../Types/SearchCardForm";
import { CardType } from "../Types/CardType";

interface DropdownProps {
  options: string[];
  defaultSelectedValue: string;
  form: SearchCardForm;
  setForm: React.Dispatch<React.SetStateAction<SearchCardForm>>;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  defaultSelectedValue,
  form,
  setForm
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value as keyof CardType) {
      case "Normal":
        setForm({...form, 
          Normal:true,
          Ordinary: false,
          Rare: false,
          Heroic: false,
          Epic: false,
          Legendary: false
        })
        break
      case "Ordinary":
        setForm({...form, 
          Normal:false,
          Ordinary: true,
          Rare: false,
          Heroic: false,
          Epic: false,
          Legendary: false
        })
        break
      case "Rare":
        setForm({...form, 
          Normal:false,
          Ordinary: false,
          Rare: true,
          Heroic: false,
          Epic: false,
          Legendary: false
        })
        break
      case "Heroic":
        setForm({...form, 
          Normal:false,
          Ordinary: false,
          Rare: false,
          Heroic: true,
          Epic: false,
          Legendary: false
        })
        break
      case "Epic":
        setForm({...form, 
          Normal:false,
          Ordinary: false,
          Rare: false,
          Heroic: false,
          Epic: true,
          Legendary: false
        })
        break
      case "Legendary":
        setForm({...form, 
          Normal:false,
          Ordinary: false,
          Rare: false,
          Heroic: false,
          Epic: false,
          Legendary: true
        })
        break
    
      default:
        setForm({...form, 
          Normal:false,
          Ordinary: false,
          Rare: false,
          Heroic: false,
          Epic: false,
          Legendary: false
        })
        break;
    }
    setSelectedValue(e.target.value);
  };

  return (
    <DropdownContainer>
      <SelectContainer
        value={selectedValue}
        onChange={handleChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SelectContainer>
    </DropdownContainer>
  );
};
