import styled from 'styled-components';

export const Text = styled.div`
    box-sizing: border-box;
    flex-basis: 75%;
    color: var(--text-color);
    text-shadow: rgb(20, 20, 20) 2px 2px;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    border: 2px solid rgb(161, 161, 161);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
`;

export const CheckboxLabel = styled.div`
    flex-basis: 75%;
    margin-bottom: 1rem;
    label {
        display: flex;
    }
`;

export const LabelContainer = styled.div`
    color: var(--text-color);
    text-shadow: rgb(20, 20, 20) 2px 2px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;

    a {
        color: var(--text-color);
        text-shadow: rgb(20, 20, 20) 2px 2px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
        margin-left: 0.4rem;
    }
`;
