import React from 'react';
import { FidgetSpinner } from 'react-loader-spinner';

type FidgetSpinnerLoaderType = {
    size: number;
};

export const FidgetSpinnerLoader = (props: FidgetSpinnerLoaderType) => {
    const { size } = props;
    return (
        <FidgetSpinner
            visible={true}
            height={size.toString()}
            width={size.toString()}
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={['#bbbbbb', '#bbbbbb', '#bbbbbb']}
            backgroundColor="#bbbbbb"
        />
    );
};
