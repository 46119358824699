import React, { useCallback, useState } from 'react';
import Popup from 'reactjs-popup';
import { currency } from '../constants';
import { ButtonWrapper } from './ButtonWrapper';
import {
    IconContainer,
    IconSvgConatiner,
    IconSvgConatinerShop,
    IconTitle,
    PaypalPopup,
    PaypalPopupTitle,
} from '../components/styled/IconHeaderStyle';
import {
    ModalPopup,
    ModalPopupCenter,
} from '../../components/styled/ModalPopup';

interface ShopButtonProps {
    title: string;
    price: string;
    image: string;
    getUserFromToken: () => Promise<void>;

    icon?: JSX.Element;
}
export const ShopButton = (props: ShopButtonProps) => {
    const { title, price, image, getUserFromToken, icon } = props;
    const [isPopupOen, setIsPopupOpen] = useState(false);

    const IconHeader = () => {
        return (
            <IconContainer>
                <IconTitle className="number_font">{title}</IconTitle>
                <IconSvgConatinerShop>{icon}</IconSvgConatinerShop>
            </IconContainer>
        );
    };

    const openPopup = useCallback(() => {
        setIsPopupOpen(true);
    }, []);

    const closePopup = useCallback(() => {
        setIsPopupOpen(false);
    }, []);
    const Header = () => {
        if (icon === undefined) {
            return <div className="shop_title number_font">{title}</div>;
        }

        return <IconHeader />;
    };
    return (
        <>
            <div className="shop_button" onClick={openPopup}>
                <Header />
                <img src={image} alt="yolo" />
                <div className="shop_price number_font">{price} $</div>
            </div>
            <Popup modal open={isPopupOen} closeOnDocumentClick={false}>
                <ModalPopup onClick={closePopup}>
                    <ModalPopupCenter>
                        <PaypalPopup
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <PaypalPopupTitle>
                                <Header />
                            </PaypalPopupTitle>
                            <ButtonWrapper
                                currency={currency}
                                showSpinner={false}
                                price={price}
                                title={title}
                                getUserFromToken={getUserFromToken}
                            />
                        </PaypalPopup>
                    </ModalPopupCenter>
                </ModalPopup>
            </Popup>
        </>
    );
};
