import React, { useState } from 'react';
import { getCookie } from '../getCookie';
import { Navigate, useNavigate } from 'react-router-dom';
import { SERVER_URL, route } from '../constants';
import { BackgroundImage } from '../BackgroundImage';
import DashboardBackground from '../assets/dashboard.jpg';
import {
    BoxCenterRegisterLogin,
    ClassicButton,
    FormContainerCenter,
    FormErrorMessage,
    FormInput,
    FormTitle,
} from '../market/Components/styled';
import {
    DeleteText,
    EmailInfoText,
} from '../market/Components/styled/FormInput';
import { ReactComponent as BackArrow } from '../assets/icons/left_arrow.svg';
import { BackArrowContainer } from '../components/styled/BoxCenterRegisterLogin';
import { isLocalhost } from '../factoryHelpers';

type LoginResponse = {
    message: string;
};

export const DeleteUser = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const cookieValue = getCookie('bloodstor_user');

    const deleteUser = async () => {
        const res = await fetch(`https://${SERVER_URL}/delete`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                Token: cookieValue,
                Password: password,
            }),
        });
        if (res.ok) {
            setSuccessMessage('Account deleted');
        } else {
            const data: LoginResponse = await res.json();
            if (data.message !== 'success') {
                setErrorMessage(data.message);
            }
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setPassword('');
        deleteUser();
    };

    if (cookieValue === undefined || cookieValue === '') {
        if (!isLocalhost()) {
            return <Navigate to={route.LOGIN} replace />;
        }
    }

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <BoxCenterRegisterLogin onSubmit={handleSubmit}>
                <BackArrowContainer onClick={() => navigate(route.USER)}>
                    <BackArrow
                        viewBox="0 0 30 30"
                        width="100%"
                        height="100%"
                    ></BackArrow>
                </BackArrowContainer>

                {successMessage === '' ? (
                    <>
                        <FormTitle>Delete account</FormTitle>
                        <FormContainerCenter>
                            <FormInput
                                name="Password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                placeholder="Password"
                            />
                            <DeleteText>
                                If you delete your account, you will be banned
                                for life.
                            </DeleteText>

                            <ClassicButton
                                className="navbar__item"
                                type="submit"
                                value="Submit"
                            >
                                Submit
                            </ClassicButton>
                        </FormContainerCenter>
                    </>
                ) : (
                    <EmailInfoText>{successMessage}</EmailInfoText>
                )}
            </BoxCenterRegisterLogin>
            {errorMessage !== '' && (
                <FormErrorMessage>{errorMessage}</FormErrorMessage>
            )}
        </div>
    );
};
