import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

type Component = number;
type Color = `rgb(${Component},${Component},${Component})`;

const fadeInAdd = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutAdd = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const fadeInSubtract = keyframes`
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutSubtract = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

export const SideBarContainer = styled.div`
    background-color: black;
    width: 260px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    box-shadow: 5px 0 20px 5px rgb(229, 229, 229);
    z-index: 5;
`;

export const SideBarResponsiveContainer = styled.div`
    position: sticky;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    padding: 10px 0px;
    justify-content: space-evenly;
`;

export const Header = styled.h3`
    font-size: 1.3rem;
    color: white;
    text-align: center;
    letter-spacing: 0.1rem;
`;

export const CheckboxContainer = styled.div`
    margin-top: 8px;
    display: flex;
    justify-content: start;
    align-items: center;
`;

export const CheckboxLabel = styled.label`
    font-size: 1.3rem;
    color: white;
    cursor: pointer;
`;

export const FilterContainer = styled.div`
    width: fit-content;
    margin: auto;
    margin-top: 15px;
`;

const checkboxCheckedAnimation = keyframes`
  0% {
    opacity:0;
  }
  
  100% {
    opacity:1;
  }
`;

export const Checkbox = styled.input`
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #aaa;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.2s ease-in-out;
    position: relative;
    margin-right: 14px;
    cursor: pointer;

    &:checked {
        border-color: #3e9035;
        background-color: #000;
    }

    &:checked::after {
        content: '';
        position: absolute;
        top: 2px;
        left: 5px;
        width: 4px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        animation: ${checkboxCheckedAnimation} 0.2s ease-in-out forwards;
    }
`;

export const SearchInputResponsive = styled.input`
    min-height: 20px;
    width: 100px;
    text-align: center;
    block-size: fit-content;
    border: solid 2px darkgray;
    padding: 5px;
    border-radius: 5px;
    background-color: lightgray;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const SearchInput = styled.input`
    min-height: 20px;
    width: 180px;
    text-align: center;
    border: solid 2px darkgray;
    padding: 5px;
    border-radius: 5px;
    background-color: lightgray;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CenterFlexContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const NewDeckInput = styled.input`
    min-height: 20px;
    width: 80%;
    max-width: 280px;
    text-align: center;
    border: solid 2px darkgray;
    padding: 5px;
    border-radius: 5px;
    background-color: lightgray;
    display: block;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 10px auto;
`;

export const Shade = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const CenterScreenMiddle = styled.div`
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0%, -50%);
`;

export const ErrorMessage = styled.div`
    color: lightgray;
    text-shadow: 2px 2px rgb(124, 0, 0);
    text-align: center;
    font-weight: 500;
`;

/*
export const SideBarButton = styled.button`
  background-color: rgba(23, 207, 23, 0.7);
  block-size: fit-content;
  border: 2px solid #d3d3d3;
  border-radius: 5px;
  min-height: 20px;
  overflow: hidden;
  padding: 5px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #d3d3d3;
  text-shadow: 1px 1px #141414;
  cursor: pointer;
  font-size: 16px;
  width: 190px;
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 0px 35px;
  &:hover {
    background-color: rgba(23, 207, 23, 0.9);
    color: #ffffff;
    transition: all 0.1s ease;
  }
`;
*/

export const FooterContainer = styled.div`
    position: absolute;
    z-index: 5;
    background-color: rgb(0, 0, 0);
    display: flex;
    padding: 10px 0px;
    justify-content: space-around;
    bottom: 0px;
    width: 100%;
`;

export const CardsAmountResponsive = styled.div<{ optionalMargin: boolean }>`
    color: white;
    width: 30px;
    text-shadow: 1px 1px rgb(20, 20, 20);
    animation-duration: 0.3s;
    animation-timing-function: ease;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => (props.optionalMargin ? 'auto' : 'unset')};

    &.fade-out-add {
        opacity: 0;
        animation-name: ${fadeOutAdd};
        animation-fill-mode: forwards;
    }

    &.fade-in-add {
        opacity: 1;
        animation-name: ${fadeInAdd};
        animation-fill-mode: forwards;
    }
    &.fade-out-subtract {
        opacity: 0;
        animation-name: ${fadeOutSubtract};
        animation-fill-mode: forwards;
    }

    &.fade-in-subtract {
        opacity: 1;
        animation-name: ${fadeInSubtract};
        animation-fill-mode: forwards;
    }
`;

export const FooterButton = styled.button`
    text-align: center;
    border: solid 1px lightgray;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(23, 207, 23, 0.7);
    color: lightgray;
    text-shadow: 1px 1px rgb(20, 20, 20);
    width: 50px;
    height: 30px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
        background-color: rgba(23, 207, 23, 0.9);
    }
`;

export const FooterButtonRed = styled.button`
    text-align: center;
    border: solid 1px lightgray;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(207, 23, 23, 0.7);
    color: lightgray;
    text-shadow: 1px 1px rgb(20, 20, 20);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
        background-color: rgba(225, 8, 8, 0.9);
    }
`;

export const SpaceBetween = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 40px;
`;

interface HeightProps {
    height: string;
}

export const HeightComponent = styled.div<HeightProps>`
    height: ${(props) => props.height};
`;
