import { useEffect } from 'react';
import {
    CardInterfaceAmount,
    MarketCardInterface,
} from '../Types/CardInterface';
import { filterCards, filterCardsAdd } from '../filterCards';
import { MarketFormType } from '../Types/MarketFormInterface';

export function useFilteredAndSortedCardsAdd(
    cards: CardInterfaceAmount[],
    sortedCards: CardInterfaceAmount[],
    form: MarketFormType,
    setAllCards: React.Dispatch<React.SetStateAction<CardInterfaceAmount[]>>,
) {
    useEffect(() => {
        if (form.Search === '') {
            setAllCards(sortedCards);
        } else {
            const filteredCards = filterCardsAdd(sortedCards, form.Search); // Implement filterCards function
            setAllCards(filteredCards);
        }
    }, [form]);
}
