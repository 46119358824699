import React from "react";
import { CardInterface} from "../../Types/CardInterface";
import {
  CardStats,
  CardStyle,
  CrackingMask,
} from "./CardStyle";

import Popup from "reactjs-popup";
import cracksImage from "../../../assets/card/cracks.png";
import { useState } from "react";
import { CardCost } from "./Cost";
import { Name } from "./Name";
import { Plus } from "./Plus";
import { Attack } from "./Attack";
import { Life } from "./Life";
import { CardDetail } from "./CardDetail";
import "./CardPopup.css";
import { CardImageLoader } from "../ImageLoader";
import { Gem } from "./Gem";
import { CARD_HOVER_EFFEKT, ICON_SIZE } from "../../../constants";

type Props = {
  card: CardInterface;
  setOpenCard: React.Dispatch<React.SetStateAction<boolean[]>>
  openCard: boolean[];
  setBlock: React.Dispatch<React.SetStateAction<boolean>>
  block: boolean;
  index: number;
};

type CardType = {
  card: CardInterface;
};
const MonsterHand = (props: CardType) => {
  const { card } = props;
  const size = ICON_SIZE;
  return (
    <>
      <CardImageLoader imagePath={card.CardImage} />
      <Gem rareLvl={card.Rank} />
      <CardCost size={size} cost={card.Cost} />
      <Name name={card.CardName} isSpell={false} />
      <Plus size={size} plus={card.RegeneratePoints} />
      <Attack size={size} attack={card.Attack} />
      <Life size={size} life={card.Life} />
    </>
  );
};

const SpellCard = (props: CardType) => {
  const { card } = props;
  const size = ICON_SIZE;
  return (
    <>
      <CardImageLoader imagePath={card.CardImage} />
      <Gem rareLvl={card.Rank} />
      <CardCost size={size} cost={card.Cost} />
      <Name name={card.CardName} isSpell={true} />
    </>
  );
};

export const Card = ({card, block, setBlock, openCard, setOpenCard, index }: Props) => {
  const CardData = card.CardType === "Spell" ? SpellCard : MonsterHand;
  const [rotation, setRotation] = useState("rotateX(0deg) rotateY(0deg)");
  const [isHovered, setIsHovered] = useState(false);

  function sleep(seconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, seconds * 1000);
    });
  }

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if(openCard[index]){
      return
    }
    if(window.innerWidth > CARD_HOVER_EFFEKT){
      const card = event.currentTarget;
      const cardRect = card.getBoundingClientRect();
      const cardCenterX = cardRect.left + cardRect.width / 2;
      const cardCenterY = cardRect.top + cardRect.height / 2;
      const mouseX = event.clientX - cardCenterX;
      const mouseY = event.clientY - cardCenterY;
      const rotateX = -mouseY / 5;
      const rotateY = mouseX / 5;
      const transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
      setRotation(transform);
    }
  };

  const handleMouseLeave = () => {
    if(openCard[index]){
      return
    }
    if(window.innerWidth > CARD_HOVER_EFFEKT){
      setRotation("");
      setIsHovered(false);
    }
  };
  const handleMouseEnter = () => {
    if(openCard[index]){
      return
    }
    if(window.innerWidth > CARD_HOVER_EFFEKT){
      setIsHovered(true);
    }
  };

  const handleClick = (index: number) => {
    if (openCard.every(value => value === false) && !block){
      const updatedOpenCard = [...openCard]; // Create a copy of the array
      updatedOpenCard[index] = true; // Update the specific index to true
      setOpenCard(updatedOpenCard); // Update the state with the modified array
    }
  };

  const handleClose = (index: number) => {
    if (openCard[index] === true){
      const updatedOpenCard = [...openCard]; // Create a copy of the array
      updatedOpenCard[index] = false; // Update the specific index to true
      setBlock(true)
      setOpenCard(updatedOpenCard); // Update the state with the modified array
      sleep(0.3).then(() => setBlock(false))
    }
  };

  return (
    <>
    <CardStyle
          onClick={() => handleClick(index)}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
          isHovered={isHovered}
          rotation={rotation}
          className="card-content"
          isSpellCard={card.CardType === "Spell"}
        >
          <CrackingMask
            style={{
              backgroundImage: `url(${cracksImage})`,
            }}
          />
          <CardStats>
            <CardData card={card} />
          </CardStats>
        </CardStyle>
        <Popup
        modal
        open={!block && openCard[index]}
        onClose={() => handleClose(index)}
      >
            <CardDetail card={card} />
      </Popup>
    </>

  );
};