import React from 'react';
import { CardInterface } from '../../Types/CardInterface';
import cracksImage from '../../../assets/card/cracks.png';
import { CardStats } from './CardStyle';
import { CardCost } from './Cost';
import { CardImage } from './Image';
import { Name } from './Name';
import { Plus } from './Plus';
import { Attack } from './Attack';
import { Life } from './Life';
import {
    CardDetailContainer,
    CardDetailDescription,
    CardDetailName,
    NameDetailContainer,
} from './CardDetailStyle';
import { CrackingMask } from './CardStyle';
import { Gem } from './Gem';

type CardCostType = {
    card: CardInterface;
};

export const CardDetail = (props: CardCostType) => {
    const { card } = props;

    type CardType = {
        card: CardInterface;
    };

    const MonsterCardOnHand = (props: CardType) => {
        const { card } = props;
        const size = 30;
        return (
            <>
                <CardImage image={card.CardImage} />
                <Gem rareLvl={card.Rank} isDetail={true} />
                <CardCost size={size} cost={card.Cost} />
                <Name name={card.CardName} isSpell={false} />
                <Plus size={size} plus={card.RegeneratePoints} />
                <Attack size={size} attack={card.Attack} />
                <Life size={size} life={card.Life} />
            </>
        );
    };

    const SpellCard = (props: CardType) => {
        const { card } = props;
        const size = 30;
        return (
            <>
                <CardImage image={card.CardImage} />
                <Gem rareLvl={card.Rank} isDetail={true} />
                <CardCost size={size} cost={card.Cost} />
                <NameDetailContainer isSpellCard={card.CardType === 'Spell'}>
                    <CardDetailName>{card.CardName}</CardDetailName>
                    <CardDetailDescription>{card.Effect}</CardDetailDescription>
                </NameDetailContainer>
            </>
        );
    };
    const CardData = card.CardType === 'Spell' ? SpellCard : MonsterCardOnHand;
    return (
        <CardDetailContainer
            className="card-content fade-in"
            isSpellCard={card.CardType === 'Spell'}
        >
            <CrackingMask
                className="cracks-layer"
                style={{
                    backgroundImage: `url(${cracksImage})`,
                }}
            />
            <CardStats>
                <CardData card={card} />
            </CardStats>
        </CardDetailContainer>
    );
};
