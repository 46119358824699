import styled, { keyframes } from "styled-components";

const rotateOut = keyframes`
  from {
  transform: rotateY(0deg);
  }

  to {
    transform: rotateY(90deg);
  }
`;

export const OpponentCardsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  border: solid;
  border-color: #fff;
  border-width: 2px;
  color: #000;
  display: flex;
  height: 80px;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: left;
  top: 0;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 2;
  padding-bottom: 2px;
`;

export const BackCard = styled.div`
    background-color: rgb(96, 91, 61);
    border-radius: 4px;
    border: 1px solid black;
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 135px;
    height: 180px;
    margin: auto;

    cursor: pointer;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    transition: box-shadow 0.3s ease-out;

  animation: fadeIn 0.5s ease-in forwards; /* added animation property */

  @keyframes fadeIn {
    from {
      transform: rotateY(90deg) translateX(-60px);
      opacity: 0;
    }
    to {
      transform: rotateY(0deg) translateX(0px);
      opacity: 1;
    }
  }

  @media (max-width: 400px) {
    min-width: unset;
    width: 83px;
    height: 128px;
  }

  &:hover {
    box-shadow: rgb(0, 0, 0) 0px 0px 20px 14px;
  }

  &.rotate-out {
    animation-name: ${rotateOut};
    animation-fill-mode: forwards;
  }
`;

export const OpponentCardInnerColor = styled.div`
  background-color: rgb(58, 56, 43);
  border-radius: 4px;
  position: absolute;
  top: 5%;
  left: 5%;
  bottom: 5%;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerCircle = styled.div`
  background-color: #242319;
  border: solid;
  border-color: #000;
  border-radius: 50%;
  border-width: 1px;
  position: relative;
  height: 75%;
  width: 45%;
`;
