import React from 'react';
import { Container } from './TermsOfUse.styles';

export const TermsOfUse = () => {
    return (
        <Container>
            <h1>Terms of Use</h1>

            <h2>1. Acceptance of Terms</h2>
            <p>
                Welcome to <b>Asthell.com</b>! By accessing and using this game,
                you agree to be bound by the following Terms of Use. If you do
                not agree with these terms, please do not use <b>Asthell.com</b>
                .
            </p>

            <h2>2. Intellectual Property</h2>
            <p>
                All intellectual property rights in the game, including but not
                limited to copyrights, trademarks, and any other proprietary
                rights, belong to us or our licensors. You may not use,
                reproduce, or distribute any content from the game without our
                prior written consent.
            </p>

            <h2>3. User Conduct</h2>
            <p>
                When using <b>Asthell.com</b>, you agree to abide by the
                following guidelines:
                <ul>
                    <li>
                        Do not engage in any activity that violates applicable
                        laws or regulations.
                    </li>
                    <li>Do not infringe upon the rights of others.</li>
                    <li>
                        Do not attempt to gain unauthorized access to the game
                        or its systems.
                    </li>
                    <li>
                        Do not disrupt or interfere with the functioning of the
                        game.
                    </li>
                </ul>
                We reserve the right to suspend or terminate your access to the
                game if you violate these guidelines.
            </p>

            <h2>4. Limitation of Liability</h2>
            <p>
                We strive to provide an enjoyable and error-free experience, but
                we do not guarantee the accuracy, completeness, or reliability
                of the game or its content. We are not liable for any damages or
                losses arising from your use of the game.
            </p>

            <h2>5. Data Protection</h2>
            <p>
                Please refer to our{' '}
                <a target="_blank" href="data_protection">
                    Data Protection Information
                </a>{' '}
                to understand how we collect, use, and protect your personal
                data.
            </p>

            <h2>6. Modifications to Terms of Use</h2>
            <p>
                We reserve the right to update or modify these Terms of Use at
                any time. Any changes will be posted on this page, and we
                encourage you to review the Terms of Use periodically to stay
                informed. By continuing to use the game after modifications are
                made, you agree to be bound by the revised terms.
            </p>

            <h2>7. Contact Us</h2>
            <p>
                If you have any questions, concerns, or feedback regarding these
                Terms of Use, please contact us at{' '}
                <a href="mailto:support@asthell.com">support@asthell.com</a>.
            </p>
        </Container>
    );
};
