import {
    CardInterface,
    CardInterfaceAmount,
    MarketCardInterface,
} from './Types/CardInterface';

export const sortByCardName = (cardArray: MarketCardInterface[]) => {
    return cardArray.sort((cardA, cardB) => {
        const cardAName = cardA.Card.CardName.toLowerCase();
        const cardBName = cardB.Card.CardName.toLowerCase();
        return cardAName.localeCompare(cardBName);
    });
};

export const sortByCardNameAdd = (cardArray: CardInterfaceAmount[]) => {
    return cardArray.sort((cardA, cardB) => {
        const cardAName = cardA.Card.CardName.toLowerCase();
        const cardBName = cardB.Card.CardName.toLowerCase();
        return cardAName.localeCompare(cardBName);
    });
};
