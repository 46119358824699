import React, { useCallback } from 'react';
import { CardInterface } from '../Types/CardInterface';
import {
    AddCardButtonContainer,
    AddCardContainer,
    BottomOfScreen,
    CenterOnScreen,
    IconContainer,
    SellInputResponsive,
} from './styled/AddCardResponsiveStyle';
import { CardContainer } from './Card/CardStyle';
import { CardPure } from './Card/CardPure';
import { AddMarketFormType } from '../Types/AddMarketForm';
import { MAX_MARKET_PRICE } from '../../constants';
import { ReactComponent as BackArrow } from './../assets/icons/left_arrow.svg';

type AddCardType = {
    card: CardInterface;
    setChoosenCard: React.Dispatch<
        React.SetStateAction<CardInterface | undefined>
    >;
    setAddMarketForm: React.Dispatch<React.SetStateAction<AddMarketFormType>>;
    addMarketForm: AddMarketFormType;
    addToMarket: () => Promise<void>;
};

export const AddCardResponsive = (props: AddCardType) => {
    const {
        card,
        setChoosenCard,
        setAddMarketForm,
        addMarketForm,
        addToMarket,
    } = props;

    const handleValue = useCallback(
        (element: React.ChangeEvent<HTMLInputElement>) => {
            const priceFloat = parseFloat(element.target.value);
            const price = isNaN(priceFloat) ? 1 : priceFloat;
            const minPrice = Math.min(price, MAX_MARKET_PRICE);
            console.log(minPrice);
            setAddMarketForm({
                ...addMarketForm,
                Price: minPrice.toString(),
            });
        },
        [addMarketForm],
    );
    return (
        <AddCardContainer>
            <SellInputResponsive
                className="current_deck center_input_text"
                type="text"
                id="new_deck"
                placeholder="Sell price"
                value={addMarketForm.Price}
                onChange={handleValue}
            />
            <CenterOnScreen>
                <CardContainer>
                    <CardPure card={card} />
                </CardContainer>
                <AddCardButtonContainer>
                    {card === undefined ||
                    addMarketForm.Price === '' ||
                    isNaN(Number(addMarketForm.Price)) ||
                    Number(addMarketForm.Price) < 0 ? (
                        <div className="white_text center_text">
                            Choose a card and set a price
                        </div>
                    ) : (
                        <div
                            className="done_deck white_text pointer"
                            onClick={() => {
                                addToMarket();
                            }}
                        >
                            Add
                        </div>
                    )}
                </AddCardButtonContainer>
            </CenterOnScreen>
            <BottomOfScreen>
                <div
                    onClick={() => setChoosenCard(undefined)}
                    className="done_deck white_text"
                >
                    <IconContainer>
                        <BackArrow width="100%" height="100%" />
                    </IconContainer>
                </div>
            </BottomOfScreen>
        </AddCardContainer>
    );
};
