import styled from 'styled-components';
import { SCREEN_WIDTH } from '../../constants';

export const FitContent = styled.div`
    width: fit-content;
    margin: 2rem auto;
    height: 3.5rem;

    @media (max-width: ${SCREEN_WIDTH}) {
        margin-bottom: 0.5rem;
    }
`;
