import React, { useCallback, useState } from 'react';
import { BackgroundImage } from '../../BackgroundImage';
import DashboardBackground from '../../assets/dashboard.jpg';
import {
    BoxCenterRegisterLogin,
    ClassicButton,
    FormContainerCenter,
    FormInput,
    FormTitle,
} from '../../market/Components/styled';
import { resetPasswordPost } from './resetPasswordPost';
import { ErrorInfoBox, SuccessInfoBox } from '../../components/InfoBox';
import { InvalidToken } from './InvalidToken';
import { AllowKeys, PasswordState } from '../../Types/Password';
import { PasswordBox } from '../../components';
import { InfoText } from '../../components/PasswordInfoCheck/PasswordInfoCheck.style';
import { PasswordInfoCheck } from '../../components/PasswordInfoCheck';

export const ResetPassword = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const [password, setPassword] = useState('');
    const [passwordNew, setPasswordNew] = useState('');
    const [message, setMessage] = useState<string>('');
    const [isOpenPassword, setIsOpenPassword] = useState(false);
    const [isOpenPasswordNew, setIsOpenPasswordNew] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState<PasswordState>({
        length: false,
        specialCharacter: false,
        match: false,
        capital: false,
        small: false,
        number: false,
    });

    const hasSpecialCharacter = useCallback(
        (input: string) => {
            const specialCharacterRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;
            return specialCharacterRegex.test(input);
        },
        [password],
    );

    const isSmallLetter = useCallback(
        (input: string) => {
            const smallLetterRegex = /[a-z]/;
            return smallLetterRegex.test(input);
        },
        [password],
    );

    const containsNumber = useCallback(
        (input: string) => {
            const numberRegex = /\d/;
            return numberRegex.test(input);
        },
        [password],
    );

    const isCapitalLetter = useCallback(
        (input: string) => {
            const smallLetterRegex = /[A-Z]/;
            return smallLetterRegex.test(input);
        },
        [password],
    );

    const toggleTrue = useCallback((caseKey: AllowKeys) => {
        setIsPasswordValid((prevState) => ({
            ...prevState,
            [caseKey]: true,
        }));
    }, []);

    const toggleFalse = useCallback((caseKey: AllowKeys) => {
        setIsPasswordValid((prevState) => ({
            ...prevState,
            [caseKey]: false,
        }));
    }, []);

    const handleLength = useCallback(
        (passwordValue: string) => {
            if (passwordValue.length == 8) {
                if (!isPasswordValid.length) {
                    toggleTrue('length');
                }
            }
            if (passwordValue.length < 8) {
                if (isPasswordValid.length) {
                    toggleFalse('length');
                }
            }
        },
        [password],
    );

    const handleNumber = useCallback(
        (passwordValue: string) => {
            if (containsNumber(passwordValue)) {
                if (!isPasswordValid.number) {
                    toggleTrue('number');
                }
            } else {
                if (isPasswordValid.number) {
                    toggleFalse('number');
                }
            }
        },
        [password],
    );

    const handleSpecialCharacter = useCallback(
        (passwordValue: string) => {
            if (hasSpecialCharacter(passwordValue)) {
                if (!isPasswordValid.specialCharacter) {
                    toggleTrue('specialCharacter');
                }
            } else {
                if (isPasswordValid.specialCharacter) {
                    toggleFalse('specialCharacter');
                }
            }
        },
        [password],
    );

    const handleSmallLetter = useCallback(
        (passwordValue: string) => {
            if (isSmallLetter(passwordValue)) {
                if (!isPasswordValid.small) {
                    toggleTrue('small');
                }
            } else {
                if (isPasswordValid.small) {
                    toggleFalse('small');
                }
            }
        },
        [password],
    );

    const handleCapitalLetter = useCallback(
        (passwordValue: string) => {
            if (isCapitalLetter(passwordValue)) {
                if (!isPasswordValid.capital) {
                    toggleTrue('capital');
                }
            } else {
                if (isPasswordValid.capital) {
                    toggleFalse('capital');
                }
            }
        },
        [password],
    );

    const handleMatch = useCallback(
        (passwordValue: string) => {
            if (passwordValue == password) {
                if (!isPasswordValid.match) {
                    toggleTrue('match');
                }
            } else {
                if (isPasswordValid.match) {
                    toggleFalse('match');
                }
            }
        },
        [passwordNew],
    );

    const token = urlParams.get('Token');

    if (token === null) {
        return <InvalidToken />;
    }

    const allValuesTrueExceptMatch = (
        Object.keys(isPasswordValid) as Array<AllowKeys>
    )
        .filter((key) => key !== 'match')
        .every((key) => isPasswordValid[key]);

    const allValuesTrue = (
        Object.keys(isPasswordValid) as Array<AllowKeys>
    ).every((key) => isPasswordValid[key]);

    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value.toString());
        handleLength(event.target.value.toString());
        handleSpecialCharacter(event.target.value.toString());
        handleCapitalLetter(event.target.value.toString());
        handleSmallLetter(event.target.value.toString());
        handleNumber(event.target.value.toString());
    };
    const handlePassword_2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordNew(event.target.value.toString());
        handleMatch(event.target.value.toString());
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setMessage('');
        event.preventDefault();
        const message = await resetPasswordPost(token, password, passwordNew);
        setPassword('');
        setPasswordNew('');
        setMessage(message);
    };

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <BoxCenterRegisterLogin onSubmit={handleSubmit}>
                <FormTitle>Reset password</FormTitle>
                <FormContainerCenter>
                    <FormInput
                        name="Password"
                        type="password"
                        onChange={handlePassword}
                        placeholder="Password"
                        value={password}
                        onFocus={() => {
                            setIsOpenPassword(true);
                        }}
                        onBlur={() => {
                            setIsOpenPassword(false);
                        }}
                    />

                    <PasswordBox isOpen={isOpenPassword}>
                        <InfoText>
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.length}
                                text="Password should have at least 8 characters"
                            />
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.specialCharacter}
                                text="Special character. Symbols like !, @, #, $, etc."
                            />
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.capital}
                                text="At least one capital letter"
                            />
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.small}
                                text="At least one lowercase letter"
                            />
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.number}
                                text="At least one number (1,2,3 etc.)"
                            />
                        </InfoText>
                    </PasswordBox>
                    <FormInput
                        name="Password_2"
                        type="password"
                        onChange={handlePassword_2}
                        placeholder="Repeat Password"
                        value={passwordNew}
                        disabled={!allValuesTrueExceptMatch}
                        onFocus={() => {
                            setIsOpenPasswordNew(true);
                        }}
                        onBlur={() => {
                            setIsOpenPasswordNew(false);
                        }}
                    />
                    <PasswordBox isOpen={isOpenPasswordNew}>
                        <InfoText>
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.match}
                                text="Passwords are matching"
                            />
                        </InfoText>
                    </PasswordBox>
                    <ClassicButton
                        className="navbar__item"
                        type="submit"
                        value="Submit"
                        disabled={!allValuesTrue}
                    >
                        Reset
                    </ClassicButton>
                </FormContainerCenter>
            </BoxCenterRegisterLogin>
            {message !== '' && message !== 'Success' && (
                <ErrorInfoBox>{message}</ErrorInfoBox>
            )}
            {message !== '' && message === 'Success' && (
                <SuccessInfoBox>{message}</SuccessInfoBox>
            )}
        </div>
    );
};
