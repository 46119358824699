import React, { useMemo } from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { UserCardCatalog, SideBar, CurrentDeck } from '../Modules';
import { CardInterfaceAmount, MainDeckInterface } from '../Types/CardInterface';
import { getCookie } from '../../getCookie';
import { SideBarResponsive } from '../Modules/SideBar/SideBarResponsive';
import { SearchCardForm } from '../Types/SearchCardForm';
import { filterAndSortByCardName } from '../filterAndSortByCardName';
import { NewDeckResponsive } from '../Modules/CurrentDeck/NewDeckResponsive';
import { DeleteDeck } from '../Modules/CurrentDeck/DeleteDeck';
import { deleteDeck } from '../Api/deleteDeck';
import { removeCardFromDeck } from '../removeCardFromDeck';
import { addCardToDeck } from '../addCardToDeck';
import { isDeckValid } from '../isDeckValid';
import { updateDeck } from '../Api/updateDeck';
import { DeckText, Triangle } from '../Components/styled/Catalog';
import { CardSelectedType } from '../Types/CardIndex';
import { useClickOutside } from '../useClickOutside';
import { LoaderData, loader } from './DeckBuilder.loader';

export default DeckBuilder;
export function DeckBuilder() {
    const ref = useRef(null);

    const [allCards, setAllCards] = useState<Array<CardInterfaceAmount>>([]);
    const [deckCards, setDeckCards] = useState<MainDeckInterface>({
        Cards: [],
        DeckName: '',
    });
    const [detailCardId, setDetailCardId] = useState('');
    const [selectedCard, setSelectedCard] = useState<CardSelectedType>({
        label: '',
        index: -1,
    });
    const [isResponsiveDeck, setIsResponsiveDeck] = useState(true);
    const [validDeck, setValidDeck] = useState('');
    const [hasNotValidCard, setHasNotValidCard] = useState(false);
    const [deckNames, setDeckNames] = useState<Array<string>>([]);
    const [isNewDeckScreen, setIsNewDeckScreen] = useState(false);
    const [isDeleteScreen, setIsDeleteScreen] = useState(false);
    const [form, setForm] = useState<SearchCardForm>({
        Search: '',
    });
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const cookieValue = getCookie('bloodstor_user');

    useMemo(() => {
        //if ( loaderData === undefined) return
        loader().then((data) => {
            const { mainDeck, allCards, deckNames } = data as LoaderData;
            setDeckNames(deckNames);
            setAllCards(allCards);
            setDeckCards(mainDeck);
        });
    }, []);

    const onReset = useCallback(
        () => setSelectedCard({ index: -1, label: '' }),
        [],
    );
    useClickOutside(ref, onReset);

    useEffect(() => {
        if (cookieValue === undefined) {
            return;
        }

        if (deckCards?.DeckName !== '') {
            isDeckValid(setValidDeck, deckCards!, validDeck);
        }
    }, [deckCards]);

    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const handlePageClick = (event: MouseEvent) => {
        if (detailCardId !== '' && (event.target as Element).id !== 'card') {
            setDetailCardId('');
        }
    };

    const updateDeckInit = (changeDeck: string) => {
        updateDeck(changeDeck, cookieValue, deckCards, setDeckCards);
    };

    const deleteDeckInit = () => {
        deleteDeck(
            cookieValue,
            deckCards,
            deckNames,
            setDeckCards,
            setDeckNames,
        );
    };

    const addCardToDeckInit = (card: CardInterfaceAmount) => {
        addCardToDeck(card, deckCards, setDeckCards);
    };

    const removeCardFromDeckInit = (card: CardInterfaceAmount) => {
        removeCardFromDeck(card, deckCards, setDeckCards);
    };

    //const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const handleKeyDown = (event: any) => {
        if (event.key !== 'Escape') {
            return;
        }
        setDetailCardId('');
    };

    document.addEventListener('mousedown', handlePageClick);
    document.addEventListener('keyup', handleKeyDown);

    if (isDeleteScreen) {
        return (
            <DeleteDeck
                deckCards={deckCards}
                deleteDeck={deleteDeckInit}
                setIsDeleteScreen={setIsDeleteScreen}
            />
        );
    }

    if (isNewDeckScreen) {
        return (
            <NewDeckResponsive
                deckNames={deckNames}
                setDeckCards={setDeckCards}
                setDeckNames={setDeckNames}
                setIsNewDeckScreen={setIsNewDeckScreen}
            />
        );
    }

    if (screenWidth > 675) {
        return (
            <div className="body background_library" onClick={onReset}>
                <SideBar
                    deleteDeck={deleteDeckInit}
                    updateDeck={updateDeckInit}
                    validDeck={validDeck}
                    deckNames={deckNames}
                    setDeckNames={setDeckNames}
                    deckCards={deckCards}
                    setDeckCards={setDeckCards}
                    form={form}
                    setForm={setForm}
                    hasNotValidCard={hasNotValidCard}
                />
                <UserCardCatalog
                    addCardToDeck={addCardToDeckInit}
                    allCards={filterAndSortByCardName(allCards, form.Search)}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                />

                <DeckText>
                    Deck
                    <Triangle />
                </DeckText>

                <CurrentDeck
                    removeCardFromDeck={removeCardFromDeckInit}
                    detailCardId={detailCardId}
                    setDetailCardId={setDetailCardId}
                    allCards={allCards}
                    deckCards={filterAndSortByCardName(
                        deckCards.Cards,
                        form.Search,
                    )}
                    setDeckCards={setDeckCards}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    setHasNotValidCard={setHasNotValidCard}
                    hasNotValidCard={hasNotValidCard}
                />
            </div>
        );
    }

    return (
        <div className="body background_library">
            <SideBarResponsive
                deleteDeck={deleteDeckInit}
                updateDeck={updateDeckInit}
                validDeck={validDeck}
                deckNames={deckNames}
                setDeckNames={setDeckNames}
                deckCards={deckCards}
                setDeckCards={setDeckCards}
                setIsResponsiveDeck={setIsResponsiveDeck}
                isResponsiveDeck={isResponsiveDeck}
                form={form}
                setForm={setForm}
                setIsNewDeckScreen={setIsNewDeckScreen}
                setIsDeleteDeckScreen={setIsDeleteScreen}
            />
            {screenWidth <= 675 && isResponsiveDeck === true && (
                <UserCardCatalog
                    addCardToDeck={addCardToDeckInit}
                    allCards={filterAndSortByCardName(allCards, form.Search)}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                />
            )}

            {screenWidth <= 675 && isResponsiveDeck === false && (
                <CurrentDeck
                    removeCardFromDeck={removeCardFromDeckInit}
                    detailCardId={detailCardId}
                    setDetailCardId={setDetailCardId}
                    deckCards={filterAndSortByCardName(
                        deckCards.Cards,
                        form.Search,
                    )}
                    setDeckCards={setDeckCards}
                    selectedCard={selectedCard}
                    allCards={allCards}
                    setSelectedCard={setSelectedCard}
                    setHasNotValidCard={setHasNotValidCard}
                    hasNotValidCard={hasNotValidCard}
                />
            )}
        </div>
    );
}
