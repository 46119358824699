const root_url = '/user/' as const;

export const route = {
    ROOT: root_url,
    ACTIVATE: root_url + 'activate',
    LOGIN: root_url + 'login',
    LOGOUT: root_url + 'logout',
    REGISTER: root_url + 'register',
    FORGOT_PASSWORD: root_url + 'forgot_password',
    CHANGE_PASSWORD: root_url + 'change_password',
    DELETE_ACCOUNT: root_url + 'delete_account',
    DELETE_ACCOUNT_CONFIRM: root_url + 'confirm_delete',
    DASHBOARD: root_url + 'dashboard',
    CARD_COLLECTION: root_url + 'gallery',
    //MATCH_HISTORY: root_url + 'match_history',
    DECK_BUILDER: root_url + 'deck_builder',
    SHOP: root_url + 'shop',
    RESET_PASSWORD: root_url + 'reset_password',
    USER: root_url + 'data',
    MARKET: root_url + 'market',
    MARKET_ADD: root_url + 'market_add',
    MARKET_MY: root_url + 'market_my',
    DATA_PROTECTION: root_url + 'data_protection',
    TERMS_OF_USE: root_url + 'terms_of_use',
} as const;

export const CARD_SYMBOLS_OFFSET = '-15px' as const;
export const SMAlL_SCREEN = '1295px' as const;
export const TABLET_WIDTH = '940px' as const;
export const MOBILE_WIDTH = '555px' as const;
export const GALAXY_FOLD = '281px' as const;
export const SCREEN_WIDTH = '12000px' as const;

export const SMALLER_CARD = '360px' as const;
export const ICON_SIZE = 25 as const;

export const HEIGHT_CUT = '835px' as const;

export const DECK_AMOUNT_LIMIT = 3 as const;

export const CARD_HOVER_EFFEKT = 675 as const; // "45.56.67.70" // "45.56.67.70" //  "127.0.0.1"
export const SERVER_URL = 'user.asthell.com' as const; // "45.56.67.70" // "45.56.67.70" //  "127.0.0.1"
export const SERVER_CARDS_URL = 'card.asthell.com' as const; // "45.56.67.70" // "45.56.67.70" //  "127.0.0.1"

export const GOOGLE_CLIENT_ID =
    '960379698482-ode5fh6onhdbgrcrl8bi2phk0nrr1pbs.apps.googleusercontent.com' as const;

export const Touch = `(hover: none)` as const;
export const NoTouch = `(hover: hover)` as const;

export const MAX_MARKET_PRICE = 1000000000000;
