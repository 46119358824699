import React, { createContext, useContext, useState, ReactNode } from 'react';

export interface AdminContextProps {
    userCookie: string | undefined;
    setUserCookie: (userCookie: string | undefined) => void;
    userUsername: string;
    setAdminUsername: (newUsername: string) => void;
}

const AdminContext = createContext<AdminContextProps | undefined>(undefined);

interface AdminProviderProps {
    children: ReactNode; // This prop type includes any JSX that the component wraps
}

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
    const [userCookie, setUserCookie] = useState<string | undefined>(undefined);
    const [userUsername, setUsername] = useState<string>('');

    const setAdminUsername = (newUsername: string) => {
        setUsername(newUsername);
    };

    const contextValue: AdminContextProps = {
        userCookie,
        setUserCookie,
        userUsername,
        setAdminUsername,
    };

    return (
        <AdminContext.Provider value={contextValue}>
            {children}
        </AdminContext.Provider>
    );
};

export const useAdmin = (): AdminContextProps => {
    const context = useContext(AdminContext);
    if (!context) {
        throw new Error('useAdmin must be used within an AdminProvider');
    }
    return context;
};
