import React, { useState } from 'react';
import { getCookie, setCookie } from '../../getCookie';
import AdminContext from '../../AdminContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { GOOGLE_CLIENT_ID, SERVER_URL, route } from '../../constants';
import { BackgroundImage } from '../../BackgroundImage';
import DashboardBackground from './../../assets/dashboard.jpg';
import './google-button.scss';
import jwt_decode from 'jwt-decode';

import {
    BoxCenterRegisterLogin,
    ClassicButton,
    ClassicButtonSmall,
    FormContainerCenter,
    FormErrorMessage,
    FormFooter,
    FormInput,
    FormTitle,
} from '../../market/Components/styled';
import {
    ForgotPasswordContainer,
    ForgotPasswordText,
} from '../../market/Components/styled/FormInput';
import { getUserFromToken } from '../../api/getUserFromToken';
import { Conatiner } from './Dashboard.styles';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { loginGoogle } from './responseGoogle';
import { BoxCenterRegisterLoginDiv } from '../../market/Components/styled/BoxCenterRegisterLogin';
import { GoogleData } from '../../Types/GoogleData';

type LoginResponse = {
    message: string;
};

export const LoginPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    //const { setUserCookie } = React.useContext(AdminContext);
    const cookieValue = getCookie('bloodstor_user');

    const login = async (username: string, password: string) => {
        const res = await fetch(`https://${SERVER_URL}/login`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                Username: username,
                Password: password,
            }),
        });
        if (res.ok) {
            const data: { message: string } = await res.json();
            setCookie('bloodstor_user', data.message, 360);
            //setUserCookie(getCookie('bloodstor_user'));
            navigate(route.DASHBOARD);
        } else {
            const data: LoginResponse = await res.json();
            if (data.message !== 'success') {
                setErrorMessage(data.message);
            }
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage('');
        login(username, password);
    };

    if (cookieValue !== undefined && cookieValue !== '') {
        return <Navigate to={route.DASHBOARD} replace />;
    }

    /*
        <FormTitle>Login</FormTitle>
          <FormInput
            name="Username"
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
          <FormInput
            name="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />

            <ForgotPasswordText onClick={() => navigate(route.FORGOT_PASSWORD)}>
              Forgot password?
            </ForgotPasswordText>
          <ClassicButton className="navbar__item" type="submit" value="Submit">
            Submit
          </ClassicButton>

<FormFooter>
            <p className="white_text">Don't have an account?</p>
            <ClassicButtonSmall onClick={() => navigate(route.REGISTER)}>
              Register
            </ClassicButtonSmall>
          </FormFooter>

{errorMessage !== "" && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
  */

    /*
  return (
        <div className="dashboard">
      <BackgroundImage src={DashboardBackground} />
      <BoxCenterRegisterLoginDiv >
        <FormTitle>Login</FormTitle>
        <FormContainerCenter height={288}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const data: GoogleData = jwt_decode(credentialResponse.credential || "");
            loginGoogle(data.email)
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />;
          </GoogleOAuthProvider>;
          <FormFooter>
            <p className="white_text">Don't have an account?</p>
            <ClassicButtonSmall onClick={() => navigate(route.REGISTER)}>
              Register
            </ClassicButtonSmall>
          </FormFooter>
        </FormContainerCenter>
      </BoxCenterRegisterLoginDiv>
      {errorMessage !== "" && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </div>

  );
  */

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <BoxCenterRegisterLogin onSubmit={handleSubmit}>
                <FormTitle>Login</FormTitle>
                <FormContainerCenter>
                    <FormInput
                        name="Username"
                        type="text"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                    />
                    <FormInput
                        name="Password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    <ForgotPasswordContainer>
                        <ForgotPasswordText
                            onClick={() => navigate(route.FORGOT_PASSWORD)}
                        >
                            Forgot password?
                        </ForgotPasswordText>
                    </ForgotPasswordContainer>

                    <ClassicButton
                        className="navbar__item"
                        type="submit"
                        value="Submit"
                    >
                        Login
                    </ClassicButton>
                    <FormFooter>
                        <p className="white_text">
                            Don&apos;t have an account?
                        </p>
                        <ClassicButtonSmall
                            onClick={() => navigate(route.REGISTER)}
                        >
                            Register
                        </ClassicButtonSmall>
                    </FormFooter>
                </FormContainerCenter>
            </BoxCenterRegisterLogin>
            {errorMessage !== '' && (
                <FormErrorMessage>{errorMessage}</FormErrorMessage>
            )}
        </div>
    );
};
