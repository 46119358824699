import styled from 'styled-components';

export const IconContainer = styled.div`
    display: flex;
    max-height: 20px;
    justify-content: center;
    align-items: center;
`;

export const PointsNavbar = styled.div`
    display: flex;
    max-height: 20px;
    justify-content: center;
    margin: 0px 20px;
`;

export const IconTitle = styled.div`
    color: lightgray;
`;

export const IconSvgConatiner = styled.div`
    max-width: 20px;
    max-height: 20px;
`;

export const IconSvgConatinerShop = styled.div`
    max-width: 20px;
    max-height: 18px;
`;

export const IconSvgConatinerController = styled.div`
    max-width: 20px;
    max-height: 17px;
    margin: 0px 5px;
`;

export const PaypalPopup = styled.div`
    padding: 10px 30px;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 5px;
    border: 1px solid white;
    max-height: 70vh;
    max-height: 70svh;
    overflow: auto;
`;

export const PaypalPopupTitle = styled.div`
    margin: 10px 0px;
`;

export const Circle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid blue;
`;

export const NewCardsContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 40px inset;
    width: 100%;
    position: absolute;
    top: 0px;
    bottom: 50px;
    animation: NewCardsFadeIn 0.8s ease-in forwards; /* added animation property */

    @keyframes NewCardsFadeIn {
        from {
            background-color: rgba(0, 0, 0, 0);
            box-shadow: rgba(255, 255, 255, 0) 0px 0px 40px inset;
        }

        to {
            background-color: rgba(0, 0, 0, 0.4);
            box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 40px inset;
        }
    }
`;
