import React from "react";
import { Container } from "./DataProtection.styles";

export const DataProtection  = () => {
    return (
        <Container>
            <h1>Data Protection Information</h1>

            <h2>Introduction</h2>
            <p>
            Welcome to <b>Asthell.com</b>! We take your privacy and the protection of your personal data very seriously. This document
            outlines how we collect, use, store, and protect your data in accordance with applicable data protection laws.
            </p>

            <h2>Collection of Personal Data</h2>
            <p>
            When you use <b>Asthell.com</b>, we may collect certain personal information from you. This may include your name, email
            address, and any other information you provide to us during the registration or login process. We only collect
            personal data that is necessary for the functioning of <b>Asthell.com</b> and for providing you with a seamless experience.
            </p>

            <h2>Use of Personal Data</h2>
            <p>
            We use the personal data we collect to operate, maintain, and improve <b>Asthell.com</b>. This may include sending you
            notifications, updates, and relevant information related to the game. We may also use your personal data for
            statistical purposes to analyze user behavior and improve our services.
            </p>

            <h2>Data Security</h2>
            <p>
            We take appropriate technical and organizational measures to safeguard your personal data against unauthorized access,
            loss, or alteration. We use industry-standard security practices to protect your information and ensure its
            confidentiality.
            </p>

            <h2>Data Sharing and Third Parties</h2>
            <p>
            We do not sell, trade, or otherwise transfer your personal data to third parties without your explicit consent, except
            as required by law or as necessary to provide our services. In certain cases, we may share your data with trusted
            third-party service providers who assist us in operating <b>Asthell.com</b>, subject to strict confidentiality obligations.
            </p>

            <h2>Legal Basis for Processing</h2>
            <p>
            We process your personal data on the legal basis of your consent, which you provide during the registration or login
            process. You have the right to withdraw your consent at any time, although this may affect your ability to use certain
            features of the game.
            </p>

            <h2>Retention of Personal Data</h2>
            <p>
            We retain your personal data for as long as necessary to fulfill the purposes for which it was collected, unless a
            longer retention period is required or permitted by law. Once the data is no longer needed, it will be securely
            deleted or anonymized.
            </p>

            <h2>Changes to this Data Protection Information</h2>
            <p>
            We reserve the right to update or modify this data protection information at any time. Any changes will be posted on
            this page, and we encourage you to review this information periodically to stay informed about how we handle your
            personal data.
            </p>

            <h2>Contact Us</h2>
            <p>
            If you have any questions, concerns, or requests regarding your personal data or this data protection information,
            please contact us at <a href="mailto:asthell@gmail.com">asthell@gmail.com</a>.
            </p>
        </Container>
    )
}