import React, { ReactNode } from 'react';
import { InfoText } from './PasswordBox.style';

type Props = {
    children: ReactNode;
    isOpen: boolean;
};

export const PasswordBox = ({ children, isOpen }: Props) => {
    return <InfoText isOpen={isOpen}>{children}</InfoText>;
};
