import styled, { keyframes, css } from 'styled-components';

// Define a keyframe animation
const slideIn = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px; /* You can adjust this value as needed */
  }
`;

export const InfoText = styled.div<{ isOpen: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--text-color);
    text-align: center;
    /*margin-bottom: 1.2rem; */
    text-shadow: 2px 2px rgb(20, 20, 20);
    overflow: hidden; /* Hide the overflow */
    max-height: 0; /* Initial height is 0 */
    transition: max-height 0.4s ease; /* Transition for smooth animation */

    /* Conditional animation based on isOpen prop */
    ${({ isOpen }) =>
        isOpen &&
        css`
            animation: ${slideIn} 0.7s ease; /* Apply the animation when isOpen is true */
            max-height: 1000px; /* Set the max-height to the desired expanded value */
        `}
`;
