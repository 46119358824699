import * as React from 'react';
import 'reactjs-popup/dist/index.css';
import { StyleFlexSpaceAround, StyleButton } from './styled';
import { ConfirmationPopupContainer } from './BuyPopupStyle';

type TypeBuyPopup = {
    buyCard: (card_id: string) => Promise<void>;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    card_id: string;
};

export const BuyPopup = (props: TypeBuyPopup) => {
    const { buyCard, card_id, setIsOpen } = props;

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <ConfirmationPopupContainer>
                Are you sure?
                <StyleFlexSpaceAround>
                    <StyleButton
                        id="card"
                        color={'rgb(110, 200, 105)'}
                        onClick={() => buyCard(card_id)}
                    >
                        Buy
                    </StyleButton>
                    <StyleButton
                        onClick={handleClose}
                        color={'rgb(205, 73, 73)'}
                    >
                        Close
                    </StyleButton>
                </StyleFlexSpaceAround>
            </ConfirmationPopupContainer>
        </>
    );
};
