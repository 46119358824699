import { CardInterfaceAmount, MainDeckInterface } from "./Types/CardInterface";
import { DECK_AMOUNT_LIMIT } from "./constants";

const canAddCard = (
  card: CardInterfaceAmount,
  mainDeck: MainDeckInterface
): boolean => {
  let cardIndex = -1;

  for (let index = 0; index < mainDeck.Cards.length; index++) {
    if (mainDeck.Cards[index].Card.ID === card.Card.ID) {
      cardIndex = index;
    }
  }

  return (
    parseInt(mainDeck.Cards[cardIndex].Amount) < DECK_AMOUNT_LIMIT &&
    parseInt(mainDeck.Cards[cardIndex].Amount) < parseInt(card.Amount)
  );
};

const isCardInMainDeck = (
  card: CardInterfaceAmount,
  mainDeck: MainDeckInterface
): boolean => {
  const mainDeckCardIds: Array<string> = [];
  mainDeck.Cards.forEach(function (data) {
    mainDeckCardIds.push(data.Card.ID);
  });
  return mainDeckCardIds.includes(card.Card.ID);
};

const addCardAmount = (
  card: CardInterfaceAmount,
  mainDeck: MainDeckInterface
): MainDeckInterface => {
  const newDeck = mainDeck;
  let cardIndex = -1;

  for (let index = 0; index < mainDeck.Cards.length; index++) {
    if (mainDeck.Cards[index].Card.ID === card.Card.ID) {
      cardIndex = index;
    }
  }

  const amount = parseInt(newDeck.Cards[cardIndex].Amount) + 1;
  newDeck.Cards[cardIndex].Amount = amount.toString();
  return mainDeck;
};

const addCard = (
  card: CardInterfaceAmount,
  mainDeck: MainDeckInterface
): MainDeckInterface => {
  mainDeck.Cards.push({
    Card: card.Card,
    Amount: "1",
  });
  return mainDeck;
};

export const addCardToDeck = (
  card: CardInterfaceAmount,
  deckCards: MainDeckInterface,
  setDeckCards: (value: React.SetStateAction<MainDeckInterface>) => void
) => {
  if (isCardInMainDeck(card, deckCards)) {
    if (!canAddCard(card, deckCards)) {
      return;
    }
    const newDeck = addCardAmount(card, deckCards);
    setDeckCards({ Cards: newDeck.Cards, DeckName: newDeck.DeckName });
    return;
  }
  const newDeck = addCard(card, deckCards);
  setDeckCards({ Cards: newDeck.Cards, DeckName: newDeck.DeckName });
};
