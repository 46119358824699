import {
    CardInterfaceAmount,
    MarketCardInterface,
} from './Types/CardInterface';

export const filterCards = (
    cardArray: MarketCardInterface[],
    searchString: string,
) => {
    const searchLowerCase = searchString.toLowerCase();
    return cardArray.filter((card) =>
        card.Card.CardName.toLowerCase().includes(searchLowerCase),
    );
};

export const filterCardsAdd = (
    cardArray: CardInterfaceAmount[],
    searchString: string,
) => {
    const searchLowerCase = searchString.toLowerCase();
    return cardArray.filter((card) =>
        card.Card.CardName.toLowerCase().includes(searchLowerCase),
    );
};
