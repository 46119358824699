import * as React from 'react';
import './MarketSideBar.scss';
import { route } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { MarketFormType } from '../../Types/MarketFormInterface';
import {
    FooterButtonIconContainer,
    IconContainer,
    PrestigePointsContainer,
    PrestigePointsText,
    SearchInput,
    SideBarResponsiveContainer,
} from './SideBarStyle';
import { ReactComponent as Star } from '../../assets/icons/star.svg';

import { FidgetSpinnerLoader } from '../../../fidgetSpinner';
import { Dropdown } from '../../Components/Dropdown';
import { NavigationType } from '../../Types/DropdownNavigation';
import { FooterContainer } from '../../../deckBuilder/Components/styled/SideBarStyle';
import { ReactComponent as Home } from './../../assets/icons/house.svg';

interface SideBarProps {
    form: MarketFormType;
    setForm: React.Dispatch<React.SetStateAction<MarketFormType>>;
    prestigePoints: number;
}

export const MarketSideBarResponsive = (props: SideBarProps) => {
    const { form, setForm, prestigePoints } = props;
    const navigate = useNavigate();
    const navigationDict: NavigationType[] = [
        {
            label: 'Market',
            onClick: () => navigate(route.MARKET),
        },
        {
            label: 'My Market',
            onClick: () => navigate(route.MARKET_MY),
        },
        {
            label: 'Create',
            onClick: () => navigate(route.MARKET_ADD),
        },
    ];

    return (
        <>
            <SideBarResponsiveContainer>
                <SearchInput
                    type="text"
                    id="new_deck"
                    placeholder="Search"
                    value={form.Search}
                    onChange={(
                        element: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                        setForm({ ...form, Search: element.target.value });
                    }}
                />
                <PrestigePointsContainer>
                    <PrestigePointsText className="number_font">
                        {prestigePoints !== -1 ? (
                            prestigePoints
                        ) : (
                            <FidgetSpinnerLoader size={15} />
                        )}
                    </PrestigePointsText>

                    <IconContainer>
                        <Star
                            viewBox="0 0 20 20"
                            width="100%"
                            height="100%"
                        ></Star>
                    </IconContainer>
                </PrestigePointsContainer>
                <Dropdown options={navigationDict} />
            </SideBarResponsiveContainer>
            <FooterContainer>
                <FooterButtonIconContainer
                    onClick={() => navigate(route.DASHBOARD)}
                >
                    <Home width="100%" height="100%"></Home>
                </FooterButtonIconContainer>
            </FooterContainer>
        </>
    );
};
