import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    inset: 0;
    overflow-y: scroll;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    & * {
        color: var(--text-color);
        text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
    }
    & h1 {
        text-align: center;
    }
`;
