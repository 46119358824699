import styled from "styled-components";
import { SMALLER_CARD } from "../../constants";

export const Picture = styled.picture`
  position: relative;
`;

export const Img = styled.img`
  line-height: 0;
    display: block;
  margin: 0px;
  max-width: 100%;
  height: auto;
`;

export const Container = styled.div`
    position:relative;
    width: 100%;
    min-height: 105px;
    @media (max-width: ${SMALLER_CARD}) {
    min-height: 85px;
  }
`;