import styled from "styled-components";

export const IconButtonGreen = styled.button`
  min-height: 35px;
  max-height: 35px;
  margin: auto;
  width: 195px;
  border: solid 2px lightgray;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(23, 207, 23, 0.7);

  overflow: hidden;
  &:hover {
    background-color: rgba(23, 207, 23, 0.9);
    cursor: pointer;
  }
`;

export const IconButtonRed = styled.button`
  min-height: 35px;
  max-height: 35px;
  margin: auto;
  width: 195px;
  border: solid 2px lightgray;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.7);

  overflow: hidden;
  &:hover {
    background-color: rgba(255, 0, 0, 0.9);
    cursor: pointer;
  }
`;