export const currency = 'USD';

export const CARD_SYMBOLS_OFFSET = '-15px';
export const SMAlL_SCREEN = '1295px';
export const TABLET_WIDTH = '940px';
export const MOBILE_WIDTH = '555px';
export const GALAXY_FOLD = '281px';

export const HEIGHT_CUT = '835px';

export const CARD_PACK_PRIZE = '1';

export const PAYPAL_SANDBOX_ID =
    'ASjkpcGFby_5-HX76BrLZf2PTbAJsP7rNs3wwsYBNdoPPPYwzP2CAAEXgLGHqdliyVhHhPpG4Qh2ZzTV';

export const PAYPAL_LIVE_ID =
    'AWff4GYRNvCwDg_6rErVJLLpwLuSB1acL01SwFWQ398tB0K08qxtBkKy93D396veDsLXx9V4zJtt5wyf';
