import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const CardDetailContainer = styled.div<{
  isSpellCard: boolean;
}>`
  background-color: ${({ isSpellCard }) =>
    isSpellCard ? "rgb(72, 172, 82)" : "rgb(212, 201, 83)"};
  border: solid;
  border-color: #948f57;
  border-radius: 2px;
  border-width: 1px;
  cursor: pointer;
  width: 80vw;
  max-width: 260px;
  margin: auto;
  position: relative;

  animation-duration: 0.6s;
  animation-timing-function: ease;
  &.fade-in {
    opacity: 1;
    animation-name: ${fadeIn};
    animation-fill-mode: forwards;
  }
`;

export const CardDetailName = styled.div`
  padding-bottom: 5px;
`;

export const CardDetailDescription = styled.div`
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 90%;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const NameDetailContainer = styled.div<{ isSpellCard: boolean }>`
  color: rgb(0, 0, 0);
  font-size: 10px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  background-color: ${({ isSpellCard }) =>
    isSpellCard ? "rgb(140, 235, 149)" : "rgb(244, 235, 138)"};

  box-shadow: ${({ isSpellCard }) =>
    isSpellCard ? "#8d6c1a 1px 1px 5px" : "darkgoldenrod 1px 1px 5px"};

  background-color: ${({ isSpellCard }) =>
    isSpellCard ? "1px solid #8d6c1a" : "1px solid darkgoldenrod"};
  border-top: 4px solid rgba(255, 255, 255, 0.35);
  border-left: 4px solid rgba(255, 255, 255, 0.35);
  border-right: 4px solid rgba(0, 0, 0, 0.15);
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
  margin: 5px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
`;
