import React from 'react';
import { CardInterface } from '../../Types/CardInterface';
import { CardStats, HandCardStyle, CrackingMask } from './CardStyle';
import Popup from 'reactjs-popup';
import cracksImage from '../../../assets/card/cracks.png';
import { useState } from 'react';
import { CardCost } from './Cost';
import { Name } from './Name';
import { Plus } from './Plus';
import { Attack } from './Attack';
import { Life } from './Life';
import { CardDetail } from './CardDetail';
import { BuyPopup } from '../BuyPopup';
import {
    ChoiceFieldStyle,
    DescriptionButton,
    CardButtonsContainer,
} from './ChoiceFieldStyle';
import './CardPopup.css';
import { CardImageLoader } from '../ImageLoader';
import { Gem } from './Gem';

import { ReactComponent as Lupe } from '../../assets/icons/lupe.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as BackArrow } from '../../assets/icons/left_arrow.svg';
import { IconContainer } from '../styled/AddCardResponsiveStyle';

type MarketCardType = {
    card: CardInterface;
    selectCard: (card_index: any) => void;
    index: number;
    buyCard?: (card_id: string) => Promise<void>;
    backToUser?: (card_id: string) => Promise<void>;
    addToMarket?: React.Dispatch<
        React.SetStateAction<CardInterface | undefined>
    >;
    selectedCard: number | undefined;
    marketOption: 'market' | 'my_market' | 'create';
};

type CardType = {
    card: CardInterface;
};
const MonsterHand = (props: CardType) => {
    const { card } = props;
    const size = 30;
    return (
        <>
            <CardImageLoader imagePath={card.CardImage} />
            <Gem rareLvl={card.Rank} />
            <CardCost size={size} cost={card.Cost} />
            <Name name={card.CardName} isSpell={false} />
            <Plus size={size} plus={card.RegeneratePoints} />
            <Attack size={size} attack={card.Attack} />
            <Life size={size} life={card.Life} />
        </>
    );
};

const SpellCard = (props: CardType) => {
    const { card } = props;
    const size = 30;
    return (
        <>
            <CardImageLoader imagePath={card.CardImage} />
            <Gem rareLvl={card.Rank} />
            <CardCost size={size} cost={card.Cost} />
            <Name name={card.CardName} isSpell={true} />
        </>
    );
};

export const Card = (props: MarketCardType) => {
    const {
        card,
        selectCard,
        index,
        buyCard,
        selectedCard,
        marketOption,
        backToUser,
        addToMarket,
    } = props;
    const CardData = card.CardType === 'Spell' ? SpellCard : MonsterHand;
    const [rotation, setRotation] = useState('rotateX(0deg) rotateY(0deg)');
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        const card = event.currentTarget;
        const cardRect = card.getBoundingClientRect();
        const cardCenterX = cardRect.left + cardRect.width / 2;
        const cardCenterY = cardRect.top + cardRect.height / 2;
        const mouseX = event.clientX - cardCenterX;
        const mouseY = event.clientY - cardCenterY;
        const rotateX = -mouseY / 5;
        const rotateY = mouseX / 5;
        const transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        setRotation(transform);
    };

    const handleMouseLeave = () => {
        setRotation('');
        setIsHovered(false);
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleClick = () => {
        selectCard(index);
    };

    // ----------------------- Popups --------------------

    const BuyField = () => {
        const [isOpen, setIsOpen] = useState(false);

        if (index !== selectedCard) {
            return <></>;
        }
        return (
            <ChoiceFieldStyle>
                <CardButtonsContainer>
                    <Popup
                        trigger={
                            <DescriptionButton>
                                <Lupe
                                    viewBox="0 -20 120 180"
                                    width="100%"
                                    height="100%"
                                ></Lupe>
                            </DescriptionButton>
                        }
                        modal
                    >
                        <CardDetail card={card} />
                    </Popup>
                    <Popup
                        on="click"
                        open={isOpen}
                        onOpen={() => setIsOpen(true)}
                        trigger={<DescriptionButton>Buy</DescriptionButton>}
                        modal
                    >
                        <BuyPopup
                            setIsOpen={setIsOpen}
                            buyCard={buyCard!}
                            card_id={card.ID}
                        />
                    </Popup>
                </CardButtonsContainer>
            </ChoiceFieldStyle>
        );
    };

    const CreateField = () => {
        if (index !== selectedCard) {
            return <></>;
        }
        return (
            <ChoiceFieldStyle>
                <CardButtonsContainer>
                    <Popup
                        trigger={
                            <DescriptionButton>
                                <Lupe
                                    viewBox="0 -20 120 180"
                                    width="100%"
                                    height="100%"
                                ></Lupe>
                            </DescriptionButton>
                        }
                        modal
                    >
                        <CardDetail card={card} />
                    </Popup>

                    <DescriptionButton
                        onClick={() => {
                            addToMarket!(card);
                        }}
                    >
                        <IconContainer>
                            <PlusIcon width="100%" height="100%" />
                        </IconContainer>
                    </DescriptionButton>
                </CardButtonsContainer>
            </ChoiceFieldStyle>
        );
    };

    const MyMarketField = () => {
        if (index !== selectedCard) {
            return <></>;
        }
        return (
            <ChoiceFieldStyle>
                <CardButtonsContainer>
                    <Popup
                        trigger={
                            <DescriptionButton>
                                <Lupe
                                    viewBox="0 -20 120 180"
                                    width="100%"
                                    height="100%"
                                ></Lupe>
                            </DescriptionButton>
                        }
                        modal
                    >
                        <CardDetail card={card} />
                    </Popup>

                    <DescriptionButton onClick={() => backToUser!(card.ID)}>
                        <IconContainer>
                            <BackArrow width="100%" height="100%" />
                        </IconContainer>
                    </DescriptionButton>
                </CardButtonsContainer>
            </ChoiceFieldStyle>
        );
    };

    //
    const Empty = () => {
        return <React.Fragment></React.Fragment>;
    };

    let PopupField: () => JSX.Element;
    switch (marketOption) {
        case 'market':
            PopupField = BuyField;
            break;
        case 'my_market':
            PopupField = MyMarketField;
            break;
        case 'create':
            PopupField = CreateField;
            break;

        default:
            PopupField = Empty;
            break;
    }

    return (
        <>
            <HandCardStyle
                className="card-content"
                isSpellCard={card.CardType === 'Spell'}
                isHovered={isHovered}
                rotation={rotation}
                onClick={(e) => {
                    e.stopPropagation();
                    handleClick();
                }}
            >
                <PopupField />
                <CrackingMask
                    className="cracks-layer"
                    style={{
                        backgroundImage: `url(${cracksImage})`,
                    }}
                />
                <CardStats>
                    <CardData card={card} />
                </CardStats>
            </HandCardStyle>
        </>
    );
};
