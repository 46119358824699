import * as React from 'react';
import { useState, useEffect } from 'react';
import { UserCardCatalog, MarketSideBar } from '../Modules';
import { MarketCardInterface } from '../Types/CardInterface';
import { DeckBackend } from '../Api/AdminAPI';
import { getCookie } from '../../getCookie';
import { MarketFormType } from '../Types/MarketFormInterface';
import { buyCard, filterMarketCards } from '../Api';
import BackgroundImage from './../assets/market.jpg';
import { BackgroundImageSideBar } from '../BackgroundImage';
import { MarketSideBarResponsive } from '../Modules/SideBar/MarketSideBarResponsive';

import { isLocalhost } from '../factoryHelpers';
import { createMarketCard } from '../Types/CardInterface.factory';
import { sortByCardName } from '../sortByCardName';
import { useFilteredAndSortedCards } from '../shared/useFilteredAndSortedCards';

export const Market = () => {
    const [allCards, setAllCards] = useState<Array<MarketCardInterface>>([]);
    const [sortedCardsMemoized, setSortedCardsMemoized] = useState<
        Array<MarketCardInterface>
    >([]);
    const [detailCardId, setDetailCardId] = useState('');
    const [prestigePoints, setPrestigePoints] = useState(-1);
    const [form, SetForm] = useState<MarketFormType>({
        Search: '',
    });
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const cookieValue = getCookie('bloodstor_user');
    const api = new DeckBackend();

    useEffect(() => {
        if (cookieValue === undefined) {
            return;
        }

        api.getMarketCards(cookieValue).then(
            (response_json: Array<MarketCardInterface>) => {
                const sortedCards = sortByCardName(response_json);
                setAllCards(sortedCards);
                setSortedCardsMemoized(sortedCards);
            },
        );

        api.getUserPrestigePoints(cookieValue).then((response_json: number) =>
            setPrestigePoints(response_json),
        );
    }, []);

    useFilteredAndSortedCards(allCards, sortedCardsMemoized, form, setAllCards);

    if (isLocalhost() && allCards.length === 0) {
        setAllCards([createMarketCard({})]);
    }

    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const handlePageClick = (event: MouseEvent) => {
        if (detailCardId !== '' && (event.target as Element).id !== 'card') {
            setDetailCardId('');
        }
    };

    const buyCardInit = async (card_id: string) => {
        buyCard(card_id, cookieValue, setAllCards, setPrestigePoints, api);
    };

    //const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const handleKeyDown = (event: any) => {
        if (event.key !== 'Escape') {
            return;
        }
        setDetailCardId('');
    };

    document.addEventListener('mousedown', handlePageClick);
    document.addEventListener('keyup', handleKeyDown);

    const SideBarOption =
        screenWidth > 675 ? MarketSideBar : MarketSideBarResponsive;

    return (
        <div className="body">
            <BackgroundImageSideBar src={BackgroundImage} />
            <SideBarOption
                form={form}
                setForm={SetForm}
                prestigePoints={prestigePoints}
            />

            <UserCardCatalog
                buyCard={buyCardInit}
                detailCardId={detailCardId}
                setDetailCardId={setDetailCardId}
                allCards={allCards}
            />
        </div>
    );
};
