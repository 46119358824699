import styled from 'styled-components';

export const CatalogContainer = styled.div`
    left: 260px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: 675px) {
        position: unset;
        max-height: 100vh;
    }
`;

export const CollectionContainer = styled.div`
    left: 260px;
    position: absolute;
    top: 10px;
    bottom: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 10px;

    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide the scrollbar for other browsers */
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (max-width: 675px) {
        height: unset;
        top: 50px;
        position: absolute;
        bottom: 50px;
        left: unset;
        border-bottom: unset;
    }
`;

export const DeckContainer = styled.div`
    left: 260px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(38.8%);
    background-color: rgba(0, 0, 0, 0.5);

    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide the scrollbar for other browsers */
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (max-width: 675px) {
        top: 54px;
        position: absolute;
        left: unset;
        height: auto;
        border-bottom: unset;
        overflow-y: scroll;
        padding-bottom: 50px;
    }
`;

export const CatalogSubContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 15px;
    @media (max-width: 675px) {
        padding: 0px 0px;
    }
`;

export const MarketCard = styled.div`
    position: relative;
`;

export const CardCatalogContainer = styled.div<{ isOwed: boolean }>`
    position: relative;
    margin: 30px 50px;
    right: auto;
    box-shadow: 0px 0px 15px 3px black;
    opacity: ${({ isOwed }) => (isOwed ? '1' : '0.5')};
    @media (max-width: 675px) {
        margin: 20px 22px;
    }
`;

export const AmountContainer = styled.div<{ isValid?: boolean }>`
    color: ${({ isValid }) => (!isValid ? 'red' : 'white')};
    text-shadow: rgb(90 90 90) 1px 1px;
    text-align: center;
    font-size: 14;
    border: 1px solid white;
    border-bottom: none;
    background-color: black;
`;

AmountContainer.defaultProps = {
    isValid: true,
};

export const DeckText = styled.div`
    color: white;
    left: 260px;
    position: absolute;
    padding-left: 15px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    width: 100%;
    height: calc(3%);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: start;
    top: calc(58%);
`;
