import React from 'react';
import { CardInterface, CardInterfaceAmount } from '../../Types/CardInterface';
import { CardStats, CrackingMask, DeckCardStyle } from './CardStyle';
import { ReactComponent as Trashcan } from '../../../assets/icons/trashcan.svg';
import { ReactComponent as Add } from '../../../assets/icons/plus.svg';
import { ReactComponent as Lupe } from '../../../assets/icons/lupe.svg';

import Popup from 'reactjs-popup';
import cracksImage from '../../../assets/card/cracks.png';
import { CardCost } from './Cost';
import { Name } from './Name';
import { Plus } from './Plus';
import { Attack } from './Attack';
import { Life } from './Life';
import { CardDetail } from './CardDetail';
import {
    ChoiceFieldStyle,
    DescriptionButton,
    CardButtonsContainer,
} from './ChoiceFieldStyle';
import './CardPopup.css';
import { CardSelectedType } from '../../Types/CardIndex';
import { CardImageLoader } from '../ImageLoader';
import { Gem } from './Gem';

type MarketCardType = {
    card_amount: CardInterfaceAmount;
    index: number;
    selectedCard: CardSelectedType;
    setSelectedCard: React.Dispatch<React.SetStateAction<CardSelectedType>>;
    option: 'collection' | 'deck';
    cardOnClick: (card: CardInterfaceAmount) => void;
    cardInDeck: boolean;
};

type CardType = {
    card: CardInterface;
};
const MonsterHand = (props: CardType) => {
    const { card } = props;
    const size = 30;
    return (
        <>
            <CardImageLoader imagePath={card.CardImage} />
            <Gem rareLvl={card.Rank} />
            <CardCost size={size} cost={card.Cost} />
            <Name name={card.CardName} isSpell={false} />
            <Plus size={size} plus={card.RegeneratePoints} />
            <Attack size={size} attack={card.Attack} />
            <Life size={size} life={card.Life} />
        </>
    );
};

const SpellCard = (props: CardType) => {
    const { card } = props;
    const size = 30;
    return (
        <>
            <CardImageLoader imagePath={card.CardImage} />
            <Gem rareLvl={card.Rank} />
            <CardCost size={size} cost={card.Cost} />
            <Name name={card.CardName} isSpell={true} />
        </>
    );
};

export const Card = (props: MarketCardType) => {
    const {
        card_amount,
        index,
        selectedCard,
        option,
        cardOnClick,
        setSelectedCard,
    } = props;
    const card = card_amount.Card;
    const CardData = card.CardType === 'Spell' ? SpellCard : MonsterHand;
    /*
  const [rotation, setRotation] = useState("rotateX(0deg) rotateY(0deg)");
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const card = event.currentTarget;
    const cardRect = card.getBoundingClientRect();
    const cardCenterX = cardRect.left + cardRect.width / 2;
    const cardCenterY = cardRect.top + cardRect.height / 2;
    const mouseX = event.clientX - cardCenterX;
    const mouseY = event.clientY - cardCenterY;
    const rotateX = -mouseY / 5;
    const rotateY = mouseX / 5;
    const transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    setRotation(transform);
  };

  const handleMouseLeave = () => {
    setRotation("");
    setIsHovered(false);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  */

    // ----------------------- Popups --------------------

    const DeckField = () => {
        if (!(index === selectedCard.index && selectedCard.label === 'deck')) {
            return <></>;
        }
        return (
            <ChoiceFieldStyle>
                <CardButtonsContainer>
                    <Popup
                        trigger={
                            <DescriptionButton>
                                <Lupe
                                    viewBox="0 -20 120 180"
                                    width="100%"
                                    height="100%"
                                ></Lupe>
                            </DescriptionButton>
                        }
                        modal
                    >
                        <CardDetail card={card} />
                    </Popup>

                    <DescriptionButton onClick={() => cardOnClick(card_amount)}>
                        <Trashcan
                            viewBox="0 0 60 60"
                            width="100%"
                            height="100%"
                        />
                    </DescriptionButton>
                </CardButtonsContainer>
            </ChoiceFieldStyle>
        );
    };

    const CollectionField = () => {
        if (
            !(
                index === selectedCard.index &&
                selectedCard.label === 'collection'
            )
        ) {
            return <></>;
        }
        return (
            <ChoiceFieldStyle>
                <CardButtonsContainer>
                    <Popup
                        trigger={
                            <DescriptionButton>
                                <Lupe
                                    viewBox="0 -20 120 180"
                                    width="100%"
                                    height="100%"
                                ></Lupe>
                            </DescriptionButton>
                        }
                        modal
                    >
                        <CardDetail card={card} />
                    </Popup>

                    <DescriptionButton onClick={() => cardOnClick(card_amount)}>
                        <Add viewBox="0 0 60 68" width="100%" height="100%" />
                    </DescriptionButton>
                </CardButtonsContainer>
            </ChoiceFieldStyle>
        );
    };

    //
    const emptyComponent = () => <></>;

    let PopupField: () => JSX.Element;
    switch (option) {
        case 'collection':
            PopupField = CollectionField;
            break;
        case 'deck':
            PopupField = DeckField;
            break;

        default:
            PopupField = emptyComponent;
            break;
    }

    return (
        <>
            <DeckCardStyle
                className="card-content"
                isSpellCard={card.CardType === 'Spell'}
                onClick={(e) => {
                    e.stopPropagation();
                    setSelectedCard({ index: index, label: option });
                }}
            >
                <PopupField />
                <CrackingMask
                    style={{
                        backgroundImage: `url(${cracksImage})`,
                    }}
                />
                <CardStats>
                    <CardData card={card} />
                </CardStats>
            </DeckCardStyle>
        </>
    );
};
