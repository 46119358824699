import styled from "styled-components";

export const BackgroundImageContainer = styled.div`
  z-index: -2;
  position: fixed;
  right: 0px;
  /* styles for phones */
  @media only screen and (max-width: 768px) {
    z-index: 0;
    display: none;
  }
`;
