import { SERVER_CARDS_URL, SERVER_URL } from "../../constants";
import { MainDeckInterface } from "../Types/CardInterface";
import { DeckBackend } from "./AdminAPI";

export const deleteDeck = async (
  cookieValue: string | undefined,
  deckCards: MainDeckInterface,
  deckNames: string[],
  setDeckCards: (value: React.SetStateAction<MainDeckInterface>) => void,
  setDeckNames: (value: React.SetStateAction<string[]>) => void
) => {
  const api = new DeckBackend();
  if (cookieValue !== undefined && deckNames.length > 1) {
    await fetch(
      `https://${SERVER_CARDS_URL}/user_deck_main`,
      {
        method: "DELETE",
        body: JSON.stringify({
          DeckName: deckCards.DeckName,
        }),
        headers: {
          Authorization: `Bearer ${cookieValue}`,
          "Content-Type": "application/json",
        },
      }
    );
    api
      .getDeckMainCards(cookieValue)
      .then((response_json: MainDeckInterface) => setDeckCards(response_json));

    api
      .getDeckNames(cookieValue)
      .then((response_json: Array<string>) => setDeckNames(response_json));
  }
};
