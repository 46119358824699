import styled from 'styled-components';
import { SMALLER_CARD } from '../../constants';
import { NoTouch } from '../../../constants';

export const FieldCardContainer = styled.div``;

export const CardStats = styled.div`
    position: relative;
`;

export const HandCardStyle = styled.div<{
    isSpellCard: boolean;
    isHovered: boolean;
    rotation: string;
}>`
    background-color: ${({ isSpellCard }) =>
        isSpellCard ? 'rgb(72, 172, 82)' : 'rgb(212, 201, 83)'};
    border: solid;
    border-color: #948f57;
    border-radius: 2px;
    border-width: 1px;
    cursor: pointer;
    min-width: 135px;
    position: relative;
    width: 135px;
    @media (max-width: ${SMALLER_CARD}) {
        min-width: unset;
        width: 85px;
    }
`;

export const FieldCardStyle = styled.div<{ isAttacking: boolean }>`
    box-shadow: ${({ isAttacking }) =>
        isAttacking ? '0 0 15px green' : 'none)'};
    background-color: rgb(212, 201, 83);
    border: solid;
    border-color: #948f57;
    border-radius: 2px;
    border-width: 1px;
    cursor: pointer;
    width: 135px;
    height: 135px;
    min-width: 135px;
    min-height: 135px;
`;

/*
export const CardImageContainer = styled.div`
  margin: auto;
`;
*/

export const BlackShade = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
`;

export const AttackingCard = styled.div`
    box-shadow: none;
`;

export const CrackingMask = styled.div`
    position: absolute;
    mix-blend-mode: multiply;
    background-size: contain;
    opacity: 0.7;
    width: 100%;
    height: 100%;
`;

export const PureCardStyle = styled.div<{
    isSpellCard: boolean;
    isHovered: boolean;
    rotation: string;
}>`
    background-color: ${({ isSpellCard }) =>
        isSpellCard ? 'rgb(72, 172, 82)' : 'rgb(212, 201, 83)'};
    border: solid;
    border-color: #948f57;
    border-radius: 2px;
    border-width: 1px;
    cursor: pointer;
    min-width: 135px;
    width: 135px;
    transform-style: preserve-3d;
    transform: ${({ rotation }) => rotation};
    transition: transform 0.2s;
    @media ${NoTouch} {
        transform: ${({ isHovered, rotation }) =>
            isHovered ? `scale(1.15) ${rotation}` : `scale(1) ${rotation}`};
    }
`;

export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
