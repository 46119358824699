import * as React from "react";
import "./Shop.scss";
import "reactjs-popup/dist/index.css";

type FooterButtonType = {
  onClick: () => void;
  text: string;
};

export const FooterButton = (props: FooterButtonType) => {
  const { onClick, text } = props;
  return (
    <ul className="navbar__items shop_footer">
      <li className="navbar__item shop_exit" onClick={onClick}>
        <div className="navbar__link">{text}</div>
      </li>
    </ul>
  );
};
