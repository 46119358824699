import styled from "styled-components";
import { rgba } from "polished";

type Component = number;
type Color = `rgb(${Component},${Component},${Component})`;

export const StyleButton = styled.button<{ color: string }>`
  background-color: ${({ color }) => rgba(color, 0.8)};
  color: white;
  border: 2px solid black;
  border-radius: 5px;
  padding: 1rem 2rem;
  margin: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width:7rem;

  &:hover {
    background-color: ${({ color }) => rgba(color, 1)};
  }
`;
