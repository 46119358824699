import { CardInterface} from "./Types/CardInterface";
import { SearchCardForm } from "./Types/SearchCardForm";

export const filterAndSortByCardName = (
  cardArray: CardInterface[],
  searchString: SearchCardForm
) => {


  const normalCards = cardArray
    .filter((card) => {
      let filterCard = false
      if (searchString.Normal && !filterCard) {
        filterCard = card.Rank === 0;
      } 
      if (searchString.Ordinary && !filterCard) {
        filterCard = card.Rank === 1;
      } 
      if (searchString.Rare && !filterCard) {
        filterCard = card.Rank === 2;
      } 
      if (searchString.Heroic && !filterCard) {
        filterCard = card.Rank === 3;
      } 
      if (searchString.Epic && !filterCard) {
        filterCard = card.Rank === 4;
      } 
      if (searchString.Legendary && !filterCard) {
        filterCard = card.Rank === 5;
      } 
      return filterCard
    })

  const ordinaryCards = cardArray
    .filter((card) => {
      if (searchString.Ordinary) {
        return card.Rank === 1;
      } else {
        return false;
      }
    })

    const rareCards = cardArray
    .filter((card) => {
      if (searchString.Rare) {
        return card.Rank === 2;
      } else {
        return false;
      }
    })

    const epicCards = cardArray
    .filter((card) => {
      if (searchString.Epic) {
        return card.Rank === 3;
      } else {
        return false;
      }
    })

    const legendaryCards = cardArray
    .filter((card) => {
      if (searchString.Legendary) {
        return card.Rank === 4;
      } else {
        return true;
      }
    })
  
  const filteredCards = [...normalCards]

  const searchCardArray = filteredCards.length === 0 ? cardArray : filteredCards

  const searchLowerCase = searchString.Search.toLowerCase();
  return searchCardArray
    .sort((cardA, cardB) => {
      const cardAName = cardA.CardName.toLowerCase();
      const cardBName = cardB.CardName.toLowerCase();
      return cardAName.localeCompare(cardBName);
    })
    .filter((card) =>
      card.CardName.toLowerCase().includes(searchLowerCase)
    );
};
