import { SERVER_URL } from '../../constants';

export const verifyEmail = async (username: string, token: string) => {
    const res = await fetch(
        `https://${SERVER_URL}/activate?Token=${token}&Username=${username}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.ok) {
        return '';
    }
    return 'Token not found';
};
