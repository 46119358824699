import styled from 'styled-components';

export const ChoiceFieldStyle = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
`;

export const CardButtonsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const DescriptionButton = styled.button`
    height: 25px;
    margin: 10px 0px;
    border: 1px solid white;
    background-color: black;
    color: white;
    min-width: 80px;
    width: 70%;
    border-radius: 5px;
    z-index: 5;

    &:hover {
        transform: scale(1.05);
        transition: all 0.15s ease-in;
        border-color: rgb(57, 208, 184);
        cursor: pointer;
    }
`;
