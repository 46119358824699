import { SERVER_URL } from '../../constants';

export const resetPasswordPost = async (
    token: string,
    password: string,
    passwordRepeat: string,
) => {
    const res = await fetch(`https://${SERVER_URL}/reset_password`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            Token: token,
            Password: password,
            Password_2: passwordRepeat,
        }),
    });
    if (res.ok) {
        return 'Success';
    }
    const data: { message: string } = await res.json();
    return data.message;
};
