import React, { useState } from "react";
import "./Card.scss";
import { BackCard, OpponentCardInnerColor, InnerCircle } from "./CardBackStyle";

type CardBackType = {
  setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
};

export const CardBack = (props: CardBackType) => {
  const { setIsClicked, index } = props;
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const id_string = "card_" + index;

  const handleOnClick = () => {
    if (isAnimating) {
      return;
    }
    setIsAnimating(true);

    const container = document.getElementById(id_string);
    if (container) {
      container.classList.add("rotate-out");
      container.addEventListener(
        "animationend",
        () => {
          setIsClicked(true);
          /*
          container.classList.add("fade-in-add");
          container.addEventListener(
            "animationend",
            () => {
              container.classList.remove("fade-in-add");
              setIsAnimating(false);
            },
            { once: true }
          );
          */
        },
        { once: true }
      );
    }
  };

  return (
    <BackCard id={id_string} onClick={handleOnClick}>
      <OpponentCardInnerColor>
        <InnerCircle />
      </OpponentCardInnerColor>
    </BackCard>
  );
};
