import styled from 'styled-components';

export const ModalPopup = styled.div`
    position: fixed;
    inset: 0;
`;

export const ModalPopupCenter = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
