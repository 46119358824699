import React from 'react';
import { NavigationType } from '../Types/DropdownNavigation';
import { DropdownContainer, SelectContainer } from './styled/DropdownStyle';

interface DropdownProps {
    options: NavigationType[];
}

export const Dropdown: React.FC<DropdownProps> = ({ options }) => {
    const handleOptionSelect = (option: NavigationType) => {
        option.onClick();
    };

    return (
        <DropdownContainer>
            <SelectContainer
                onChange={(e) =>
                    handleOptionSelect(options[e.target.selectedIndex])
                }
            >
                {options.map((option) => (
                    <option key={option.label}>{option.label}</option>
                ))}
            </SelectContainer>
        </DropdownContainer>
    );
};
