import { MainDeckInterface } from "./Types/CardInterface";
import { cardsInDeck } from "./cardsInDeck";

export const isDeckValid = (
  setValidDeck: (value: React.SetStateAction<string>) => void,
  deckCards: MainDeckInterface,
  validDeck: string
) => {
  //check if deck has at least 40 cards
  if (cardsInDeck(deckCards) < 40) {
    setValidDeck("not_enough_cards");
    return;
  }
  if (validDeck !== "") {
    setValidDeck("");
  }
};
