import styled from "styled-components";

export const Settings = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: border 0.25s linear;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 1);
  }
`;

export const SettingPopup = styled.div`
  background-color: black;
  border-radius: 5px;
  border: 1px solid white;
`;

export const SettingPopupRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid lightgray;
  background-color: unset;
  overflow: hidden;
  color: white;
  padding: 10px 50px;
  transition: background-color 0.1s ease-in;
  text-align: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
