import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlusIcon from "../../../assets/card/plus.png";
import { PlusContainer } from "./PlusStyle";
import { TextContainer } from "./TextStyle";

type CardCostType = {
  plus: number;
  size: number;
};

export const Plus = (props: CardCostType) => {
  const { plus, size } = props;
  return (
    <PlusContainer>
      <LazyLoadImage
        src={PlusIcon}
        alt={"plus_icon"}
        height={size}
        width={size}
        effect="blur"
      />
      <TextContainer>{plus}</TextContainer>
    </PlusContainer>
  );
};
