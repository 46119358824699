import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import  SwordIcon from "../../../assets/card/sword.png";
import { AttackContainer } from "./AttackStyle";
import { TextContainer } from "./TextStyle";

type CardCostType = {
  attack: number;
  size: number;
};

export const Attack = (props: CardCostType) => {
  const { attack, size } = props;
  return (
    <AttackContainer>
      <LazyLoadImage
        src={SwordIcon}
        alt={"attack"}
        height={size}
        width={size}
        effect="blur"
      />
      <TextContainer>{attack}</TextContainer>
    </AttackContainer>
  );
};
