import { SERVER_CARDS_URL, SERVER_URL } from "../../constants";
import { CardInterface, CardInterfaceAmount, MainDeckInterface } from "../Types/CardInterface";

enum methods {
  DELETE = "DELETE",
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

const adminRequest = async (url: string, method = "GET", token="", Data = {}) => {
  let response;

  if (method === "PUT" || method === "POST") {
    Data = {...Data, Token: token}
    response = await fetch(url, {
      method: method,
      credentials: "include",
      body: JSON.stringify(Data),
    });
  } else {
    response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }

  if (response.status === 401) {
    throw new Error("unauthorized");
  }

  try {
    return await response.json();
  } catch (err) {
    return err;
  }
};

export class DeckBackend {
  url: string;
  constructor() {
    this.url = `https://${SERVER_CARDS_URL}`;
  }

  getUserCards(token: string): Promise<string[]>  {
    return adminRequest(`${this.url}/user_card_ids`, "GET" ,token);
  }

  getAllCards(): Promise<CardInterface[]>  {
    return adminRequest(`${this.url}/all_cards`, "GET" );
  }
}