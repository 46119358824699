/*
import React, { useState, useEffect, useRef } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Conatiner, Picture } from './CardImageLoader.styles';

type Props = {
    imagePath: string;
}

export const CardImageLoader = ({ imagePath } :Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [blurLoaded, setBlurLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const image = new Image();
    image.src = imagePath;
    image.onload = () => {
      setImageLoaded(true);
    };

    return () => {
      image.onload = null;
    };
  }, [imagePath]);

  useEffect(() => {
    if(imgRef.current === null) return

    const imgElement = imgRef.current as HTMLImageElement;
    if (imgElement && imgElement.complete) {
      setBlurLoaded(true);
    }
  }, []);

  const handleImageLoad = () => {
    setBlurLoaded(true);
  };


  const TriangleLoader = () => {
    return(
        <Triangle
            height="105"
            width="80"
            color="#fff"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            visible={true}
            wrapperClass='card-loader'
        />)
  }

  return (
    <Conatiner  >
      {!imageLoaded && <TriangleLoader />}
      <Picture>
        <img
        className={"lazy-load-image lazy-load-image-wrapper"}
        loading='lazy'
        decoding='async'
            alt='Card'
            ref={imgRef}
            src={imagePath}
            style={{
            opacity: blurLoaded ? 1 : 0,
            transition: 'opacity 0.3s',
            filter: blurLoaded ? 'none' : 'blur(20px)',
            maxWidth: '100%'
            }}
            onLoad={handleImageLoad}
        />
      </Picture>
    </Conatiner>
  );
};
*/

import React, { useState, useEffect, useRef } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Container, Picture } from './CardImageLoader.styles';

type Props = {
  imagePath: string;
};

export const CardImageLoader = ({ imagePath }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [blurLoaded, setBlurLoaded] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);


  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setBlurLoaded(true);
        observer.disconnect();
      }
    }, options);

    observerRef.current = observer;

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const TriangleLoader = () => {
    return (
      <Triangle
        height={105}
        width={80}
        color="#fff"
        ariaLabel="triangle-loading"
        visible={true}
        wrapperClass="card-loader"
      />
    );
  };

  return (
    <Container>
      {!imageLoaded && <TriangleLoader />}
      <Picture>
        <img
          className="lazy-load-image lazy-load-image-wrapper"
          loading="lazy"
          decoding="async"
          alt="Card"
          ref={imgRef}
          src={blurLoaded ? imagePath : undefined}
          style={{
            opacity: blurLoaded ? 1 : 0,
            transition: 'opacity 0.3s',
            filter: blurLoaded ? 'none' : 'blur(20px)',
            maxWidth: '100%',
          }}
          onLoad={handleImageLoad}
        />
      </Picture>
    </Container>
  );
};

