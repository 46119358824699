import styled from 'styled-components';

export const PriceContainer = styled.div`
    color: #fae570;
    text-shadow: rgb(90 90 90) 1px 1px;
    text-align: center;
    font-size: medium;
    background-color: black;
`;

export const AmountContainer = styled.div`
    color: white;
    text-shadow: rgb(90 90 90) 1px 1px;
    font-size: medium;
`;

export const Center = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    background-color: black;
    border-bottom: none;
    height: 25px;
`;
