import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CenterBox = styled.div`
  max-width: 420px;
  width: 80%;
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  border: solid 2px rgb(0, 0, 0);
  border-radius: 10px;
  opacity: 0;
  animation: ${fadeIn} ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.6s;
`;
