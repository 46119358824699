import { CardInterfaceAmount, MainDeckInterface } from "./Types/CardInterface";

export const removeCardFromDeck = (
  card: CardInterfaceAmount,
  deckCards: MainDeckInterface,
  setDeckCards: (value: React.SetStateAction<MainDeckInterface>) => void
) => {
  let cardIndex = -1;

  for (let index = 0; index < deckCards.Cards.length; index++) {
    if (deckCards.Cards[index].Card.ID === card.Card.ID) {
      cardIndex = index;
    }
  }
  if (cardIndex === -1) {
    return;
  }
  if (parseInt(deckCards.Cards[cardIndex].Amount) <= 1) {
    deckCards.Cards.splice(cardIndex, 1);
  } else {
    deckCards.Cards[cardIndex].Amount = (
      parseInt(deckCards.Cards[cardIndex].Amount) - 1
    ).toString();
  }
  setDeckCards({ Cards: deckCards.Cards, DeckName: deckCards.DeckName });
};
