import { SERVER_CARDS_URL } from '../../constants';
import { MarketCardInterface } from '../Types/CardInterface';
import { DeckBackend } from './AdminAPI';

export const buyCard = async (
    card_id: string,
    cookieValue: string | undefined,
    setAllCards: (value: React.SetStateAction<MarketCardInterface[]>) => void,
    setPrestigePoints: (value: React.SetStateAction<number>) => void,
    api: DeckBackend,
) => {
    if (cookieValue === undefined) {
        return;
    }
    const res = await fetch(
        `https://${SERVER_CARDS_URL}/buy_card_from_market`,
        {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                CardId: card_id.toString(),
            }),
            headers: {
                Authorization: `Bearer ${cookieValue}`,
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.ok) {
        api.getMarketCards(cookieValue).then(
            (response_json: Array<MarketCardInterface>) =>
                setAllCards(response_json),
        );

        api.getUserPrestigePoints(cookieValue).then((response_json: number) =>
            setPrestigePoints(response_json),
        );
    }
};
