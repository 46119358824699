import { MatchesType } from "../../../Types/MatchHistory";
import { createMatchHistory } from "../../../Types/MatchHistory.factory";
import { isLocalhost } from "../../../factoryHelpers";
import { getMatches } from "../../Api/getMatches";

export type LoaderData = {
    matchHistory: MatchesType[];
};

export const loader = async (username: string) => {
    const matchHistory = isLocalhost() ? createMatchHistory() :  await getMatches(username);
    return { matchHistory };
}