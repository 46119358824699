import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BackgroundImageContainer } from './BackgroundImageContainer';

type BackgroundImageType = {
    src: string;
    width?: number;
    height?: number;
};

export const BackgroundImage = (props: BackgroundImageType) => {
    const { src, width, height } = props;
    const browserWidth = window.innerWidth;
    const browserHeight = window.innerHeight;

    if (browserWidth > 1750) {
        return (
            <BackgroundImageContainer>
                <LazyLoadImage
                    effect="blur"
                    src={src}
                    width={browserWidth}
                    height={browserHeight}
                />
            </BackgroundImageContainer>
        );
    }

    if (width === undefined) {
        return (
            <BackgroundImageContainer>
                <LazyLoadImage effect="blur" src={src} />
            </BackgroundImageContainer>
        );
    }

    return (
        <BackgroundImageContainer>
            <LazyLoadImage
                effect="blur"
                src={src}
                width={width}
                height={height}
            />
        </BackgroundImageContainer>
    );
};
