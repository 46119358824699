import styled from 'styled-components';

export const PrestigePointsContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 25px;
`;

export const PrestigePointsContainerCatalog = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border: 1px solid white;
    border-bottom: none;
    background-color: black;
`;

export const MyMarketPriceConatiner = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.7rem;
    border: 1px solid white;
    border-top: none;
    background-color: black;
`;

export const MyMArketStarIcon = styled.div`
    width: 1.5rem;
    height: 80%;
    svg {
        width: inherit;
        height: inherit;
    }
`;

export const PrestigePointsNumber = styled.div`
    width: fit-content;
    color: lightgray;
    text-shadow: 1px 1px rgb(20, 20, 20);
    font-size: large;
`;

export const IconContainer = styled.div`
    max-height: 25px;
    max-width: 25px;
`;
