export const CARD_SYMBOLS_OFFSET = "-15px";
export const SMAlL_SCREEN = "1295px";
export const TABLET_WIDTH = "940px";
export const MOBILE_WIDTH = "555px";
export const GALAXY_FOLD = "281px";

export const SMALLER_CARD = "360px";

export const HEIGHT_CUT = "835px";

export const DECK_AMOUNT_LIMIT = 3;
