import * as React from 'react';
import './MarketSideBar.scss';
import { route } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { MarketFormType } from '../../Types/MarketFormInterface';
import { SearchInput, SideBarResponsiveContainer } from './SideBarStyle';

import { FidgetSpinnerLoader } from '../../../fidgetSpinner';
import { Dropdown } from '../../Components/Dropdown';
import { NavigationType } from '../../Types/DropdownNavigation';

interface SideBarProps {
    form: MarketFormType;
    setForm: React.Dispatch<React.SetStateAction<MarketFormType>>;
}

export const MarketSideBarResponsiveMy = (props: SideBarProps) => {
    const { form, setForm } = props;

    const navigate = useNavigate();

    const navigationDict: NavigationType[] = [
        {
            label: 'My Market',
            onClick: () => navigate(route.MARKET_MY),
        },
        {
            label: 'Market',
            onClick: () => navigate(route.MARKET),
        },
        {
            label: 'Create',
            onClick: () => navigate(route.MARKET_ADD),
        },
    ];

    return (
        <SideBarResponsiveContainer>
            <SearchInput
                type="text"
                id="new_deck"
                placeholder="Search"
                value={form.Search}
                onChange={(element: React.ChangeEvent<HTMLInputElement>) => {
                    setForm({ ...form, Search: element.target.value });
                }}
            />

            <Dropdown options={navigationDict} />
        </SideBarResponsiveContainer>
    );
};
