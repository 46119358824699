import type { CardInterface } from "../Types/CardInterface";
import { DeckBackend } from '../Api/cards';
import { getCookie } from '../../getCookie';
import { isLocalhost } from '../factoryHelpers';
import { createDefaultCards } from '../Types/CardInterface.factory';

export type LoaderData = {
    allCards: CardInterface[];
    userCards: string[];
};

export const loader = async () => {
    const api = new DeckBackend();
    const cookieValue = getCookie("bloodstor_user") || "";
    const allCardsData = createDefaultCards();

    const allCards = isLocalhost() ? allCardsData : await api.getAllCards()
    const userCards = isLocalhost() ? ["1", "2", "3"] : await api.getUserCards(cookieValue)

    return { allCards, userCards };
}