import React, { useCallback } from 'react';
import { useState } from 'react';
import { getCookie, setCookie } from '../../getCookie';
import { SERVER_URL, route } from '../../constants';
import { Navigate, useNavigate } from 'react-router-dom';
import { BackgroundImage } from '../../BackgroundImage';
import DashboardBackground from './../../assets/dashboard.jpg';
import {
    ClassicButton,
    ClassicButtonSmall,
    FormContainerCenter,
    FormErrorMessage,
    FormFooter,
    FormInput,
    FormTitle,
} from '../../market/Components/styled';
import { PasswordBox } from '../../components';
import { BoxCenterRegister } from '../../market/Components/styled/BoxCenterRegisterLogin';
import { InfoText } from './PasswordInfoCheck/PasswordInfoCheck.style';
import { PasswordInfoCheck } from './PasswordInfoCheck';
import { PasswordState, AllowKeys } from '../../Types/Password';
import { AccountSlots } from '../../components/AccountsSlots/AccountSlots';
import { CheckboxLabel, LabelContainer, Text } from './CreateUser.styles';

interface SignUpResponse {
    message: string;
}

function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const CreateUser = (): React.ReactElement => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [isOpenPassword, setIsOpenPassword] = useState(false);
    const [isOpenPasswordNew, setIsOpenPasswordNew] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState<PasswordState>({
        length: false,
        specialCharacter: false,
        match: false,
        capital: false,
        small: false,
        number: false,
    });

    const [password, setPassword] = useState('');
    const [password_2, setPassword_2] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    // Use getCookie here
    const cookieValue = getCookie('bloodstor_user');

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const hasSpecialCharacter = useCallback(
        (input: string) => {
            const specialCharacterRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;
            return specialCharacterRegex.test(input);
        },
        [password],
    );

    const isSmallLetter = useCallback(
        (input: string) => {
            const smallLetterRegex = /[a-z]/;
            return smallLetterRegex.test(input);
        },
        [password],
    );

    const containsNumber = useCallback(
        (input: string) => {
            const numberRegex = /\d/;
            return numberRegex.test(input);
        },
        [password],
    );

    const isCapitalLetter = useCallback(
        (input: string) => {
            const smallLetterRegex = /[A-Z]/;
            return smallLetterRegex.test(input);
        },
        [password],
    );

    const toggleTrue = useCallback((caseKey: AllowKeys) => {
        setIsPasswordValid((prevState) => ({
            ...prevState,
            [caseKey]: true,
        }));
    }, []);

    const toggleFalse = useCallback((caseKey: AllowKeys) => {
        setIsPasswordValid((prevState) => ({
            ...prevState,
            [caseKey]: false,
        }));
    }, []);

    const handleLength = useCallback(
        (passwordValue: string) => {
            if (passwordValue.length == 8) {
                if (!isPasswordValid.length) {
                    toggleTrue('length');
                }
            }
            if (passwordValue.length < 8) {
                if (isPasswordValid.length) {
                    toggleFalse('length');
                }
            }
        },
        [password],
    );

    const handleNumber = useCallback(
        (passwordValue: string) => {
            if (containsNumber(passwordValue)) {
                if (!isPasswordValid.number) {
                    toggleTrue('number');
                }
            } else {
                if (isPasswordValid.number) {
                    toggleFalse('number');
                }
            }
        },
        [password],
    );

    const handleSpecialCharacter = useCallback(
        (passwordValue: string) => {
            if (hasSpecialCharacter(passwordValue)) {
                if (!isPasswordValid.specialCharacter) {
                    toggleTrue('specialCharacter');
                }
            } else {
                if (isPasswordValid.specialCharacter) {
                    toggleFalse('specialCharacter');
                }
            }
        },
        [password],
    );

    const handleSmallLetter = useCallback(
        (passwordValue: string) => {
            if (isSmallLetter(passwordValue)) {
                if (!isPasswordValid.small) {
                    toggleTrue('small');
                }
            } else {
                if (isPasswordValid.small) {
                    toggleFalse('small');
                }
            }
        },
        [password],
    );

    const handleCapitalLetter = useCallback(
        (passwordValue: string) => {
            if (isCapitalLetter(passwordValue)) {
                if (!isPasswordValid.capital) {
                    toggleTrue('capital');
                }
            } else {
                if (isPasswordValid.capital) {
                    toggleFalse('capital');
                }
            }
        },
        [password],
    );

    const handleMatch = useCallback(
        (passwordValue: string) => {
            if (passwordValue == password) {
                if (!isPasswordValid.match) {
                    toggleTrue('match');
                }
            } else {
                if (isPasswordValid.match) {
                    toggleFalse('match');
                }
            }
        },
        [password_2],
    );

    const allValuesTrueExceptMatch = (
        Object.keys(isPasswordValid) as Array<AllowKeys>
    )
        .filter((key) => key !== 'match')
        .every((key) => isPasswordValid[key]);

    const allValuesTrue = (
        Object.keys(isPasswordValid) as Array<AllowKeys>
    ).every((key) => isPasswordValid[key]);

    const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.toString().length < 20) {
            setUsername(event.target.value.toString());
        }
    };
    const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value.toString());
    };
    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value.toString());
        handleLength(event.target.value.toString());
        handleSpecialCharacter(event.target.value.toString());
        handleCapitalLetter(event.target.value.toString());
        handleSmallLetter(event.target.value.toString());
        handleNumber(event.target.value.toString());
    };
    const handlePassword_2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword_2(event.target.value.toString());
        handleMatch(event.target.value.toString());
    };

    const signUp = async (event: React.FormEvent<HTMLFormElement>) => {
        if (!allValuesTrue) {
            setErrorMessage('Password to weak');
            return;
        }
        event.preventDefault();
        setErrorMessage('');
        const res = await fetch(`https://${SERVER_URL}/create`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                Username: username,
                Email: email,
                Password: password,
                Password_2: password_2,
            }),
        });
        if (res.ok) {
            const data: { message: string } = await res.json();
            setCookie('bloodstor_user', data.message, 360);
            sleep(100);
            navigate(route.DASHBOARD);
        } else {
            const data: SignUpResponse = await res.json();
            if (data.message !== 'success') {
                setErrorMessage(data.message);
            }
        }
    };

    if (cookieValue !== undefined && cookieValue !== '') {
        return <Navigate to={route.DASHBOARD} />;
    }

    return (
        <div className="dashboard">
            <BackgroundImage src={DashboardBackground} />
            <BoxCenterRegister onSubmit={signUp}>
                <FormTitle>Register</FormTitle>
                <AccountSlots />
                <FormContainerCenter>
                    <FormInput
                        name="Username"
                        type="text"
                        onChange={handleUsername}
                        placeholder="Username"
                        value={username}
                    />
                    <FormInput
                        name="Email"
                        type="email"
                        onChange={handleEmail}
                        placeholder="E-mail"
                        value={email}
                    />
                    <FormInput
                        name="Password"
                        type="password"
                        onChange={handlePassword}
                        placeholder="Password"
                        value={password}
                        onFocus={() => {
                            setIsOpenPassword(true);
                        }}
                        onBlur={() => {
                            setIsOpenPassword(false);
                        }}
                    />

                    <PasswordBox isOpen={isOpenPassword}>
                        <InfoText>
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.length}
                                text="Password should have at least 8 characters"
                            />
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.specialCharacter}
                                text="Special character. Symbols like !, @, #, $, etc."
                            />
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.capital}
                                text="At least one capital letter"
                            />
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.small}
                                text="At least one lowercase letter"
                            />
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.number}
                                text="At least one number (1,2,3 etc.)"
                            />
                        </InfoText>
                    </PasswordBox>
                    <FormInput
                        name="Password_2"
                        type="password"
                        onChange={handlePassword_2}
                        placeholder="Repeat Password"
                        value={password_2}
                        disabled={!allValuesTrueExceptMatch}
                        onFocus={() => {
                            setIsOpenPasswordNew(true);
                        }}
                        onBlur={() => {
                            setIsOpenPasswordNew(false);
                        }}
                    />
                    <PasswordBox isOpen={isOpenPasswordNew}>
                        <InfoText>
                            <PasswordInfoCheck
                                isTrue={isPasswordValid.match}
                                text="Passwords are matching"
                            />
                        </InfoText>
                    </PasswordBox>
                    <Text>
                        <b>Note:</b> If your account will be inactive within a
                        month, it will be banned.
                    </Text>
                    <CheckboxLabel>
                        <label>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <LabelContainer>
                                Accept{' '}
                                <a
                                    href={route.TERMS_OF_USE}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    terms of use
                                </a>
                            </LabelContainer>
                        </label>
                    </CheckboxLabel>
                    <ClassicButton
                        className="navbar__item"
                        type="submit"
                        value="Submit"
                        disabled={!allValuesTrue || !isChecked}
                    >
                        Register
                    </ClassicButton>
                    <FormFooter>
                        <p className="white_text">Already have an account?</p>
                        <ClassicButtonSmall
                            onClick={() => navigate(route.LOGIN)}
                        >
                            Login
                        </ClassicButtonSmall>
                    </FormFooter>
                </FormContainerCenter>
            </BoxCenterRegister>
            {errorMessage !== '' && (
                <FormErrorMessage>{errorMessage}</FormErrorMessage>
            )}
        </div>
    );
};
