import { SERVER_CARDS_URL, SERVER_URL } from "../../constants";
import { MarketCardInterface } from "../Types/CardInterface";
import { DeckBackend } from "./AdminAPI";

export const bringMarketCardBackToUser = async (
  card_id: string,
  cookieValue: string | undefined,
  api: DeckBackend,
  setAllCards: (value: React.SetStateAction<MarketCardInterface[]>) => void
) => {
  if (cookieValue === undefined) {
    return;
  }
  const res = await fetch(
    `https://${SERVER_CARDS_URL}/market_card_back_to_user`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        CardId: card_id.toString(),
      }),
      headers: {
        Authorization: `Bearer ${cookieValue}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (res.ok) {
    api
      .getUserMarketCards(cookieValue)
      .then((response_json: Array<MarketCardInterface>) =>
        setAllCards(response_json)
      );
  }
};
