import React, { useState } from "react";
import { NavigationType } from "../Types/DropdownNavigation";
import { DropdownContainer, SelectContainer } from "./styled/DropdownStyle";

interface DropdownProps {
  options: NavigationType[];
  defaultSelectedValue: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  defaultSelectedValue,
}) => {
  const handleOptionSelect = (option: NavigationType) => {
    option.onClick();
  };

  return (
    <DropdownContainer>
      <SelectContainer
        value={defaultSelectedValue}
        onChange={(e) => handleOptionSelect(options[e.target.selectedIndex])}
      >
        {options.map((option) => (
          <option key={option.label} value={option.label}>
            {option.label}
          </option>
        ))}
      </SelectContainer>
    </DropdownContainer>
  );
};
