import React, { useMemo, useState } from 'react';
import { CardCatalog, SideBar } from '../Modules';
import type { CardInterface } from '../Types/CardInterface';
import { getCookie } from '../../getCookie';
import { SideBarResponsive } from '../Modules/SideBar/SideBarResponsive';
import { SearchCardForm } from '../Types/SearchCardForm';
import { filterAndSortByCardName } from '../filterAndSortByCardName';
import { LoaderData, loader } from './CardCollection.loader';
import { CARD_HOVER_EFFEKT } from '../../constants';

export default CardCollection;
export function CardCollection() {
    const [allCards, setAllCards] = useState<CardInterface[]>([]);
    const [userCards, setUserCards] = useState<string[]>([]);
    const [form, setForm] = useState<SearchCardForm>({
        Search: '',
        Normal: false,
        Ordinary: false,
        Rare: false,
        Heroic: false,
        Epic: false,
        Legendary: false,
    });
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    useMemo(() => {
        loader().then((data) => {
            const { allCards, userCards } = data as LoaderData;
            setAllCards(allCards);
            setUserCards(userCards);
        });
    }, []);

    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    if (screenWidth > 675) {
        return (
            <div className="body background_library">
                <SideBar form={form} setForm={setForm} />
                <CardCatalog
                    allCards={filterAndSortByCardName(allCards, form)}
                    userCards={userCards}
                />
            </div>
        );
    }

    return (
        <div className="body background_library">
            <SideBarResponsive form={form} setForm={setForm} />
            {screenWidth <= CARD_HOVER_EFFEKT && (
                <CardCatalog
                    allCards={filterAndSortByCardName(allCards, form)}
                    userCards={userCards}
                />
            )}
        </div>
    );
}
